import { type Dispatch, type SetStateAction, useEffect, useState } from 'react'

type UseFiltersLoadingReturn = [boolean, Dispatch<SetStateAction<boolean>>]

/**
 * Allows local loading state in filter components that terminates when SRP stops loading
 * @param isSrpLoading Is the whole page loading (value exposed from search context)
 * @returns loading state local to the component where it is used and its setter
 */
export const useFiltersLoading = (isSrpLoading: boolean): UseFiltersLoadingReturn => {
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (isSrpLoading === true) return

    setIsLoading(isSrpLoading)
  }, [isSrpLoading])

  return [isLoading, setIsLoading]
}
