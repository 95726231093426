import styled from 'styled-components'

import { CardProps } from './Card'

type CardPropsBaseProps = CardProps

export const CardBase = styled.div<CardPropsBaseProps>(
  ({ theme, borderType, padding, borderRadius }) => `
  border-radius: ${theme.borderRadius[borderRadius || 'small']}; 
  padding: ${theme.spacing[padding || 'large']};
  background: ${theme.colors.white};
  box-shadow: ${
    borderType === 'box-shadow' ? theme.boxShadow['shadow-1'] : 'initial'
  };
  border: ${
    borderType === 'border'
      ? '0.1rem solid ' + theme.colors.grey.light4
      : 'initial'
  };
`
)
