import { type ButtonHTMLAttributes, type FC } from 'react'

import { AssistChipButton } from '@/components/shared/assist-chip/styled'

type AssistChipProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  label: string
}

export const AssistChip: FC<AssistChipProps> = ({ label, children, ...buttonProps }) => {
  return (
    <AssistChipButton size="medium" variant="primary" {...buttonProps}>
      {children}
      {label}
    </AssistChipButton>
  )
}
