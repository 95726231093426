import { isAnyVacationRentalsCategory } from '@kijiji/category'

import { type IntlResponse } from '@/types/translations'

/**
 * Return intl for results title - depends on category, keyword, location and if there are any results.
 *
 * @param categoryName - Search category label
 * @param keyword - Keyword used for search
 * @param locationName - Search location label
 * @param total - Total number of listings for the search
 */
export const getSrpStaticH1 = ({
  categoryId,
  categoryName,
  keyword,
  locationName,
  total,
}: {
  categoryId: number
  categoryName: string
  keyword: string | null
  locationName: string
  total: number
}): IntlResponse => {
  const params = { category: categoryName, location: locationName, keyword }

  /**
   * Variations:
   * 1. With or without results - The search returned or not listings for the matching criteria
   * 2. With or without keyword search - The search was done or not using a keyword in the search bar
   *
   * 3. Vacation Rentals - This is the only category in which its subcategories' names are locations. The general format of the H1 doesn't work for them.
   * For that reason, there is a special variation for it's sub-categories.
   *
   * The override category file is only meant to be used for BRPs.
   * There will be some logic added for SRPs (keyword searches) for vacation rentals categories and its subcategories
   */
  let intlKey = ''

  if (!total && !keyword) {
    intlKey = 'header.no_results.title'
  }

  // It should include the vacation rentals key to the intl string if is L1 or any subcategory
  const vacationRentalsKey = isAnyVacationRentalsCategory(categoryId) ? `.vacation_rentals` : ''

  if (!total && keyword) {
    intlKey = intlKey = `header.no_results${vacationRentalsKey}.keyword_title`
  }

  if (total && keyword) {
    intlKey = `header.results${vacationRentalsKey}.keyword_title`
  }

  return { intl: intlKey || `header.results${vacationRentalsKey}.title`, params }
}
