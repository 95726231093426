import { type SearchCategoryPath } from '@kijiji/generated/graphql-types'

import { ALL_CATEGORIES_ID } from '@/constants/category'

/**
 * Extracts the category IDs from an array of SearchCategory objects and returns them as an array of strings.
 *
 * @function
 * @param {SearchCategoryPath[]} data - An array of SearchCategory objects.
 * @returns {string[]} An array of category IDs as strings.
 * @example
 * const categories = [
 *   { id: 10, localizedName: 'Buy & Sell', parentId: 0 },
 *   { id: 12, localizedName: 'Arts & Collectibles', parentId: 10 },
 * ]
 *
 * const extractedIds = extractCategoryIdsFromPath(categories)
 * console.log(extractedIds) // ['1', '2']
 */
export const extractCategoryIdsFromPath = (data: SearchCategoryPath[]): string[] => {
  if (data.length < 1) {
    return [ALL_CATEGORIES_ID]
  }

  return data.map((item) => String(item.id))
}
