import { FavouriteSolidIcon } from '@kijiji/icons/src/icons'
import FavouriteOutlineThick from '@kijiji/icons/src/icons/FavouriteOutlineThick'
import { useSession } from 'next-auth/react'
import { useTranslation } from 'next-i18next'
import { useTheme } from 'styled-components'

import { SavedSearchActionFeedback } from '@/components/srp/save-search/SavedSearchActionFeedback'
import {
  SaveSearchFiltersContainer,
  SaveSearchFilterToggle,
  SaveSearchSubText,
  SaveSearchWrapper,
} from '@/components/srp/save-search-chip/style'
import { useGetSearchResultsData } from '@/hooks/srp'
import { type GaSaveSearchType, useSaveSearchActions } from '@/hooks/useSaveSearchActions'
import { GA_SAVE_SEARCH_TYPES } from '@/lib/ga/constants/ga'

interface SaveSearchChipButtonProps {
  /** Determines whether the component is displayed in the SRP view */
  isSrpView?: boolean
  eventTrackingSource?: GaSaveSearchType
  isPillVariant?: boolean
}

export const SaveSearchChipButton: React.FC<SaveSearchChipButtonProps> = ({
  isSrpView = false,
  eventTrackingSource = GA_SAVE_SEARCH_TYPES.btnInFilters,
  isPillVariant = false,
}) => {
  const { t } = useTranslation('srp')
  const theme = useTheme()

  const { data: { searchQuery } = {} } = useGetSearchResultsData()
  const { data: userData } = useSession()
  const userId = parseInt(`${userData?.user.sub}`)

  const searchString = searchQuery?.searchString ?? ''

  const {
    handleActivateSaveSearch,
    isSearchSaved,
    handleDeactivateSearch,
    searchLookup,
    showUserFeedback,
  } = useSaveSearchActions({ searchString })

  const handleToggleClick = () => {
    if (isSearchSaved && searchLookup) {
      handleDeactivateSearch(searchLookup.id)
      return
    }

    handleActivateSaveSearch(eventTrackingSource)
  }

  // if unauthenticated
  if (!userId) {
    return null
  }

  return (
    <SaveSearchFiltersContainer isPillVariant={isPillVariant}>
      <SaveSearchWrapper alignItems="center" justifyContent="center" flexDirection="column">
        <SaveSearchFilterToggle
          size="medium"
          variant="secondary"
          onClick={handleToggleClick}
          data-testid="save-search-button-filters"
          isFullWidth
          isSelected={isSearchSaved}
          aria-pressed={isSearchSaved}
          isPillVariant={isPillVariant}
        >
          {isSearchSaved ? (
            <FavouriteSolidIcon data-testid="save-search-filled-icon" aria-hidden />
          ) : (
            <FavouriteOutlineThick
              data-testid="save-search-empty-icon"
              color={theme.colors.purple.primary}
              aria-hidden
            />
          )}
          {isSearchSaved
            ? t('save_search.filters.saved')
            : t('save_search.buttons.save_this_search')}
        </SaveSearchFilterToggle>
        {!isSrpView && (
          <SaveSearchSubText data-testid="saved-search-filters-additional-text">
            {isSearchSaved
              ? t('save_search.filters.get_notified_success_label')
              : t('save_search.filters.get_notified_label')}
          </SaveSearchSubText>
        )}
      </SaveSearchWrapper>
      {showUserFeedback && <SavedSearchActionFeedback type={showUserFeedback} />}
    </SaveSearchFiltersContainer>
  )
}
