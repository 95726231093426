import { type AttributeFilter, FilterControlType } from '@kijiji/generated/graphql-types'

import { getDescriptionFromSelectedValues } from '@/domain/srp/filters/getDescriptionFromSelectedValues'

/**
 * Returns the label for each of the filters based on the selected options
 *
 * @param filter
 * @returns
 */
export const getAttributeFilterDescription = (filter: AttributeFilter): string => {
  let selectedValues = filter.selectedValues ?? []

  /* 
    On spamming clicking the inputs, the filter values that are coming get mixed with other has filters that are applied,
    causing a duplicity of the values applied. 
    This piece of code filters out the values that are not part of the filter group to avoid showing another applied values in the description. 
  */
  if (selectedValues.length && filter.type === FilterControlType.HasFilters) {
    selectedValues = selectedValues.filter((selectedValue) => {
      return filter.values?.some((item) => item.value === selectedValue)
    })
  }
  if (!selectedValues.length) return ''

  const description = getDescriptionFromSelectedValues(selectedValues, filter.values)
  const selectedValuesCount = selectedValues.length

  return selectedValuesCount == 1 ? description : `${description} +${selectedValuesCount - 1}`
}
