import styled from 'styled-components'

import { SRP_FILTER_HEADER_FOOTER_HEIGHT } from '@/constants/others'
import { BodyText } from '@/ui/atoms/body-text'
import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'

export const FiltersModalPanel = styled.div(
  ({ theme }) => `
    display: flex;
    flex-direction: column;
    background: ${theme.colors.white};
    height: 100%;
    position: fixed;
    width: 100%;

    ${MEDIA_QUERIES(theme).medium} {
      border-radius: ${theme.borderRadius.medium};
      height: auto;
      position: unset;
      width: unset;
    }
  `
)

export const FiltersModalTitle = styled(BodyText)`
  grid-column: 1 / span 2;
  grid-row: 1;
`

export const FiltersModalHeader = styled.div(
  ({ theme }) => `
  display: grid;
  align-items: center;
  grid-template-columns: 1fr ${theme.spacing.xLarge};
  margin: ${theme.spacing.default};
`
)
//Set the max-height to 100vh and subtract the modal header and footer height
export const FiltersModalContent = styled.div(
  ({ theme }) => `
  max-height: calc(100vh - ${SRP_FILTER_HEADER_FOOTER_HEIGHT});
  overflow-y: auto;
  padding: ${theme.spacing.default};
`
)

export const FiltersModalFooter = styled.div(
  ({ theme }) => `
  border-top: 1px solid ${theme.colors.grey.light4};
  margin-top: auto;
  padding: ${theme.spacing.default};
`
)
