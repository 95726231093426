import { type ListingFragment } from '@kijiji/generated/graphql-types'
import { productCarouselJsonLd, transformListingsToProductSeo } from '@kijiji/seo/configs'
import { ProductJsonLd } from 'next-seo'

import { getAbsoluteUrl } from '@/utils/url'

export type ProductCarouselSeoJsonLdProps = {
  id: string
  listings: ListingFragment[]
}

export const ProductCarouselSeoJsonLd = ({ listings, id }: ProductCarouselSeoJsonLdProps) => {
  const defaultImageUrl = getAbsoluteUrl('/next-assets/images/fb_desktop.jpg')

  const productCarouselSeoListings = productCarouselJsonLd(
    transformListingsToProductSeo(listings, defaultImageUrl)
  )

  return (
    <ProductJsonLd
      type="ItemList"
      productName=""
      data-testid={id}
      itemListElement={productCarouselSeoListings}
    />
  )
}
