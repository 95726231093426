import { type AttributeFilter, type FilterGroup } from '@kijiji/generated/graphql-types'

import { isAttributeFilter } from '@/types/search'

export type AppliedFilterLabels = {
  name: string
  selectedLabels: string[]
}

/**
 * Returns the list of applied attributes from the entire filters list
 */
export const getLabelsFromAppliedFilters = (filterGroup: FilterGroup[]): AppliedFilterLabels[] => {
  const appliedAttributeFilters = filterGroup.reduce((acc: AppliedFilterLabels[], curr) => {
    const selectedFilter: AttributeFilter | undefined = curr.filters.find((item) => {
      return isAttributeFilter(item) && !!item.selectedValues?.length
    })

    if (!selectedFilter) return acc

    const { name, selectedValues } = selectedFilter

    const values = selectedFilter.values?.reduce((acc: string[], curr) => {
      if (!selectedValues?.includes(curr.value)) return acc
      return [...acc, curr.label]
    }, [])

    return [...acc, { name, selectedLabels: values || [] }]
  }, [])

  return appliedAttributeFilters
}
