/**
 * Determines the category ID based on the first and second category IDs.
 *
 * @param {number} [firstCategoryId] - The first category ID.
 * @param {number} [secondCategoryId] - The second category ID.
 * @returns {string} The corresponding category ID.
 *   - If no first category ID is provided, the default value 'o_o' is returned.
 *   - If the first category ID is 0, the default value '00_o' is returned.
 *   - If both the first and second category IDs are provided, the concatenated category ID is returned.
 *   - If only the first category ID is provided, the category ID with the first category ID and 'o' as the second category ID is returned.
 *
 * @example
 *  determineCategoryId();            // Returns 'o_o'
 *  determineCategoryId(27);          // Returns '27_o'
 *  determineCategoryId(34, 37);      // Returns '34_37'
 *  determineCategoryId(0);           // Returns '00_o'
 *  determineCategoryId(10, undefined);  // Returns '10_o'
 */
export function determineCategoryId(firstCategoryId?: string, secondCategoryId = 'o'): string {
  if (!firstCategoryId) return 'o_o'

  if (firstCategoryId === '0') return '00_o'

  return `${firstCategoryId}_${secondCategoryId}`
}
