import { useTranslation } from 'next-i18next'
import { type FC, useState } from 'react'
import { useTheme } from 'styled-components'

import { ShowAtOrLarger } from '@/components/shared/breakpoint'
import { RouteLink } from '@/components/shared/route-link'
import {
  SaveSearchIcon,
  SaveSearchIconContainer,
  SaveSearchModalPanel,
} from '@/components/srp/save-search/styled'
import { EXTERNAL_ROUTES } from '@/constants/routes'
import { getSaveSearchParametersLabel } from '@/domain/saveSearch'
import { useSignin } from '@/features/auth/hooks/useSignin'
import { useGetSearchCategory } from '@/hooks/category/useGetSearchCategory'
import { useGetLocation } from '@/hooks/location/useGetLocation'
import { useLocale } from '@/hooks/useLocale'
import { BodyText } from '@/ui/atoms/body-text'
import { Button } from '@/ui/atoms/button'
import { Flex } from '@/ui/atoms/flex'
import { HeadlineText } from '@/ui/atoms/headline-text'
import { Spacing } from '@/ui/atoms/spacing'
import { TextLink } from '@/ui/atoms/text-link'
import { Modal } from '@/ui/molecules/modal/Modal'
import { SystemMessage } from '@/ui/molecules/system-message'

export type SaveSearchGuestModalProps = {
  /**
   * Mounted URL to redirect user to sign in and back to the SRP with search saved
   * */
  signInRedirectUrl: string
  /**
   * Function responsible to close the modal
   */
  handleCloseModal: () => void
  /**
   * Flag to show an error for the submission of a save search action
   */
  error: boolean
  /**
   * Defines if the modal is open
   */
  isModalOpen: boolean
  /**
   * Search keyword
   */
  keywords?: string
}

export const SaveSearchGuestModal: FC<SaveSearchGuestModalProps> = ({
  error,
  handleCloseModal,
  isModalOpen,
  keywords,
  signInRedirectUrl,
}) => {
  const { colors, spacing } = useTheme()
  const { apiLocale } = useLocale()
  const { t } = useTranslation(['srp', 'common'])

  const { category } = useGetSearchCategory()
  const { location } = useGetLocation()

  const [goToSignIn, setGoToSignIn] = useState(false)

  const handleSignInClick = () => setGoToSignIn(true)

  useSignin({ callbackUrl: signInRedirectUrl, goToSignIn })

  return (
    <Modal
      data-testid="save-search-guest-modal"
      id="save-search-guest-modal"
      isOpen={isModalOpen}
      onCancel={handleCloseModal}
      label={t('srp:save_search.title_guest')}
      width={{ small: '100vw', medium: '48rem' }}
      hasCloseButton
      closeButtonLabel={t('srp:save_search.buttons.close')}
    >
      <SaveSearchModalPanel>
        {error && (
          <SystemMessage
            closeButtonLabel={t('common:system_messages.close_label')}
            data-testid="save-search-guest-error"
            description={t('common:system_messages.save_search_guest_failed.description')}
            title={`${t('common:system_messages.save_search_guest_failed.title')}`}
            variation="error"
          />
        )}

        <Spacing mBottom={spacing.large} mRight={spacing.defaultSmall} mLeft={spacing.defaultSmall}>
          <Flex flexDirection="column" gap={spacing.defaultSmall}>
            <ShowAtOrLarger breakpoint="medium">
              <SaveSearchIconContainer>
                <SaveSearchIcon
                  alt=""
                  data-testid="save-search-guest-modal-image"
                  src="/next-assets/images/Illustration_Verify.png"
                  fill
                />
              </SaveSearchIconContainer>
            </ShowAtOrLarger>

            <HeadlineText size="large" color={colors.purple.primary} textAlign="center">
              {t('srp:save_search.title_guest')}
            </HeadlineText>

            <BodyText size="medium" color={colors.grey.primary} textAlign="center">
              {t('srp:save_search.sub_title_guest')}
            </BodyText>

            <BodyText
              color={colors.grey.light1}
              data-testid="save-search-guest-parameters"
              size="medium"
              textAlign="center"
            >
              {getSaveSearchParametersLabel({
                activeCategory: category,
                apiLocale,
                keywords,
                location,
              })}
            </BodyText>
          </Flex>
        </Spacing>

        <Spacing mLeft={spacing.default} mRight={spacing.default}>
          <Button isFullWidth onClick={handleSignInClick} data-testid="save-search-sign-in">
            {t('srp:save_search.sign_in_text')}
          </Button>
        </Spacing>

        <Spacing mTop={spacing.default} mRight={spacing.defaultSmall} mLeft={spacing.defaultSmall}>
          <Flex flexDirection="column" gap={spacing.large}>
            <BodyText
              color={colors.grey.light1}
              data-testid="save-search-guest-consent"
              size="small"
              textAlign="center"
            >
              {t('srp:save_search.consent')}{' '}
              <RouteLink
                route={{ href: EXTERNAL_ROUTES.PRIVACY_POLICY.href, useLegacyHref: false }}
              >
                <TextLink variant="secondary" size="small" hasUnderline>
                  {t('srp:save_search.privacy_policy')}
                </TextLink>
              </RouteLink>{' '}
              {t('srp:save_search.and')}{' '}
              <RouteLink route={{ href: EXTERNAL_ROUTES.TERMS_OF_USE.href, useLegacyHref: false }}>
                <TextLink variant="secondary" size="small" hasUnderline>
                  {t('srp:save_search.terms_of_use')}
                </TextLink>
              </RouteLink>
            </BodyText>
          </Flex>
        </Spacing>
      </SaveSearchModalPanel>
    </Modal>
  )
}
