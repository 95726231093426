import { useEffect, useState } from 'react'

export const useScrollOutsideMainTag = () => {
  const [isOutsideMain, setIsOutsideMain] = useState<boolean>(false)

  const handleScroll = () => {
    const pageWrapper: HTMLDivElement | null = document.querySelector('#base-layout-main-wrapper')
    if (!pageWrapper) return

    /** Should be the size of the main container minus the viewport */
    const offsetHeight = pageWrapper.offsetHeight - window.innerHeight
    const scrollY = window.scrollY
    setIsOutsideMain(scrollY > offsetHeight)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return { isOutsideMain }
}
