import { type FilterGroup, type SearchCategory } from '@kijiji/generated/graphql-types'

import { type ApiLocale } from '@/domain/locale'
import { getLabelsFromAppliedFilters } from '@/domain/srp/getLabelsFromAppliedFilters'
import { getSrpMetadataOverrideFile } from '@/domain/srp/seo/getSrpMetadataOverrideFile'
import { type MetaCategoryOverride } from '@/types/seo'

type CustomOverrideProps = {
  apiLocale: ApiLocale
  category: SearchCategory
  filters: FilterGroup[]
  locationName: string
  totalListingsCount: number
}

type CustomOverrideData = {
  description?: string
  h1?: string
  title?: string
}

type Dictionary = { [key: string]: string }
const regex = /\{(.*?)\}/g

/**
 * Extracts a list of variable names from strings with dynamic values.
 * Example:
 * input: {carmake}{carmodel}For sale in {location-name}."
 * output: [carmake, carmodel, "location-name"]
 */
const getCanonicalNamesArrayFromStrings = (...data: string[]): string[] => {
  return data.reduce((acc: string[], curr: string) => {
    const matches = curr.match(regex) || []

    matches.forEach((match) => {
      const canonicalName = match.slice(1, -1)
      if (!acc.includes(canonicalName)) {
        acc = [...acc, canonicalName]
      }
    })

    return acc
  }, [])
}

/**
 * Replaces dynamic values defined within a string with the values defined in a dictionary.
 * Example:
 * input: {carmake}{carmodel}For sale in {location-name}."
 * dictionary: {carmake: "BMW", location-name: "Toronto"}
 * output: BMW For sale in Toronto."
 *
 * Example:
 * input: {numberbedrooms}Houses & Townhouses For Sale in {location-name}
 * suffixDictionary: {"numberbedrooms": "bedroom"}
 * dictionary: {numberbedrooms: "2", location-name: "Toronto"}
 * output: 2 bedroom Houses & Townhouses For Sale in Toronto"
 */
const replaceStringVariables = (
  str: string,
  dictionary: Dictionary,
  suffixDictionary: Dictionary
) => {
  const formattedStr = str.replace(regex, (_, canonicalName) => {
    let replacedString = dictionary[canonicalName]
    const suffixString = suffixDictionary[canonicalName]

    /** Add suffix only if the attribute has been selected */
    if (replacedString && suffixString) {
      replacedString += `${suffixString} `
    }

    return replacedString || ''
  })

  return formattedStr
    ? `${formattedStr.charAt(0).toUpperCase()}${formattedStr.substring(1)}`
    : undefined
}

/**
 * Creates a dictionary from a list of values
 * This function will try to match the values from the "canonicalNamesArray" with a value selected by the user.
 * Values will only be added to the dictionary if they match a value selected by the user.
 */
const createDictionaryFromCanonicalListName = ({
  canonicalNamesArray,
  category,
  filters,
  locationName,
  totalListingsCount,
}: Pick<CustomOverrideProps, 'category' | 'filters' | 'locationName' | 'totalListingsCount'> & {
  canonicalNamesArray: string[]
}): Dictionary => {
  if (!canonicalNamesArray.length) return {}

  const appliedAttributeFilters = getLabelsFromAppliedFilters(filters)

  const dictionary = canonicalNamesArray.reduce((acc: Dictionary, curr: string) => {
    if (curr.toLocaleLowerCase() === 'category-name') {
      return { ...acc, [curr]: category.localizedName }
    }

    if (curr.toLocaleLowerCase() === 'location-name') {
      return { ...acc, [curr]: locationName }
    }

    if (curr.toLocaleLowerCase() === 'listings-total') {
      return { ...acc, [curr]: `${totalListingsCount}` }
    }

    const selectedFilter = appliedAttributeFilters.find((filter) => {
      return filter.name.toLowerCase().trim() === curr.toLowerCase().trim()
    })

    const selectedLabels = selectedFilter?.selectedLabels

    /**
     * It should not return values if multiple values were selected or none
     * */
    if (!selectedLabels || selectedLabels.length > 1 || selectedLabels.length === 0) {
      return acc
    }

    return { ...acc, [curr]: `${selectedLabels[0].trim()} ` }
  }, {})

  return dictionary
}

/** Choose which row to use depending on the selected attributes */
const selectCategoryOverrideRow = ({
  categoryData,
  category,
  filters,
  locationName,
  totalListingsCount,
}: Pick<CustomOverrideProps, 'filters' | 'category' | 'locationName' | 'totalListingsCount'> & {
  categoryData: MetaCategoryOverride[]
}): MetaCategoryOverride | undefined => {
  let overrideData: MetaCategoryOverride | undefined

  categoryData.forEach((item) => {
    if (overrideData && !item.selectedAttributes) return

    /**
     * Check if there are required attributes to select the row
     * It should have at least one of the attributes in the list
     * */
    const selectedAttributes = item.selectedAttributes

    if (!selectedAttributes) {
      overrideData = item
      return
    }

    const attributesToInclude = selectedAttributes
      .trim()
      .substring(1, selectedAttributes.length - 1)
      .split(',')

    const dictionary = createDictionaryFromCanonicalListName({
      category,
      canonicalNamesArray: attributesToInclude,
      filters,
      locationName,
      totalListingsCount,
    })

    if (Object.keys(dictionary).length) {
      overrideData = item
      return
    }
  })

  return overrideData
}

export const getMetaCustomOverride = ({
  apiLocale,
  category,
  filters,
  locationName,
  totalListingsCount,
}: CustomOverrideProps): CustomOverrideData | undefined => {
  const fileJson = getSrpMetadataOverrideFile(apiLocale)

  /** Find out if current category is on the override list */
  const categoryData = fileJson.filter((item) => {
    /**
     * Transform categoryId string into array of strings
     * ex: "[10, 20, 30]" into ["10", "20", "30"]
     */
    const categoryIdList = JSON.parse(item.categoryIds)
    return categoryIdList?.includes(category.id)
  })

  /** Return undefined if the category is not listed in the document */
  if (!categoryData.length) return

  const overrideData = selectCategoryOverrideRow({
    category,
    categoryData,
    filters,
    locationName,
    totalListingsCount,
  })

  if (!overrideData) return

  const {
    pageTitleLogic = '',
    h1Logic = '',
    pageDescriptionLogic = '',
    attributeSuffix = '',
  } = overrideData

  /** Make dictionary of variables needed to fill fields */
  const canonicalNames = getCanonicalNamesArrayFromStrings(
    pageTitleLogic,
    pageDescriptionLogic,
    h1Logic
  )

  const dictionary = createDictionaryFromCanonicalListName({
    category,
    canonicalNamesArray: canonicalNames,
    filters,
    locationName,
    totalListingsCount,
  })

  /** Parse attribute suffix values */
  const suffixDictionary: Dictionary = attributeSuffix ? JSON.parse(attributeSuffix) : {}

  /** It should populate title if there is one */
  const title = pageTitleLogic
    ? replaceStringVariables(pageTitleLogic, dictionary, suffixDictionary)
    : undefined

  /** It should populate description if there is one */
  const description = pageDescriptionLogic
    ? replaceStringVariables(pageDescriptionLogic, dictionary, suffixDictionary)
    : undefined

  /** It should populate h1 if there is one */
  const h1 = h1Logic ? replaceStringVariables(h1Logic, dictionary, suffixDictionary) : undefined

  return { description, title, h1 }
}
