import { FC, HTMLAttributes } from 'react'

import { PaginationProps } from './Pagination'
import { DirectionButtons, Previous } from './styled'

type PaginationPreviousProps = HTMLAttributes<HTMLLIElement> &
  Pick<PaginationProps, 'generateUrl'> & {
    /**
     * Current page selected
     */
    currentPage: number
    /**
     * Previous link label
     */
    previousLabel: string
  }

/**
 *
 * @description Link for pagination's previous page
 */
export const PaginationPrevious: FC<PaginationPreviousProps> = ({
  currentPage,
  generateUrl,
  previousLabel,
  ...rest
}) => {
  const { href, onClick } = generateUrl(currentPage - 1)

  /** Shouldn't show previous component if the first page is selected */
  if (currentPage === 1) return null

  return (
    <DirectionButtons data-testid="pagination-previous-link" {...rest}>
      <Previous href={href} onClick={onClick} variant="secondary">
        {previousLabel}
      </Previous>
    </DirectionButtons>
  )
}
