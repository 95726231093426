// This is the location we set an SRP map to when
// the user has not yet set their own location in the header/modal. This defaults them to roughly all of Canada.
export const defaultLocation = {
  lat: 61.50086334212211,
  lng: -102.20162062792083,
  /** USE: MAP_ZOOM_CONTROL CONSTANT */
  defaultZoom: 4,
}

export const MAP_ID: string = 'map-srp-embed'
