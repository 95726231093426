import { type SearchCategory, type UserLocation } from '@kijiji/generated/graphql-types'

import { type ApiLocale } from '@/domain/locale'
import { getUserLocationLabel } from '@/domain/location/getUserLocationLabel'
/**
 * Returns the save search label with the parameters for that specific search
 * It will omit any of the parameters that are not in the current search
 *
 * i.e. phone • Buy & Sell •  City of Toronto • 50km
 */
export const getSaveSearchParametersLabel = ({
  apiLocale,
  activeCategory,
  keywords,
  location,
}: {
  activeCategory: SearchCategory
  apiLocale: ApiLocale
  keywords?: string
  location: UserLocation
}): string => {
  /**
   * Should consider the category tree selected value the source of truth since the user could be in a dominant category search
   * In this case the global category won't be updated, but the category selected for the search will be more specific
   */
  const locationLabel = getUserLocationLabel(apiLocale, location)

  const parameters = []

  if (keywords) parameters.push(`'${keywords}'`)
  parameters.push(activeCategory.localizedName)

  if (locationLabel) parameters.push(locationLabel)
  if (location.area?.radius) parameters.push(`${location.area.radius}km`)

  return parameters.join(' • ')
}
