import { useTranslation } from 'next-i18next'
import { type FC } from 'react'
import { useTheme } from 'styled-components'

import { LoadingFilter } from '@/components/srp/srp-loading/LoadingFilter'
import { LoadingListingCards } from '@/components/srp/srp-loading/LoadingListingCards'
import { Flex } from '@/ui/atoms/flex'
import { Spacing } from '@/ui/atoms/spacing'

type SrpLoadingProps = {
  variant: 'listing-card' | 'sidebar-filter' | 'modal-filter' | 'sidebar-filter-simplified'
}

export const SrpLoading: FC<SrpLoadingProps> = ({ variant }) => {
  const { spacing } = useTheme()
  const { t } = useTranslation('srp')

  const ListingCards = (
    <Flex
      aria-label={`${t('filters.show.results_loading')}`}
      aria-live="assertive"
      data-testid="loading-listing-cards"
      flexDirection="column"
      gap={spacing.default}
    >
      <LoadingListingCards />
      <LoadingListingCards />
      <LoadingListingCards />
      <LoadingListingCards />
      <LoadingListingCards />
      <LoadingListingCards />
      <LoadingListingCards />
      <LoadingListingCards />
      <LoadingListingCards />
      <LoadingListingCards />
    </Flex>
  )

  const ModalFilters = (
    <Spacing
      data-testid="loading-modal-filters"
      pBottom={spacing.default}
      pLeft={spacing.default}
      pRight={spacing.default}
      pTop={spacing.default}
    >
      <LoadingFilter />
      <LoadingFilter titleSize="small" />
      <LoadingFilter />
      <LoadingFilter titleSize="small" hideDivider />
    </Spacing>
  )

  const SidebarFilters = (
    <div data-testid="loading-sidebar-filters">
      <Spacing
        pBottom={spacing.default}
        pLeft={spacing.default}
        pRight={spacing.default}
        pTop={spacing.default}
      >
        <LoadingFilter />
        <LoadingFilter titleSize="small" />
        <LoadingFilter />
        <LoadingFilter titleSize="small" />
        <LoadingFilter hideDivider />
      </Spacing>
    </div>
  )

  const SidebarFiltersSimplified = (
    <div data-testid="loading-sidebar-filters-simplified">
      <Spacing
        pBottom={spacing.default}
        pLeft={spacing.default}
        pRight={spacing.default}
        pTop={spacing.default}
      >
        <LoadingFilter />
        <LoadingFilter titleSize="small" />
        <LoadingFilter hideDivider />
      </Spacing>
    </div>
  )

  const getLoadingComponent = () => {
    switch (variant) {
      case 'listing-card':
        return ListingCards
      case 'sidebar-filter':
        return SidebarFilters
      case 'sidebar-filter-simplified':
        return SidebarFiltersSimplified
      case 'modal-filter':
        return ModalFilters
    }
  }

  return getLoadingComponent()
}
