import { sendToLogger } from '@/utils/sendToLogger'

// TODO: Remove this file and/or edit it to use Optimizely once feature flags are implemented

// A union type of all the feature toggles we have
export type ToggleName =
  | 'isSearchBarEnabled'
  | 'isGuestSaveSearchEnabled'
  | 'isOneTimeSavedSearchEnabled'
  | 'metadataCategoryOverride'

/**
 * Checks the feature toggle environment variable for the specified toggleName
 * @param toggleName the name of the feature toggle you wish to check
 * @returns the boolean value of the feature toggle. returns false if the toggle is not found
 */
export const checkToggle = (toggleName: ToggleName): boolean => {
  try {
    const featureFlags = process.env.NEXT_PUBLIC_FEATURE_TOGGLES

    if (typeof featureFlags === 'string') {
      let featureJson: Record<string, string | boolean> = {}

      if (featureFlags.charAt(0) === '{') {
        featureJson = JSON.parse(featureFlags)
      } else {
        featureJson = featureFlags.split(',').reduce((prev, curr) => {
          const [flag, val] = curr.split(':')
          return { ...prev, [flag]: val }
        }, {})
      }

      return featureJson[toggleName]?.toString()?.toLowerCase() === 'true'
    }
  } catch (e) {
    sendToLogger(e, {
      tags: { component: 'feature-toggles', fn: 'check-toggle' },
      fingerprint: ['featureToggles'],
    })
  }
  return false
}
