import { useDecision } from '@optimizely/react-sdk'

import { createSrpGptConfig } from '@/components-page/srp/advertisement/createSrpGptConfig'
import { useSrpGpt } from '@/components-page/srp/advertisement/useSrpGpt'
import { extractCategoryIdsFromPath } from '@/domain/category/extractCategoryIdsFromPath'
import { getCurrentPageFromPagination } from '@/domain/srp/getCurrentPageFromPaginationData'
import { useAdSense } from '@/features/advertisement/hooks/useAdSense'
import { getAdSenseConfig } from '@/features/advertisement/utils/adsense/getAdSenseConfig'
import { cleanCategoryPathForDisplayAds } from '@/features/advertisement/utils/cleanCategoryPathForDisplayAds'
import { useGetSearchCategory } from '@/hooks/category/useGetSearchCategory'
import { useGetLocation } from '@/hooks/location/useGetLocation'
import { useGetSearchResultsData } from '@/hooks/srp/useGetSearchResultsData'
import { useLocale } from '@/hooks/useLocale'
import { FEATURE_FLAG } from '@/lib/optimizely'

/**
 * Custom hook to handle the logic for SRP advertisements
 * returns configurations and targeting values to be passed to AdvertisingProvider
 *
 * @returns gpt - targeting values
 * @returns srpGptConfig - GPT configuration object
 */
export const useSrpAds = () => {
  const { data } = useGetSearchResultsData()
  const { routeLocale } = useLocale()
  const { location } = useGetLocation()
  const { category } = useGetSearchCategory()

  const { searchQuery, pagination, controls } = data || {}

  const currentPage = getCurrentPageFromPagination(pagination || {})
  const cleanedPath = cleanCategoryPathForDisplayAds(category.path || [])
  const allCategoryIds = extractCategoryIdsFromPath(category.path || [])

  const [mastheadRemovalDecision] = useDecision(FEATURE_FLAG.MASTHEAD_REMOVAL_EXP)
  const isMastheadRemoved =
    mastheadRemovalDecision?.enabled === true && mastheadRemovalDecision?.variationKey === 'b'

  const [moveAdsenseBelowFoldDecision] = useDecision(FEATURE_FLAG.MOVE_ADSENSE_BELOW_FOLD_EXP)
  const adsenseRemovalExperimentBucket = {
    experimentName: 'kjca4271',
    experimentGroup:
      moveAdsenseBelowFoldDecision?.enabled && moveAdsenseBelowFoldDecision?.variationKey === 'b'
        ? 'b'
        : 'a',
  }

  const gpt = useSrpGpt({
    categoryIds: allCategoryIds,
    currentPage: currentPage,
    keywords: searchQuery?.keywords || '',
    locationId: location.id,
    routeLocale,
    filters: controls?.filtering || [],
    categories: cleanedPath,
    totalListings: pagination?.totalCount || 0,
  })

  const srpGptConfig = createSrpGptConfig({
    gptTargeting: gpt,
    isMastheadRemoved,
  })

  const adSenseConfig = getAdSenseConfig({
    allCategoryIds: allCategoryIds,
    location,
    currentPage,
    keywords: searchQuery?.keywords || '',
    routeLocale,
    categoryPath: category.path || [],
    ...adsenseRemovalExperimentBucket,
  })

  useAdSense({
    ...adSenseConfig,
    active: true,
    containers: [
      { container: 'AFSTop', width: '100%', number: 2 },
      { container: 'AFSBottom', width: '100%', number: 2 },
    ],
  })

  return {
    srpGptConfig,
    gpt,
  }
}
