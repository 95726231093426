import styled from 'styled-components'

import { zIndexRegistry } from '@/constants/zIndexRegistry'

export const UserActionFeedbackContainer = styled.div(
  ({ theme }) => `
  background: ${theme.colors.grey.primary};
  bottom: ${theme.spacing.large};
  min-width: 34rem;
  padding: ${theme.spacing.default};
  position: fixed;
  border-radius: ${theme.borderRadius.small};
  z-index: ${zIndexRegistry.USER_FEEDBACK_TOAST};

  & a, 
  & p {
    color: ${theme.colors.white};
  }
`
)
