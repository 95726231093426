import Image from 'next/image'
import styled from 'styled-components'

import { zIndexRegistry } from '@/constants/zIndexRegistry'
import { TRANSITION_STYLE } from '@/ui/constants/commonStyles'
import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'

// GUEST MODAL
export const SaveSearchIcon = styled(Image)(
  ({ theme }) => `
  margin: auto;
  display: none;

  ${MEDIA_QUERIES(theme).medium} {
    display: block;
  }
`
)

export const SaveSearchIconContainer = styled.div`
  height: 20rem;
  margin: 0 auto;
  position: relative;
  text-align: center;
  width: 20rem;
`

export const SaveSearchModalPanel = styled.div(
  ({ theme }) => `
    background: ${theme.colors.white};
    border-radius: ${theme.borderRadius.medium};
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    padding: ${theme.spacing.default} ${theme.spacing.default} ${theme.spacing.husky};
    width: 100%;
  `
)

type SaveSearchFloatingContainerProps = {
  isVisible: boolean
}

export const SaveSearchFloatingContainer = styled.div<SaveSearchFloatingContainerProps>(
  ({ theme, isVisible }) => `
  ${TRANSITION_STYLE}
  bottom: ${theme.spacing.default};
  display: flex;
  z-index: ${zIndexRegistry.FLOATING_CTA};

  ${MEDIA_QUERIES(theme).large}{
    position: fixed;
    ${!isVisible ? `transform: translate(0, 150%);` : ''}
  }
`
)
