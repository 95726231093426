import { LEADERBOARD_SCROLL_STICKY_OFFSET } from '@/features/advertisement/constants/adSlots'
import { useIframeObserverDisplayAds } from '@/features/advertisement/hooks/useIframeObserverDisplayAds'

export const useAdIframe = (ref: React.RefObject<HTMLDivElement>) => {
  // Get the iframe from the DOM once the ad is loaded
  const iframe = useIframeObserverDisplayAds(ref)

  // to distinguish between the different ad unit heights (250px vs 90px)
  const getStickyOffset = () => {
    if (!iframe) return '0'
    return LEADERBOARD_SCROLL_STICKY_OFFSET
  }

  const getAdWidth = () => {
    if (!iframe) return 0
    return iframe.offsetWidth
  }

  return {
    stickyOffset: getStickyOffset(),
    adWidth: getAdWidth(),
    rootMargin: '0px',
  }
}
