import { type Listing } from '@/types/search'

/**
 * Return only extended listings
 * Those are listings that are not a part of the initial search
 */
export const filterExtendedSearchFromListings = (
  initialSearchListings: Listing[],
  extendedListings: Listing[]
) => {
  const mainListingsIds = initialSearchListings.map((item) => item.id)

  // Should only return listings that are extra for the extended search
  return extendedListings.filter((item) => !mainListingsIds.includes(item.id))
}
