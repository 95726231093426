const WORD_BOUNDARY_REGEX = /\s+/;

/**
 * Counts the number of words in the given text.
 *
 * wordCount('the quick brown fox'); // 4
 */
export const wordCount = (text: string) => {
  if (text === undefined || typeof text !== 'string' || text === '') {
    return 0;
  }

  return text.trim().split(WORD_BOUNDARY_REGEX).length;
};

/**
 * Interpolate tags into a string
 *
 * Given a string:
 *
 * '/b-atv-snowmobile/<seoLocation />/c171l<locationId />'
 *
 * And a map of interpolation tags and values
 *
 * { '<locationId />': `123456dz`, '<seoLocation />': `ontario-toronto` }
 *
 * It will return this result
 *
 * '/b-atv-snowmobile/ontario-toronto/c171l123456dz'
 *
 * @param {string} value - the string to be interpolate with the interpolationValues
 * @param {object} interpolationValues - map of interpolation tags and values to be used to interpolate into the string
 */
export const interpolateTags = (value: string, interpolationValues?: { [key: string]: any }) => {
  if (interpolationValues === null || typeof interpolationValues !== 'object') {
    return value;
  }

  let resultString = value;

  Object.keys(interpolationValues).forEach(key => {
    resultString = resultString.split(key).join(interpolationValues[key]);
  });

  return resultString;
};


/**
 * Capitalizes the first letter of each word in a string.
 *
 * @param text - The string to be capitalized.
 * @returns A string with each word capitalized.
 *
 * @example
 * const capitalized = capitalizeWords('necklaces');
 * console.log(capitalized); // "Necklaces"
 */
export const capitalizeWords = (text: string): string => {
  return text
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}
