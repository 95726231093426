import { FC, HTMLAttributes } from 'react'

import { PaginationProps } from './Pagination'
import { DirectionButtons, Next } from './styled'

type PaginationNextProps = HTMLAttributes<HTMLLIElement> &
  Pick<PaginationProps, 'generateUrl'> & {
    /**
     * Current page selected
     */
    currentPage: number
    /**
     * Next link label
     */
    nextLabel: string
    /**
     * Total number of available pages
     */
    totalPageCount: number
  }

/**
 *
 * @description Link for pagination's next page
 */
export const PaginationNext: FC<PaginationNextProps> = ({
  currentPage,
  generateUrl,
  nextLabel,
  totalPageCount,
  ...rest
}) => {
  const { href, onClick } = generateUrl(currentPage + 1)

  /** Shouldn't show next component if the last page is selected */
  if (currentPage >= totalPageCount) return null

  return (
    <DirectionButtons data-testid="pagination-next-link" {...rest}>
      <Next href={href} onClick={onClick} variant="secondary">
        {nextLabel}
      </Next>
    </DirectionButtons>
  )
}
