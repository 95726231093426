import { isCarsVehiclesExtendedRadiusCategory } from '@kijiji/category'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import qs from 'query-string'
import { useEffect, useState } from 'react'

import { setRadiusBoostCookie } from '@/domain/location/setRadiusBoostCookie'
import { shouldBoostSearchRadius } from '@/domain/location/shouldBoostSearchRadius'
import { getSrpComposedUrl } from '@/domain/srp/getSrpComposedUrl'
import { useGetSearchCategory } from '@/hooks/category/useGetSearchCategory'
import { useGetLocation } from '@/hooks/location/useGetLocation'
import { TextLink } from '@/ui/atoms/text-link'
import { SystemMessage } from '@/ui/molecules/system-message'

export const RadiusBoostSystemMessage = () => {
  const { location, updateUserLocation } = useGetLocation()
  const { category } = useGetSearchCategory()

  const { t } = useTranslation('common')
  const { query, push } = useRouter()

  const [showNotification, setShowNotification] = useState<boolean>(false)
  const [previousRadius, setPreviousRadius] = useState<number | null>()

  useEffect(() => {
    if (query.rb && typeof query.rb === 'string') {
      /** Check query parameter for RadiusBoost "rb" for the previous radius value */
      const prevRadius = parseInt(query.rb)
      setPreviousRadius(prevRadius)

      const didRadiusBoost = shouldBoostSearchRadius({
        isRegion: location.isRegion,
        radius: prevRadius,
        searchCategoryId: category.id,
        clientCookie: document.cookie,
      })

      if (didRadiusBoost) {
        setShowNotification(true)
      }

      return
    }

    setShowNotification(false)
  }, [category.id, location.area?.radius, location.isRegion, query.rb])

  useEffect(() => {
    /**
     * If the category is not a vehicle, stop showing the banner
     **/
    if (!isCarsVehiclesExtendedRadiusCategory(category.id)) {
      setShowNotification(false)
      return
    }

    if (location.isRegion || !location.area) {
      setRadiusBoostCookie(false)
      setShowNotification(false)
    }
  }, [category.id, location.area, location.isRegion])

  const handleCloseSystemMessage = () => {
    setShowNotification(false)
  }

  const getURlWithUpdatedRadius = (radius: number) => {
    const newUrl = getSrpComposedUrl(query)
    const { query: queryParam, url: baseUrl } = qs.parseUrl(newUrl.href)
    queryParam.radius = radius.toFixed(1)

    /** Remove rb from URL */
    const { rb: _rb, ...rest } = queryParam
    return qs.stringifyUrl({ url: baseUrl, query: rest })
  }

  const handleSwitchToPreviousValue = (prevValue: number) => {
    if (!location.area) return

    /** Re-run search and update user location */
    const newLocation = { ...location, area: { ...location.area, radius: prevValue } }

    setRadiusBoostCookie(true)
    updateUserLocation(newLocation)

    const updatedUrl = getURlWithUpdatedRadius(prevValue)

    /**
     * Pushing instead of requesting for results only because the filters links (category tree) will have an incorrect location radius defined
     */
    push(updatedUrl)
    handleCloseSystemMessage()
  }

  return (
    showNotification &&
    !!location.area &&
    !!previousRadius && (
      <SystemMessage
        closeButtonLabel={t('modals.close.button.label')}
        description={
          <TextLink
            as="button"
            hasUnderline
            variant="secondary"
            onClick={() => handleSwitchToPreviousValue(previousRadius)}
          >
            {t(`system_messages.auto_change_location.description`, { value: previousRadius })}
          </TextLink>
        }
        onClose={handleCloseSystemMessage}
        title={t(`system_messages.auto_change_location.title`, {
          value: location.area.radius,
        })}
        variation="tip"
      />
    )
  )
}
