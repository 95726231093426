import styled from 'styled-components'

import { Flex } from '@/ui/atoms/flex'
import { Panel } from '@/ui/atoms/panel'
import { Skeleton } from '@/ui/atoms/skeleton'
import { Spacing } from '@/ui/atoms/spacing'
import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'

export const LoadingListingCardsContainer = styled(Panel)`
  height: fit-content;
  width: 100%;
`

export const LoadingContainer = styled.div`
  height: fit-content;
  width: 100%;
`

export const RectangleSkeleton = styled(Skeleton)(
  ({ theme }) => `
  ${MEDIA_QUERIES(theme).medium}{
    & > span {
      height: 17.6rem;
      width: 17.6rem;
    }
  }
`
)

export const LoadingListingContent = styled(Flex)(
  ({ theme }) => `
  width: 100%;

  & > span:nth-of-type(4),
  & > span:nth-of-type(5) {
    display: none;
  }

  ${MEDIA_QUERIES(theme).medium}{
    padding-right: 6rem;

    & > span:nth-of-type(1) > span {
      height: ${theme.spacing.large};
      width: 16.9%;
    }

    & > span:nth-of-type(2) > span {
      width: 67.8%;
    }

    & > span:nth-of-type(3) > span {
      width: 31.28%;
    }
    
    & > span:nth-of-type(4),
    & > span:nth-of-type(5) {
      display: inline-block;
    }
  }

  ${MEDIA_QUERIES(theme).large}{
    padding-right: 8rem;
  }
`
)

export const LoadingFiltersContainer = styled(Spacing)(
  ({ theme }) => `
  padding-left: ${theme.spacing.default};
`
)
