import { useTheme } from 'styled-components'

import { Breadcrumbs } from '@/components/shared/breadcrumbs/Breadcrumbs'
import { getBreadcrumbs } from '@/domain/breadcrumbs'
import { useGetSearchResultsData } from '@/hooks/srp/useGetSearchResultsData'
import { useLocale } from '@/hooks/useLocale'
import { Spacing } from '@/ui/atoms/spacing'

export const SrpBreadcrumb = () => {
  const { routeLocale } = useLocale()
  const { spacing } = useTheme()

  /**
   * It should get the cache saved on the server-side
   * This query access the same main call from the SRP requesting just the fragment needed for the breadcrumbs
   * */
  const { data: { searchQuery } = {}, loading } = useGetSearchResultsData({
    fetchPolicy: 'cache-only',
  })

  /** Breadcrumbs */
  const breadcrumbs = getBreadcrumbs({
    activeCategory: searchQuery?.category ?? undefined,
    keywords: searchQuery?.keywords,
    routeLocale,
  })

  return (
    <Spacing mBottom={spacing.defaultSmall} data-testid="search-breadcrumbs">
      <Breadcrumbs breadcrumbs={breadcrumbs} loading={loading} pageName="srp" />
    </Spacing>
  )
}
