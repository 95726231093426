import styled from 'styled-components'

import { BodyText } from '@/ui/atoms/body-text'
import { Button } from '@/ui/atoms/button'
import { HeadlineText } from '@/ui/atoms/headline-text'
import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'
import { styleFromTypography } from '@/ui/helpers/styleFromTypography'

export const ValueEstimateImage = styled.img(
  ({ theme }) => `
  background: ${theme.colors.purple.light5};
  border-radius: ${theme.spacing.defaultSmall};
  flex-shrink: 0;
  width: 10.4rem;
  height: 10.4rem;
  object-fit: contain;
  ${MEDIA_QUERIES(theme).medium} {
    height: revert;
    width: 17.6rem;
  }
`
)

export const ValueEstimateHeading = styled(HeadlineText)(
  ({ theme }) => `
    ${styleFromTypography(theme.typography.headline.small)};
    ${MEDIA_QUERIES(theme).medium} {
        ${styleFromTypography(theme.typography.headline.medium)};
    }
`
)

export const ValueEstimateDescription = styled(BodyText)(
  ({ theme }) => `
    ${styleFromTypography(theme.typography.body.small)};
    ${MEDIA_QUERIES(theme).medium} {
        ${styleFromTypography(theme.typography.body.medium)};
    }
`
)

export const ValueEstimateButton = styled(Button)(
  ({ theme }) => `
    ${styleFromTypography(theme.typography.body.small)};
    ${MEDIA_QUERIES(theme).medium} {
        ${styleFromTypography(theme.typography.body.medium)};
    }
`
)
