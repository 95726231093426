import { type AppliedRangeFilter, type MinMax } from '@kijiji/generated/graphql-types'
import { useTranslation } from 'next-i18next'
import { type ChangeEvent, type FC, useEffect, useState } from 'react'
import { useTheme } from 'styled-components'

import { ApplyFilter } from '@/components/srp/filters/filter-types/ApplyFilter'
import { FlexItemContainer } from '@/components/srp/filters/filter-types/styled'
import { type FilterProps } from '@/components/srp/filters/FiltersAccordion/FiltersAccordion'
import { MAXIMUM_INPUT_DIGITS } from '@/constants/others'
import { FILTER_CANONICAL } from '@/constants/search'
import { getDollarRangeFromFilter } from '@/domain/filters'
import { isNumber } from '@/domain/isNumber'
import { getRangeFilterDescription } from '@/domain/srp/filters/getRangeFilterDescription'
import { useSearchLoadingState } from '@/hooks/srp/useSearchLoadingState'
import { useLocale } from '@/hooks/useLocale'
import { type RangeFilterMinMax } from '@/types/search'
import { AccordionItem } from '@/ui/atoms/accordion'
import { Flex } from '@/ui/atoms/flex'
import { TextField } from '@/ui/molecules/text-field'
import { centsToDollar, dollarToCents } from '@/utils/price'

/** Labels */
const LARGE_VALUE_ERROR = 'srp:filters.error.range_large_value'
const RANGE_MAXIMUM_ERROR = 'srp:filters.error.range_maximum'

/** This function determines when the range input is the same as already applied, useful for disabled the submit */
const isInputRangeSameAsApplied = (
  min: string,
  max: string,
  applied: MinMax | null | undefined,
  isPriceFilter = true
) => {
  const appliedMin = (isPriceFilter && applied?.min && centsToDollar(applied.min)) || applied?.min
  const appliedMax = (isPriceFilter && applied?.max && centsToDollar(applied.max)) || applied?.max

  const minIsSame = min === (appliedMin?.toString() || '') || (min === '0' && !appliedMin)
  const maxIsSame = max === (appliedMax?.toString() || '')

  return minIsSame && maxIsSame
}

export const IntegerRangeFilter: FC<FilterProps<RangeFilterMinMax, AppliedRangeFilter>> = ({
  filter,
  parentFilter,
  refetch,
  onClickAccordionItem,
}) => {
  const { loadingResults } = useSearchLoadingState()

  const { t } = useTranslation(['srp', 'common'])
  const { apiLocale } = useLocale()
  const { spacing } = useTheme()

  const [errorMessage, setErrorMessage] = useState<string>('')
  const [min, setMin] = useState<string>('')
  const [max, setMax] = useState<string>('')

  const {
    name,
    label,
    fromRange: { min: filterMinimum, max: filterMaximum },
    selectedRangeValues,
  } = filter

  const isPriceFilter = name === FILTER_CANONICAL.PRICE_RANGE
  const filterDescription = getRangeFilterDescription({ apiLocale, t, rangeFilter: filter })

  /** Check if user input is greater than the expected maximum input digits */
  const maxInputCharError = max.length > MAXIMUM_INPUT_DIGITS
  const minInputCharError = min.length > MAXIMUM_INPUT_DIGITS

  useEffect(() => {
    /** Initialize filter */
    if (loadingResults) return

    let range = { min: '', max: '' }

    if (isPriceFilter && selectedRangeValues) {
      range = getDollarRangeFromFilter(selectedRangeValues)
    } else {
      if (selectedRangeValues?.min) range.min = `${selectedRangeValues.min}`
      if (selectedRangeValues?.max) range.max = `${selectedRangeValues.max}`
    }

    setMin(range.min)
    setMax(range.max)
  }, [isPriceFilter, loadingResults, selectedRangeValues])

  const handleMinRangeChange = (evt: ChangeEvent<HTMLInputElement>) => {
    setErrorMessage('')
    setMin(evt.target.value)
  }

  const handleMaxRangeChange = (evt: ChangeEvent<HTMLInputElement>) => {
    setErrorMessage('')
    setMax(evt.target.value)
  }

  const handleResetFilter = () => {
    setMin('')
    setMax('')

    const trackingLabel = `${name} = [undefined, undefined]`
    refetch({ filterName: name, minValue: null, maxValue: null }, trackingLabel)
  }

  const validateFields = (min: string, max: string): boolean => {
    /**
     * If min value is more than 7 digit length - it should be invalid
     */
    if (minInputCharError) {
      setErrorMessage(LARGE_VALUE_ERROR)
      return false
    }

    /**
     * If max value is more than 7 digit length - it should be invalid
     */
    if (maxInputCharError) {
      setErrorMessage(LARGE_VALUE_ERROR)
      return false
    }

    /**
     * If only a min value was set - it should be valid
     * If only a max value was set - it should be valid
     * If both min and max is not set - it should be valid
     */
    if (!min || !max) {
      setErrorMessage('')
      return true
    }

    // Max value shouldn't be smaller than min value
    const isValid = max && min ? parseInt(min) <= parseInt(max) : false
    if (isValid) {
      setErrorMessage('')
      return true
    }

    setErrorMessage(RANGE_MAXIMUM_ERROR)
    return isValid
  }

  const submitRangeFilter = () => {
    const isValid = validateFields(min, max)
    if (!isValid) return

    let minValue = min ? parseInt(min) : 0
    let maxValue = max ? parseInt(max) : null

    if (isPriceFilter) {
      //Price is saved in cents on the back-end
      minValue = minValue && dollarToCents(minValue)
      maxValue = maxValue && dollarToCents(maxValue)
    }

    const trackingLabel = `${name} = [${minValue}, ${maxValue ?? undefined}]`
    refetch({ filterName: name, minValue, maxValue }, trackingLabel)
  }

  return (
    <AccordionItem
      id={name}
      key={name}
      title={`${label}`}
      description={filterDescription}
      isDisabled={parentFilter && !parentFilter.isSelected}
      onClick={onClickAccordionItem}
    >
      <Flex flexDirection="column">
        <Flex flexDirection="row" gap={spacing.default}>
          <FlexItemContainer>
            <TextField
              id={`${name}_min`}
              label={`${filter?.rangeLabels.from}`}
              max={filterMaximum || undefined}
              min={filterMinimum}
              onKeyDown={isNumber}
              onChange={handleMinRangeChange}
              type="number"
              value={min}
              error={minInputCharError && errorMessage ? t(errorMessage) : undefined}
            />
          </FlexItemContainer>

          {/* FlexItemContainer is to prevent TextField expanding when an error message is displayed  */}
          <FlexItemContainer>
            <TextField
              id={`${name}_max`}
              label={`${filter?.rangeLabels.to}`}
              max={filterMaximum || undefined}
              min={filterMinimum}
              onKeyDown={isNumber}
              onChange={handleMaxRangeChange}
              type="number"
              value={max}
              error={(maxInputCharError || min < max) && errorMessage ? t(errorMessage) : undefined}
            />
          </FlexItemContainer>
        </Flex>

        <ApplyFilter
          filterLabel={label}
          handleApplyFilter={submitRangeFilter}
          handleResetFilter={handleResetFilter}
          shouldShowResetButton={!!min || !!max}
          isLoading={loadingResults}
          isDisabled={
            loadingResults ||
            isInputRangeSameAsApplied(min, max, filter?.selectedRangeValues, isPriceFilter)
          }
        />
      </Flex>
    </AccordionItem>
  )
}
