import { type ApiLocale, API_LOCALE } from '@/domain/locale'
import categoryOverrideJsonEN from '@/generated/meta/srp/meta-category-override-en.json'
import categoryOverrideJsonFR from '@/generated/meta/srp/meta-category-override-fr.json'
import { type MetaCategoryOverride } from '@/types/seo'

/**
 * Responsible for fetching static file containing Title & Description & H1 for specific categories
 * It access a internal API that returns the static excel file.
 */
export const getSrpMetadataOverrideFile = (apiLocale: ApiLocale): MetaCategoryOverride[] => {
  /**
   * Get file generated from .tsv
   */
  const jsonFile = { en: categoryOverrideJsonEN, fr: categoryOverrideJsonFR }
  return apiLocale === API_LOCALE.fr_CA ? jsonFile.fr : jsonFile.en
}
