import { type AttributeFilter } from '@kijiji/generated/graphql-types'

/**
 * Gets the first selected option and returns its label based on the available options
 *
 * @param selectedValues Selected options values
 * @param filterOptions All available options
 * @returns
 */
export const getDescriptionFromSelectedValues = (
  selectedValues?: string[] | null,
  filterOptions?: AttributeFilter['values']
) => {
  if (!selectedValues?.length || !filterOptions?.length) return ''

  const option = filterOptions.find((option) => selectedValues[0] === option.value)

  return option?.label || ''
}
