import Script from 'next/script'

type GptScriptsProps = {
  handleGptReady: () => void
}

export const GptScripts = ({ handleGptReady }: GptScriptsProps) => {
  return (
    <>
      <Script
        src="//securepubads.g.doubleclick.net/tag/js/gpt.js"
        onReady={handleGptReady}
      ></Script>

      <Script
        id="gpt-setup"
        data-testid="gpt-script"
        dangerouslySetInnerHTML={{
          __html: `
               window.googletag = window.googletag || {cmd: []};
            `,
        }}
      />
    </>
  )
}
