import { useTranslation } from 'next-i18next'
import { type FC } from 'react'

import { RouteLink } from '@/components/shared/route-link'
import { UserActionFeedback } from '@/components/shared/user-action-feedback'
import { ROUTES } from '@/constants/routes'
import {
  type SaveSearchUseFeedbackType,
  SAVE_SEARCH_FEEDBACK_TYPE,
} from '@/hooks/useSaveSearchActions'
import { trackEvent } from '@/lib/ga'
import { GA_EVENT } from '@/lib/ga/constants/gaEvent'
import { BodyText } from '@/ui/atoms/body-text'
import { Flex } from '@/ui/atoms/flex'
import { TextLink } from '@/ui/atoms/text-link'

type SavedSearchActionFeedbackProps = {
  type: SaveSearchUseFeedbackType
}

/**
 * Specific toast component to be used in the save search flow.
 * This component is mean to be used for desktop to mWeb
 *
 * It communicate the user if the search has been successfully saved or removed
 */
export const SavedSearchActionFeedback: FC<SavedSearchActionFeedbackProps> = ({ type }) => {
  const { t } = useTranslation('srp')

  const handleViewSaveSearch = () => {
    trackEvent({ action: GA_EVENT.WatchlistOpen, label: `cta=modal` })
  }

  return (
    <UserActionFeedback>
      <Flex justifyContent="space-between">
        {type === SAVE_SEARCH_FEEDBACK_TYPE.saved && (
          <>
            <BodyText>{t('save_search.user_feedback.saved')}</BodyText>

            <RouteLink route={ROUTES.MY_SAVED_SEARCHES} prefetch={false}>
              <TextLink onClick={handleViewSaveSearch}>
                {t('save_search.user_feedback.view')}
              </TextLink>
            </RouteLink>
          </>
        )}

        {type === SAVE_SEARCH_FEEDBACK_TYPE.removed && (
          <BodyText>{t('save_search.user_feedback.removed')}</BodyText>
        )}
      </Flex>
    </UserActionFeedback>
  )
}
