import { AdvertisingSlot } from 'react-advertising'
import styled from 'styled-components'

import { zIndexRegistry } from '@/constants/zIndexRegistry'
import { Flex } from '@/ui/atoms/flex'
import { TRANSITION_STYLE } from '@/ui/constants/commonStyles'
import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'

export const SearchFloatingContainer = styled.div(
  ({ theme }) => `
  bottom: 0;
  left: 0;
  position: fixed;
  width: 100%;
  z-index: ${zIndexRegistry.FLOATING_CTA};
  min-height: 11.4rem; /* prevent layout shift */
  
  ${MEDIA_QUERIES(theme).large}{
    display: none;
  }
`
)

type SearchButtonsWrapperProps = {
  isVisible?: boolean
}

export const SearchButtonsWrapper = styled(Flex)<SearchButtonsWrapperProps>(
  ({ theme, isVisible }) => `
  ${TRANSITION_STYLE}
  margin-bottom: ${theme.spacing.defaultSmall};
  transform: ${isVisible ? `translate(0, 0)` : `translate(0, 300%)`};
  min-height: 4.8rem; /* prevent layout shift buttons render */
  `
)

export const MobileLeaderboard = styled(AdvertisingSlot)(
  ({ theme }) => `
  background-color: ${theme.colors.white};
  box-shadow: 0 -0.2rem 0.5rem rgb(0 0 0 / 5%);
  display: flex;
  justify-content: center;
  padding: ${theme.spacing.mini} 0;
  width: 100%;
  min-height: 58px; /* prevent layout shift when ad loads */

  &:empty {
    margin: 0;
    padding: 0;
  }
`
)
