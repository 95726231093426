import { CookieRegistry } from '@/constants/cookieRegistry'
import { getCookieByKey } from '@/utils/cookies/getCookieByKey'

/**
 * Checks if the user manually modified their location radius
 * This information is stored on the cookies whenever the user manually updates their radius search
 */
export const didUserModifyRadius = ({
  clientCookie,
  serverCookies,
}: {
  clientCookie?: string
  serverCookies?: Record<string, unknown>
}): boolean => {
  /**
   * Check if the user has manually updated their radius before
   * */
  if (serverCookies) {
    return serverCookies?.[CookieRegistry.MANUALLY_UPDATED_RADIUS] === 'true'
  }

  if (clientCookie) {
    return getCookieByKey(clientCookie, CookieRegistry.MANUALLY_UPDATED_RADIUS) === 'true'
  }

  return false
}
