export const isArrayOfStrings = (arr: unknown): arr is string[] => {
  return Array.isArray(arr) && arr.every((element) => typeof element === 'string')
}

export const isJSON = (value: string): boolean =>
  value.trim().startsWith('{') || value.trim().startsWith('[')

export type Image = {
  /**
   * Image source
   */
  src?: string | null
  /**
   * Image alt attribute
   */
  alt?: string | null
}

export type UseLegacyLgDesktopBreakpoint = {
  /**
   * Should consider large legacy large desktop breakpoint
   * This is a legacy backwards compatibility prop
   */
  useLegacyLgDesktopBreakpoint?: boolean
}
