import styled from 'styled-components'

/**The fade in/out effect is created by adding a mask.
 * The mask fill is a left to right linear gradient.
 * When using a mask, transparent = opaque and black = transparent.
 * Here we're telling the mask to start off opaque, transition to transparent
 * over a width of 1.6rem and then back to opaque for the last 1.6rem.
 **/
export const PopularRelatedKeywordsList = styled.ul(
  ({ theme }) => `
  display: flex;
  gap: ${theme.spacing.default};
  width: 100%;
  overflow-x: auto;
  padding-left: ${theme.spacing.default};
  mask-image: linear-gradient(
    to right,
    transparent,
    black ${theme.spacing.default},
    black calc(100% - ${theme.spacing.default}),
    transparent
  );
  white-space: nowrap;

  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
}

  & li > a {
    display: inherit;
  }
`
)
