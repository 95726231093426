import { type AttributeFilter, type AttributeValue } from '@kijiji/generated/graphql-types'
import { useTranslation } from 'next-i18next'
import { type FC, type SyntheticEvent, useState } from 'react'

import { AttributeTreeItem } from '@/components/srp/filters/filter-types/AttributeTreeItem'
import {
  AttributeTreeButton,
  AttributeTreeList,
} from '@/components/srp/filters/filter-types/styled'
import { ViewAllFiltersButton } from '@/components/srp/filters/filter-types/ViewAllFiltersButton'
import { formatTreeFacets } from '@/domain/filters'
import { sortTreeByTotalValues } from '@/domain/srp/filters/sortTreeByTotalValues'
import { getCategoryLevel } from '@/domain/srp/getCategoryLevel'
import { useLocale } from '@/hooks/useLocale'
import { trackEvent } from '@/lib/ga'
import { GA_EVENT } from '@/lib/ga/constants/gaEvent'

const MAX_DISPLAYABLE_ITEMS = 5

export type TreeFilterProps = {
  /**
   * List of tree based on selected node
   * */
  attributes: AttributeFilter
  /**
   * Triggered when attribute is selected
   */
  handleSelectAttribute: (value: AttributeValue, level: number) => void
  /**
   * Selected attribute Id
   */
  selectedId?: number
  /**
   * Defines if tree leaf should be selected
   * For location tree it depends if user location is an exact location
   */
  shouldSelectLeaf?: boolean
  /**
   * Type of tree content being rendered
   * */
  type: 'category' | 'location'
}

export const TreeFilter: FC<TreeFilterProps> = ({
  attributes,
  handleSelectAttribute,
  selectedId,
  shouldSelectLeaf,
  type,
}) => {
  const { routeLocale } = useLocale()
  const { t } = useTranslation('srp')
  const [showMore, setShowMore] = useState<boolean>(false)

  /** Get all L1 leafs from list of attributes */
  const l1Nodes = attributes.values?.filter((item) => item.parentValue === '0') ?? []

  /**
   * This will get the primary node that has no parent
   * For location = All Canada
   * For Category = All Categories
   */
  const primaryNode = attributes.values?.find((item) => item.parentValue === null) ?? null

  const selectedAttributeHasChildren = attributes.values?.some(
    (item) => item.parentValue === selectedId
  )

  /** Determines whether or not the selected attribute has children */
  const getSelectedAttributeChildren = (attribute: AttributeValue): AttributeValue[] => {
    const children = attributes.values?.filter((item) => attribute.value === item.parentValue)

    /**
     * If level of category selection is L3, bring it up in the list of categories
     *
     * 1] We only do this for category, since only L3s for category need to be shown after
     * a selection is made. For location, the remaining L3s are not shown at all.
     *
     * 2] We need to make a check for the parent category being 2, since this function is
     * called while rendering any level of the tree, and we only want to do this sorting
     * if we are trying to find the children of an L2.
     */
    const levelOfSelectedCategory = getCategoryLevel({ categoryId: selectedId })
    const levelOfParentCategory = getCategoryLevel({ categoryId: attribute.value })

    // Since this sorts children by their totals, if the selected category
    // is to be at the top regardless of totals, it needs to be boosted after the sorting.
    const sortedChildren = sortTreeByTotalValues(children)
    const shouldHoistSelectedAttribute =
      (levelOfSelectedCategory === 3 && levelOfParentCategory === 2) ||
      (levelOfSelectedCategory === 2 && !selectedAttributeHasChildren)

    if (sortedChildren && type === 'category' && shouldHoistSelectedAttribute) {
      const indexOfSelectedCategory = sortedChildren.findIndex(
        (child) => parseInt(child.value) === selectedId
      )
      // This if-condition could never be false, as long as server response is correct.
      // If it is false somehow, we do not perform this sorting and return children as is.
      if (indexOfSelectedCategory !== -1) {
        const removedCategoryObject = sortedChildren.splice(indexOfSelectedCategory, 1)[0]
        sortedChildren.unshift(removedCategoryObject)
      }
    }

    return sortedChildren
  }

  const getDisplayableChildren = (children: AttributeValue[], shouldShowToggle: boolean) => {
    if (showMore || !shouldShowToggle) return children

    return children.slice(0, MAX_DISPLAYABLE_ITEMS)
  }

  const renderTree = (leaf: AttributeValue, level: number) => {
    const leafValue = parseInt(leaf.value)
    const isSelected = shouldSelectLeaf && leafValue === selectedId

    const onClick = (e: SyntheticEvent<HTMLAnchorElement | HTMLButtonElement>) => {
      // Prevent the native navigation, we need to handle it.
      e.preventDefault()
      e.stopPropagation()

      handleSelectAttribute(leaf, level)
    }

    const children = getSelectedAttributeChildren(leaf)

    /** Should only show toggle if there are more than 5 child leafs and category is not "All Categories" */
    const shouldShowToggle = children.length > 5 && selectedId !== 0
    const displayableChildren = getDisplayableChildren(children, shouldShowToggle)

    const toggleShowMore = () => {
      const updatedShowMore = !showMore
      setShowMore(updatedShowMore)

      const trackingAction =
        type === 'category' ? GA_EVENT.CategorySelectClick : GA_EVENT.LocationSelectClick
      const trackingLabel = `cta='${updatedShowMore ? 'show_more' : 'show_less'}'`
      trackEvent({ action: trackingAction, label: trackingLabel })
    }

    return (
      <AttributeTreeItem
        isSelected={!!isSelected}
        key={leaf.value}
        leafLabel={`${leaf.label}`}
        leafUrl={leaf.seoUrl}
        leafValue={leaf.value}
        onClick={onClick}
        totalResults={leaf.totalResults}
        level={level}
      >
        {!!displayableChildren.length && (
          <AttributeTreeList data-testid="selected-attribute-list">
            {displayableChildren.map((item) => renderTree(item, level + 1))}

            <ViewAllFiltersButton
              displayToggle={shouldShowToggle}
              showMore={showMore}
              handleClick={toggleShowMore}
            />
          </AttributeTreeList>
        )}
      </AttributeTreeItem>
    )
  }

  return (
    <AttributeTreeList data-testid="tree-filter-list">
      <li>
        <AttributeTreeButton
          aria-pressed={selectedId === 0}
          $selected={selectedId === 0}
          level={0}
          onClick={() =>
            handleSelectAttribute(
              { value: '0', label: t('filters.all_categories'), parentValue: null },
              0
            )
          }
        >
          {primaryNode?.label} {formatTreeFacets(primaryNode?.totalResults, routeLocale)}
        </AttributeTreeButton>

        <AttributeTreeList>
          {sortTreeByTotalValues(l1Nodes).map((item) => renderTree(item, 1))}
        </AttributeTreeList>
      </li>
    </AttributeTreeList>
  )
}
