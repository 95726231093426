import { isCarsTrucks } from '@kijiji/category'
import { useDecision } from '@optimizely/react-sdk'
import dynamic from 'next/dynamic'
import React, { Fragment, useMemo } from 'react'

import { ErrorBoundary } from '@/components/shared/error-boundary'
import { ListingCard } from '@/components/srp/listing-card'
import { InlineAdSlot } from '@/components-page/srp/advertisement/InlineAdSlot'
import { isICOLaunchedInLocation } from '@/domain/location/isICOLaunchedInLocation'
import { SearchListIntersectionObserver } from '@/domain/srp/SearchListIntersectionObserver'
import { AdSense } from '@/features/advertisement/components/adsense'
import { INLINE_AD_POSITIONS } from '@/features/advertisement/constants/adSlots'
import { useGetSearchResultsData } from '@/hooks/srp'
import { FEATURE_FLAG } from '@/lib/optimizely'
import { type Listing } from '@/types/search'

type RenderListingCardProps = {
  index: number
  isTopAd?: boolean
  item: Listing
  totalListings?: number
  isMobile?: boolean
}

const ValueEstimate = dynamic(
  () => import('@/components/srp/value-estimate').then((mod) => mod.ValueEstimate),
  { ssr: false }
)

export const RenderListingCard = ({
  index,
  isTopAd,
  item,
  totalListings,
  isMobile,
}: RenderListingCardProps) => {
  const { data } = useGetSearchResultsData()
  const { searchQuery, pagination } = data || {}

  const searchProvinceId =
    searchQuery?.location?.locationPaths && searchQuery?.location?.locationPaths.length > 1
      ? searchQuery.location.locationPaths[1].id
      : undefined

  const { totalCount: listingsSize = 0 } = pagination || {}

  /**
   * It should check the current category based on the listing data not activeCategory cache
   * If the active category is ALL CATEGORIES, none of the listing specific logic would work
   */
  const { categoryId: listingCategoryId } = item
  const isCarsTrucksSearch = isCarsTrucks(listingCategoryId)

  // We are interested in the user's location here, not the location of the listing.
  const isICOActive = useMemo(
    () => isICOLaunchedInLocation(searchQuery?.location?.locationPaths),
    [searchQuery?.location?.locationPaths]
  )

  const [valueEstimateFlag, isClientReady] = useDecision(FEATURE_FLAG.SRP_ICO)
  const [adsenseMoveDecision] = useDecision(FEATURE_FLAG.MOVE_ADSENSE_BELOW_FOLD_EXP)
  const isAdsenseMoved =
    adsenseMoveDecision?.enabled === true && adsenseMoveDecision?.variationKey === 'b'

  const key = `listing-${item.id}-${index}`

  return (
    <Fragment key={key}>
      <SearchListIntersectionObserver data-testid={`listing-card-list-item-${index}`} index={index}>
        <ErrorBoundary fingerprintId="RenderListingCard">
          <ListingCard item={item} isTopAd={isTopAd} isMobile={isMobile} index={index} />
        </ErrorBoundary>
      </SearchListIntersectionObserver>

      {!isTopAd && (
        <>
          {isAdsenseMoved && index === INLINE_AD_POSITIONS[0] && (
            <li>
              <AdSense id="AFSTop" />
            </li>
          )}
          <InlineAdSlot index={index} totalListings={totalListings ?? listingsSize} />
          {isClientReady && valueEstimateFlag.enabled && isCarsTrucksSearch && isICOActive && (
            <ValueEstimate index={index} searchProvinceId={searchProvinceId} isMobile={isMobile} />
          )}
        </>
      )}
    </Fragment>
  )
}
