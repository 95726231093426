import { useDecision } from '@optimizely/react-sdk'
import dynamic from 'next/dynamic'
import { useSession } from 'next-auth/react'

import { SrpResults } from '@/components/srp/results-sort/results'
import {
  SaveSearchWrapper,
  SrpResultsSortContainer,
  SrpSortContainer,
} from '@/components/srp/results-sort/styled'
import { SaveSearchChipButton } from '@/components/srp/save-search-chip'
import { isUserAuthenticated } from '@/features/auth/constants/user'
import { GA_SAVE_SEARCH_TYPES } from '@/lib/ga/constants/ga'
import { FEATURE_FLAG } from '@/lib/optimizely'
import { SelectVariant } from '@/ui/molecules/select'
import { getUserDevice } from '@/utils/getUserDevice'

const SrpSort = dynamic(() => import('./sort').then((mod) => mod.SrpSort), { ssr: false })

export const SrpResultsSort = () => {
  const [srpQuickFilterPillsDecision] = useDecision(FEATURE_FLAG.SRP_QUICK_FILTERS_PILLS)
  const areQuickFilterPillsActive =
    srpQuickFilterPillsDecision?.enabled && srpQuickFilterPillsDecision?.variationKey === 'b'
  const [saveSearchWithinFiltersDecision] = useDecision(FEATURE_FLAG.SAVED_SEARCH_WITHIN_FILTERS)
  const { status: userSessionStatus } = useSession()
  const isMobileDevice = getUserDevice().isPhone

  const isSaveSearchBtnEnabled =
    saveSearchWithinFiltersDecision.enabled &&
    isUserAuthenticated(userSessionStatus) &&
    isMobileDevice

  return (
    <SrpResultsSortContainer
      flexDirection="row"
      isSaveSearchActive={
        saveSearchWithinFiltersDecision.enabled && isUserAuthenticated(userSessionStatus)
      }
    >
      {isSaveSearchBtnEnabled && (
        <SaveSearchWrapper justifyContent="flex-start">
          <SaveSearchChipButton
            isPillVariant={areQuickFilterPillsActive}
            isSrpView
            eventTrackingSource={GA_SAVE_SEARCH_TYPES.btn}
          />
        </SaveSearchWrapper>
      )}
      <SrpResults showOnLargeBreakpoint />
      <SrpSortContainer isSaveSearchActive={isSaveSearchBtnEnabled} isRoundedSelect={false}>
        <SrpSort
          variant={areQuickFilterPillsActive ? SelectVariant.ROUNDED : SelectVariant.DEFAULT}
        />
      </SrpSortContainer>
    </SrpResultsSortContainer>
  )
}
