import { FC, InputHTMLAttributes, ReactNode } from 'react'

import { ChipCheckbox, ChipCloseIcon, ChipLabel } from './styled'

export type FilterChipProps = InputHTMLAttributes<HTMLInputElement> & {
  /**
   * Unique identifier for the chip.
   * Accessibility requirement to connect the chip to its label
   */
  id: string
  /**
   * The label for the chip
   */
  label: string | ReactNode
}

export const FilterChip: FC<FilterChipProps> = ({
  checked,
  id,
  label,
  ...rest
}) => {
  return (
    <span>
      <ChipCheckbox type="checkbox" id={id} checked={checked} {...rest} />
      <ChipLabel htmlFor={id}>
        {label}
        {checked && (
          <ChipCloseIcon aria-hidden data-testid="filter-chip-icon-id" />
        )}
      </ChipLabel>
    </span>
  )
}
