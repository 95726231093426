import { type SearchCategoryPath, type UserLocation } from '@kijiji/generated/graphql-types'

import { AdSenseSettings, AdSenseType } from '@/features/advertisement/constants/adsense'
import {
  type ContainersProps,
  type useAdSenseProps,
} from '@/features/advertisement/hooks/useAdSense'
import { getUserDevice } from '@/utils/getUserDevice'
import { replaceAmpersands } from '@/utils/string'

export type AdSenseQueryProps = {
  keywords: string
  location?: UserLocation
  categories?: SearchCategoryPath[]
}

/**
 * Process inputs and return a formatted query for AdSense
 *
 * @param param0 - AdSenseQueryProps
 * @returns <search keywords> <search location name> <L2CategoryName or L3CategoryName> or <search keywords> <search location name> <L1CategoryName or L2CategoryName or L3CategoryName>
 */
export const adSenseQuery = ({ keywords, location, categories }: AdSenseQueryProps) => {
  const searchKeywords = keywords || ''
  const searchLocationName = location?.name?.en_CA || 'Canada'

  let listCategories: string[] | undefined

  // find out if the "Buy And Sell" category is present
  const isBuyAndSellCategory = categories?.some((category) => {
    const localizedName = category.localizedName.toLowerCase()
    return localizedName === 'buy & sell' || localizedName === 'acheter et vendre'
  })

  if (searchKeywords.trim().length > 0 && isBuyAndSellCategory) {
    // when pubId is kijiji-ca-search2 and "Buy & Sell" category is present, remove the L1 category
    listCategories = categories?.slice(1).map((category) => category.localizedName)
  } else {
    listCategories = categories?.map((category) => category.localizedName)
  }

  const convertedCategories = listCategories ? replaceAmpersands(listCategories).join(' ') : ''

  return `${searchKeywords} ${searchLocationName} ${convertedCategories}`.trim()
}

export type AdSenseConfigurationProps = {
  active: boolean
  categoryPath?: SearchCategoryPath[]
  containers: ContainersProps[]
  keywords: string
  location?: UserLocation
  currentPage?: number
  hl?: string
  channel?: string
}

/**
 * Returns the configuration for the AdSense hook
 *
 * @param param0 - adSenseConfigurationProps
 * @returns - useAdSenseProps
 */
export const adSenseConfiguration = ({
  active = false,
  currentPage,
  categoryPath,
  containers,
  hl,
  keywords,
  location,
  channel,
}: AdSenseConfigurationProps): useAdSenseProps => {
  const isMobileDevice = getUserDevice().isPhone
  const adtest = process.env.NEXT_PUBLIC_ADSENSE_TEST_MODE || 'on'

  const isEmptyKeyword = keywords.trim().length === 0
  const currentTagType: AdSenseType = isEmptyKeyword ? AdSenseType.BRP : AdSenseType.SRP

  const currentSettings = AdSenseSettings[currentTagType]

  return {
    active,
    containers,
    options: {
      adPage: currentPage?.toString(),
      hl,
      pubId: isMobileDevice ? currentSettings.pubId.mobile : currentSettings.pubId.desktop,
      query: adSenseQuery({
        keywords,
        location,
        categories: categoryPath,
      }),
      styleId: isMobileDevice ? currentSettings.styleId.mobile : currentSettings.styleId.desktop,
      channel,
      ...(adtest === 'on' && { adtest }),
    },
  }
}
