import { type ImageSeoJsonLdProps, imageSeoJsonLd } from '@kijiji/seo/configs'
import Script from 'next/script'

import { PLATFORM_NAME } from '@/constants/others'

export type ImageSeoJsonLdWrapperProps = Omit<ImageSeoJsonLdProps, 'platformName'> & {
  id: string
}

export const ImageSeoJsonLdWrapper = ({ listings, id }: ImageSeoJsonLdWrapperProps) => {
  const jsonLd = imageSeoJsonLd({ listings, platformName: PLATFORM_NAME })

  if (!jsonLd) return null

  return (
    <Script
      id={id}
      data-testid={id}
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLd) }}
    />
  )
}
