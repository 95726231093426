import { type AttributeValue } from '@kijiji/generated/graphql-types'

/**
 * Sort tree items based on total values
 * Highest total value should be sorted to the top of the tree
 */
export const sortTreeByTotalValues = (treeChildren?: AttributeValue[]): AttributeValue[] => {
  if (!treeChildren?.length) return []

  treeChildren.sort((child, nextChild) => {
    /**
     * It shouldn't change the order when both child & nextChild don't have total results
     * It should push child down if the next child has a total value and the current doesn't
     * It should push child up if next child doesn't have a value and current does
     */
    if (!child.totalResults && !nextChild.totalResults) {
      return 0
    }
    if (!child.totalResults) {
      return 1
    }
    if (!nextChild.totalResults) {
      return -1
    }

    /**
     * If both child and next have values, then the child with higher total value should be pushed up
     * */
    return child.totalResults > nextChild.totalResults ? -1 : 1
  })

  return treeChildren
}
