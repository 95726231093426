import React, { type FC } from 'react'

import { PaginationContainer } from '@/components/srp/pagination/styled'
import { type PaginationProps } from '@/ui/molecules/pagination'
import { PaginationLink } from '@/ui/molecules/pagination/PaginationLink'
import { PaginationNext } from '@/ui/molecules/pagination/PaginationNext'
import { PaginationPrevious } from '@/ui/molecules/pagination/PaginationPrevious'

/**
 * SimplePagination Component
 *
 * This component renders a simplified version of the pagination, optimized for SEO and initial page load.
 * It displays a minimal set of pagination links:
 * - Previous page
 * - First page
 * - Current page (if not first or last)
 * - Last page
 * - Next page
 *
 * This approach ensures that search engines can crawl the essential pagination links
 * while keeping the initial render lightweight.
 *
 * @param {Object} props - The properties that define the pagination behavior
 * @param {function} props.generateUrl - Function to generate URLs for each page
 * @param {string} props.label - Aria label for the entire pagination nav
 * @param {string} props.nextLabel - Label for the 'Next' button
 * @param {string} props.pageNumberLabel - Label for each page number (used for accessibility)
 * @param {string} props.previousLabel - Label for the 'Previous' button
 * @param {number} props.selectedIndex - The currently selected page number
 * @param {number} props.totalPageCount - The total number of pages
 */
export const SimplePagination: FC<PaginationProps> = ({
  generateUrl,
  label,
  nextLabel,
  pageNumberLabel,
  previousLabel,
  selectedIndex,
  totalPageCount,
}) => (
  <nav aria-label={label}>
    <PaginationContainer data-testid="pagination-simple-list">
      {/* Previous page button */}
      <PaginationPrevious
        currentPage={selectedIndex}
        generateUrl={generateUrl}
        previousLabel={previousLabel}
      />

      {/* First page link (always visible) */}
      <PaginationLink
        generateUrl={generateUrl}
        isSelected={selectedIndex === 1}
        pageNumber={1}
        visuallyHiddenLabel={pageNumberLabel}
      />

      {/* Current page link (if not first or last page) */}
      {selectedIndex > 1 && selectedIndex < totalPageCount && (
        <PaginationLink
          generateUrl={generateUrl}
          isSelected={true}
          pageNumber={selectedIndex}
          visuallyHiddenLabel={pageNumberLabel}
        />
      )}

      {/* Last page link (if not already shown) */}
      {selectedIndex !== totalPageCount && (
        <PaginationLink
          generateUrl={generateUrl}
          isSelected={selectedIndex === totalPageCount}
          pageNumber={totalPageCount}
          visuallyHiddenLabel={pageNumberLabel}
        />
      )}

      {/* Next page button */}
      <PaginationNext
        currentPage={selectedIndex}
        generateUrl={generateUrl}
        nextLabel={nextLabel}
        totalPageCount={totalPageCount}
      />
    </PaginationContainer>
  </nav>
)
