/**
 * Ensures that search string passed to saveSearch mutation starts with a "?"
 * @param searchString input search string
 * @returns search string pre-pended with a "?" if not already present
 */
export const ensureSearchStringStartsWithQuestionMark = (searchString: string) => {
  const modifiedSearchString = searchString.startsWith('?') ? searchString : `?${searchString}`

  return modifiedSearchString
}
