import styled from 'styled-components'

import { Button } from '@/ui/atoms/button'

export const AssistChipButton = styled(Button)(
  ({ theme }) => `
  border-radius: 5rem;
  display: flex;
  gap: ${theme.spacing.mini};
  padding: ${theme.spacing.defaultSmall} ${theme.spacing.default};

  svg {
    height: 1.8rem;
    width: 1.8rem;
  }
`
)
