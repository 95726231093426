import { createRef, FC, RefObject } from 'react'

import { FloatingLabel } from '@/ui/atoms/floating-label'
import { TextInput } from '@/ui/atoms/text-input'
import { createSequentialId } from '@/ui/helpers/createSequentialId'
import { FormControl } from '@/ui/molecules/form-control'
import { CommonInputFieldProps } from '@/ui/typings/commonTextInput'

export type DateFieldProps = Omit<CommonInputFieldProps, 'maxLength'>

/**
 * @description Date field component
 */
export const DateField: FC<DateFieldProps> = ({
  bottom,
  error,
  helperText,
  id,
  label,
  ...rest
}) => {
  const inputRef: RefObject<HTMLInputElement> = createRef()

  const sequentialErrorId =
    error && createSequentialId(`${id}-date-field-error-`)()

  return (
    <FormControl
      bottom={bottom}
      error={error}
      helperText={helperText}
      id={id}
      isFullWidth
      errorId={sequentialErrorId}
    >
      <FloatingLabel
        hasError={!!error}
        htmlFor={id}
        inputRef={inputRef}
        isFocused={true}
      >
        {label}
      </FloatingLabel>

      <TextInput
        data-testid={`date-input-${id}`}
        errorId={sequentialErrorId}
        id={id}
        ref={inputRef}
        type="date"
        {...rest}
      />
    </FormControl>
  )
}
