import { type ApiLocale, API_LOCALE } from '@/domain/locale'
import legacyMetadataJsonEN from '@/generated/meta/srp/legacy-title-description-dynamic-en.json'
import legacyMetadataJsonFR from '@/generated/meta/srp/legacy-title-description-dynamic-fr.json'
import { type SrpMetaLegacyDetails } from '@/types/seo'

/**
 * Responsible for fetching static file containing Title & Description for all SRP categories
 * It access a internal API that returns the static excel file.
 */
export const getSrpMetadataLegacyFile = (apiLocale: ApiLocale): SrpMetaLegacyDetails[] => {
  /**
   * Get file from generated legacy JSON
   */
  const jsonFile = { en: legacyMetadataJsonEN, fr: legacyMetadataJsonFR }

  return apiLocale === API_LOCALE.fr_CA ? jsonFile.fr : jsonFile.en
}

export const getMetaDetailsPerCategoryId = (
  fileData: SrpMetaLegacyDetails[],
  categoryId: number,
  locationName: string
) => {
  const node = fileData.find((item) => parseInt(item.categoryId) === categoryId)

  if (!node) return { title: '', description: '' }

  /** Dynamic tag to be replaced in string returned by file */
  const cityTag = '[city]'

  /** Replace city name in title with the selected location name */
  const locationTitle = node?.titlePart3?.includes(cityTag)
    ? node.titlePart3.replace(cityTag, locationName)
    : node?.titlePart3

  /** Replace city name in description with the selected location name */
  let locationDescription
  if (node.descriptionPart2) {
    locationDescription = node.descriptionPart2.includes(cityTag)
      ? node.descriptionPart2.replace(cityTag, locationName)
      : node.descriptionPart2
  }

  /** Mount SEO title for SRP */
  const title = `${node.titlePart1}${node.titlePart2}${locationTitle}`

  /** Mount SEO description for SRP */
  const description = `${node.descriptionPart1 ?? ''}${locationDescription ?? ''}${
    node.descriptionPart3 ?? ''
  }`

  return { description, title }
}
