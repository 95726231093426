import { type AttributeValue } from '@kijiji/generated/graphql-types'
import { useEffect, useState } from 'react'

/**
 * Max value to display chips in chips
 * Just for multiselect filters
 */
export const MAX_CHIP_FILTERS = 20

/**
 * Minimum value before displaying
 * Search Component
 */
export const MIN_SEARCH_BAR_VALUES = 25

/** Max value to display */
const MAX_INITIAL_DISPLAY = 5

export const useFieldsVisibility = (fields: AttributeValue[], isMultiselect?: boolean) => {
  const [showMore, setShowMore] = useState<boolean>(false)
  const [fieldsToDisplay, setFieldsToDisplay] = useState<AttributeValue[]>([])

  useEffect(() => {
    const maxInitialDisplay =
      isMultiselect && fields.length <= MAX_CHIP_FILTERS ? MAX_CHIP_FILTERS : MAX_INITIAL_DISPLAY

    if (showMore) {
      setFieldsToDisplay(fields)
    } else {
      setFieldsToDisplay(fields.slice(0, maxInitialDisplay))
    }
  }, [fields, showMore, isMultiselect])

  const toggleFieldsVisibility = () => {
    setShowMore((prev) => !prev)
  }

  return {
    fieldsToDisplay,
    showMore,
    displayToggle: fields.length > MAX_INITIAL_DISPLAY,
    toggleFieldsVisibility,
  }
}
