import { useEffect, useMemo } from 'react'

import { debug } from '@/utils/debug'
import { sendToLogger } from '@/utils/sendToLogger'

declare global {
  interface Window {
    _googCsa: (type: 'ads', options: AdSensePageOptions, ...containers: ContainersProps[]) => void
  }
}

export type AdSensePageOptions = {
  adPage?: string
  // AdSense client-ID that comes after 'partner-'.
  pubId?: string
  // ID of the search style, also called templateId
  styleId: string
  // Key to enable testing mode, should be
  adtest?: string
  // Search query entered by the user in the search bar
  query?: string
  // Used for tracking
  channel?: string
  adsafe?: 'high' | 'medium' | 'low'
  // Specifies whether clicking on an ad opens up in the same window or a new window.
  linkTarget?: '_blank' | '_top'
  // Maximum number of characters of a related search term including spaces
  maxTermLength?: number
  // Language for the ads
  hl?: string
  personalizedAds?: boolean
}

export type ContainersProps = {
  container: string
  // should ONLY be used for the top ad unit
  maxTop?: number
  width: string | number
  number?: number
}

export type useAdSenseProps = {
  type?: 'ads'
  options: AdSensePageOptions
  containers: ContainersProps[]
  active: boolean
}

/**
 * Hook for AdSense (custom search)
 *
 * Docs: https://support.google.com/adsense/answer/9055049?hl=en
 *
 * @param param0
 */
export const useAdSense = ({ type = 'ads', options, containers, active }: useAdSenseProps) => {
  const pageOptions: AdSensePageOptions = useMemo(
    () => ({
      pubId: options.pubId,
      query: options.query,
      linkTarget: options.linkTarget || '_blank',
      adsafe: options.adsafe || 'high',
      adPage: options.adPage || '1',
      channel: options.channel || 'Total',
      hl: options.hl || 'en',
      styleId: options.styleId,
      adtest: options.adtest,
      personalizedAds: options.personalizedAds || true,
    }),
    [options]
  )

  const typeMemo = useMemo(() => type, [type])
  const containersMemo = useMemo(() => containers, [containers])

  const generatePageOptionsTable = () => {
    // eslint-disable-next-line no-console
    console.table(pageOptions)
  }

  debug(generatePageOptionsTable)

  useEffect(() => {
    try {
      if (typeof window !== 'undefined' && active) {
        window._googCsa(typeMemo, pageOptions, ...containersMemo)
      }
    } catch (e) {
      sendToLogger(`'Could not initialize adsense ad block, _googCsa may be undefined', ${e}`, {
        tags: { component: 'adsense', fn: 'useAdSense' },
        fingerprint: ['useAdSense'],
      })
    }
    // TODO: Remove this eslint-disable when fix the issue with the react intersection observer
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active, pageOptions.query, pageOptions.pubId, pageOptions.styleId])
}
