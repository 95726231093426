import styled from 'styled-components'

export const PaginationContainer = styled.div(
  ({ theme }) => `
  display: flex;
  justify-content: center;
  margin: auto;
  margin-top: ${theme.spacing.large};
`
)
