// =============================================================================
//
// AdSense constants
//
// =============================================================================

export type AdsenseSlot = {
  clientId: string
  numberOfAds?: number
  numberOfRows: string
  subType: string
  templateId: string
}

export type AdSenseAdSettingsSlot = {
  settingsId: string
  adType: string
  settings: AdsenseSlot
}

export enum AdSenseType {
  BRP = 'brp',
  SRP = 'srp',
}

type PubIds =
  | 'mobile-kijiji-ca-browse'
  | 'mobile-kijiji-ca-search'
  | 'kijiji-ca-browse2'
  | 'kijiji-ca-search2'

type StyleIds = string

type DeviceSettings<T> = {
  mobile: T
  desktop: T
}

type AdSenseSettingsReturn = {
  pubId: DeviceSettings<
    | Extract<PubIds, 'mobile-kijiji-ca-browse' | 'mobile-kijiji-ca-search'>
    | Extract<PubIds, 'kijiji-ca-browse2' | 'kijiji-ca-search2'>
  >
  styleId: DeviceSettings<StyleIds>
}

export const AdSenseSettings: Record<AdSenseType, AdSenseSettingsReturn> = {
  [AdSenseType.BRP]: {
    pubId: {
      mobile: 'mobile-kijiji-ca-browse',
      desktop: 'kijiji-ca-browse2',
    },
    styleId: {
      desktop: '4711502865',
      mobile: '7574468603',
    },
  },
  [AdSenseType.SRP]: {
    pubId: {
      mobile: 'mobile-kijiji-ca-search',
      desktop: 'kijiji-ca-search2',
    },
    styleId: {
      desktop: '4292467516',
      mobile: '5174874674',
    },
  },
}
