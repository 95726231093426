import React, { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'

type LazyRenderProps = Readonly<{
  /** The content to be lazily rendered */
  children: React.ReactNode
  /**
   * A number between 0 and 1 indicating the percentage of the target element
   * that needs to be visible before triggering the callback
   */
  threshold?: number
  /**
   * Margin around the root. Can have values similar to the CSS margin property,
   * e.g. "10px 20px 30px 40px" (top, right, bottom, left)
   */
  rootMargin?: string
  /** Callback function to be executed when the element becomes visible */
  onVisible?: () => void
}>

/**
 * LazyRender Component
 *
 * This component uses the react-intersection-observer library to lazily render its children
 * when they come into view in the viewport. This is useful for performance optimization,
 * especially for long pages with many complex components.
 *
 * In testing environments, it can render children immediately based on an environment variable
 * or a global variable.
 *
 * @example
 * <LazyRender threshold={0.5} rootMargin="10px" onVisible={() => console.log('Visible!')}>
 *   <ExpensiveComponent />
 * </LazyRender>
 *
 * @param {LazyRenderProps} props - The properties that define the lazy rendering behavior
 * @returns {React.ReactElement} A div that will render its children when visible or immediately in test environments
 */
export function LazyRender({ children, threshold, rootMargin, onVisible }: LazyRenderProps) {
  const [isLazyLoadDisabled, setIsLazyLoadDisabled] = useState(false)

  // Check if lazy loading should be disabled (e.g., in testing environments)
  useEffect(() => {
    const disableFromEnv = process.env.NEXT_PUBLIC_DISABLE_LAZY_LOAD === 'true'

    // This is a global variable that we set in Playwright tests to disable lazy loading
    const disableFromGlobal = typeof window !== 'undefined' && window.__DISABLE_LAZY_LOAD__ === true
    setIsLazyLoadDisabled(disableFromEnv || disableFromGlobal)
  }, [])

  const { ref, inView } = useInView({
    threshold: threshold ?? 1,
    rootMargin: rootMargin ?? '0px',
    triggerOnce: true,
    skip: isLazyLoadDisabled,
  })

  useEffect(() => {
    if (inView && onVisible) {
      onVisible()
    }
  }, [inView, onVisible])

  // Render children immediately if lazy loading is disabled
  if (isLazyLoadDisabled) {
    return <>{children}</>
  }

  // Render the children only when visible
  return <div ref={ref}>{inView ? children : null}</div>
}
