import { type SearchCategory } from '@kijiji/generated/graphql-types'

import { seoUrlToString } from '@/domain/urls'

/**
 * Returns the most representative form of the current URL to avoid indexation duplication
 */
export const getSrpCanonicalUrl = (url: URL, category: SearchCategory) => {
  const isDomCat = !!url.searchParams.get('dc')

  /**
   * The domcat url does not include the category
   * so we need to get canonical url from the SearchCategory path
   */
  if (isDomCat && category.path) {
    // we want to use the last path segment as the canonical URL
    const canonicalUrl = category?.path[category.path.length - 1]?.seoUrl

    if (canonicalUrl) return canonicalUrl.split('?')[0]
  }

  /**
   * The canonical url should be the same as the current url without the query parameters
   * It will be everything before the "?" in the URL
   * Replace any $ in the canonical URL with %24 to reduce crawler page duplication (SEO)
   * */
  return seoUrlToString(url).split('?')[0].replace(/\$/g, '%24')
}
