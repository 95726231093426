import { AdSenseContainer } from '@/features/advertisement/components/adsense/styled'

export type AdSenseProps = {
  // Unique ID for the container
  id: string
  children?: React.ReactNode
}

/**
 * AdSense component used to display iframes from Google Adsense
 */
export const AdSense = ({ id, children }: AdSenseProps) => {
  /**
   * TODO: find better approach to handle top and bottom position and
   * make it scalable
   */
  return (
    <AdSenseContainer position={id === 'AFSTop' ? 'top' : 'bottom'} data-testid="adsense-container">
      <ins id={id} aria-hidden="true" />
      {children}
    </AdSenseContainer>
  )
}
