/**
 * Calculate and retrieve listing counts based on provided parameters.
 *
 * @param totalListings Total number of listings available.
 * @param currentPage Current page number in the pagination.
 * @param limit Listings limit per page.
 * @returns An object containing internationalization key and pagination details.
 *
 * @example
 * const listingDetails = getListingCounts(100, 1, 20);
 */
export function getListingCounts(totalListings: number, currentPage: number, limit: number) {
  const intl = 'header.results.count'
  if (totalListings === 0) {
    return { intl, params: { start: 0, end: 0, total: 0 } }
  }

  const start = Math.max(1, (currentPage - 1) * limit + 1)
  const end = Math.min(totalListings, currentPage * limit)

  return { intl, params: { start, end, total: totalListings } }
}
