import {
  type GetSearchResultsPageByUrlQuery,
  type UserLocation,
} from '@kijiji/generated/graphql-types'

import { DEFAULT_USER_LOCATION } from '@/constants/location'

export const getUserLocationFromSearchQuery = (
  searchQuery?: GetSearchResultsPageByUrlQuery['searchResultsPageByUrl']['searchQuery']
): UserLocation => {
  const locationArea = searchQuery?.area

  if (!searchQuery?.location?.id) return DEFAULT_USER_LOCATION

  const location: UserLocation = {
    id: searchQuery.location.id,
    name: searchQuery.location.name || { en_CA: '', fr_CA: '' },
    isRegion: !locationArea,
    area: locationArea
      ? {
          latitude: locationArea.latitude,
          longitude: locationArea.longitude,
          radius: locationArea.radius,
          address: locationArea.address || '',
        }
      : null,
  }

  return location
}
