import { type RobotsProps } from '@/domain/srp/seo/getSrpMetaData'
import { type MetaCategoryOverrideJson, type SrpMetaLegacyDetailsJson } from '@/types/seo'

/**
 * Creates robots meta tag content based on the results count.
 *
 * @param {number} resultsCount - The number of results to determine the robots meta tag content.
 * @returns {object | void} An object containing the noindex and nofollow properties.
 *
 * @example
 * createRobotsContent(0); // { nofollow: true, noindex: true }
 * createRobotsContent(5); // { noindex: true, nofollow: false }
 * createRobotsContent(6); // { noindex: false, nofollow: false }
 */
export const createRobotsContent = (resultsCount: number): RobotsProps => {
  // This is the case for BRP SRP
  if (resultsCount === 0) {
    return { nofollow: true, noindex: true }
  }

  // This is the case for low results SRP
  if (resultsCount <= 5) {
    return { noindex: true, nofollow: false }
  }

  return { noindex: false, nofollow: false }
}

export const isMetaCategoryOverrideJson = (value: unknown): value is MetaCategoryOverrideJson =>
  !!(value as MetaCategoryOverrideJson)?.en.length &&
  !!(value as MetaCategoryOverrideJson)?.fr.length

export const isSrpLegacyMetaDetailsJson = (value: unknown): value is SrpMetaLegacyDetailsJson =>
  !!(value as SrpMetaLegacyDetailsJson)?.en.length &&
  !!(value as SrpMetaLegacyDetailsJson)?.fr.length
