import styled from 'styled-components'

import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'

export const SrpGrid = styled.div(
  ({ theme }) => `
    display: grid;
    grid-template-columns: minmax(0, 1fr);

    ${MEDIA_QUERIES(theme).large} {
      grid-template-columns: 30rem minmax(0, 1fr);
      gap: 0 ${theme.spacing.default};
    }
  `
)
