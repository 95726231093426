import { GA_PAGE_TYPE } from '@/lib/ga/constants/datalayer'

export function determinePageNumber(pageNum: number, pageType?: GA_PAGE_TYPE): string {
  if (pageType === GA_PAGE_TYPE.VIEW_AD || pageType === GA_PAGE_TYPE.ZERO_SEARCH_RESULTS_PAGE) {
    return '1'
  }

  if (pageNum === 1) {
    return '1'
  } else if (pageNum >= 2 && pageNum <= 5) {
    return '2'
  } else if (pageNum >= 6) {
    return '3'
  }

  return '1'
}
