import CheckmarkSolidIcon from '@kijiji/icons/src/icons/CheckmarkSolid'
import styled from 'styled-components'

import { styleFromTypography } from '@/ui/helpers/styleFromTypography'

export const ChipLabel = styled.label(
  ({ theme }) => `
  ${styleFromTypography(theme.typography.body.medium)}
  align-items: center;
  background-color: ${theme.colors.white};
  border-radius: ${theme.borderRadius.small};
  border: .1rem solid ${theme.colors.grey.light3};
  color: ${theme.colors.grey.primary};
  cursor: pointer;
  display: inline-flex;
  flex-direction: row;
  min-height: ${theme.spacing.husky};
  padding: .7rem ${theme.spacing.default};

  &:hover {
    background-color: ${theme.colors.purple.light5};
  }
`
)

export const ChipCheckbox = styled.input(
  ({ theme }) => `
  opacity: 0;
  position: absolute;

  &:checked + label{
    background: ${theme.colors.purple.light5};
    border-color: ${theme.colors.purple.light4};
    color: ${theme.colors.purple.light1};
    padding-right: ${theme.spacing.defaultSmall}; 
  }

  &:hover:checked + label {
    background-color: ${theme.colors.purple.light4};
    border-color: ${theme.colors.purple.light3};
    color: ${theme.colors.purple.dark1};
    font-weight: ${theme.fontWeight.regular};
  }

  &:focus + label {
    box-shadow: 0 0 ${theme.spacing.defaultSmall} rgba(38, 129, 219, 0.25);
    border-color: ${theme.colors.blue.primary};
  }

  &:focus:not(:checked) + label {
    background-color: ${theme.colors.purple.light5};
    color: ${theme.colors.grey.primary};
    font-weight: ${theme.fontWeight.medium};
  }

  &:focus:checked + label {
    background: ${theme.colors.purple.light4};
    color: ${theme.colors.purple.dark1};
    font-weight: ${theme.fontWeight.regular};
  }

  &:disabled + label {
    background-color: ${theme.colors.grey.light4};
    border-color: ${theme.colors.grey.light3};
    color: ${theme.colors.grey.light2};
  }

  &:focus:not(:checked) + label {
    font-weight: ${theme.fontWeight.regular};
  }

  &:focus::checked + label {
    font-weight: ${theme.fontWeight.medium};
  } 
`
)

export const ChipCloseIcon = styled(CheckmarkSolidIcon)(
  ({ theme }) => `
  color: inherit;
  height: auto;
  width: ${theme.spacing.large};
`
)
