import styled from 'styled-components'

import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'

export const SrpErrorContainer = styled.div(
  ({ theme }) => `
  align-items: center;
  background: ${theme.colors.purple.light5};
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing.large} ${theme.spacing.large} ${theme.spacing.husky};
  width: 100%;


  ${MEDIA_QUERIES(theme).medium}{
    height: 70rem;
    padding: ${theme.spacing.default};
  }
`
)

export const SrpErrorImageContainer = styled.div(
  ({ theme }) => `
  height: 14rem;
  position: relative;
  width: 14.2rem;

  ${MEDIA_QUERIES(theme).medium}{
    height: 20.31rem;
    width: 20rem;
  }
`
)
