import { FC, HTMLAttributes } from 'react'

import { VisuallyHidden } from '@/ui/atoms/visually-hidden'

import { PaginationProps } from './Pagination'
import { PageNumber } from './styled'

type PaginationLinkProps = HTMLAttributes<HTMLAnchorElement> &
  Pick<PaginationProps, 'generateUrl'> & {
    /**
     * Defines if current pagination link is selected
     */
    isSelected?: boolean
    /**
     * Current link page number
     */
    pageNumber: number
    /**
     * Aria-label for pagination link
     */
    visuallyHiddenLabel: string
  }

export const PaginationLink: FC<PaginationLinkProps> = ({
  isSelected,
  pageNumber,
  visuallyHiddenLabel,
  generateUrl,
  ...rest
}) => {
  const { href, onClick } = generateUrl(pageNumber)
  return (
    <li
      data-testid={`${
        isSelected ? 'pagination-list-item-selected' : 'pagination-list-item'
      }`}
    >
      <PageNumber
        aria-current={isSelected ? 'page' : undefined}
        data-testid="pagination-link-item"
        href={isSelected ? '#' : href}
        onClick={isSelected ? undefined : onClick}
        {...rest}
      >
        <VisuallyHidden>{visuallyHiddenLabel} </VisuallyHidden>
        {pageNumber}
      </PageNumber>
    </li>
  )
}
