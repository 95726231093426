import { LoadingContainer } from '@/components/srp/srp-loading/styled'
import { type SkeletonProps, Skeleton } from '@/ui/atoms/skeleton'

export const LoadingBlockContainer = (props: SkeletonProps) => {
  return (
    <LoadingContainer data-testid="loading-srp-header">
      <Skeleton {...props} />
    </LoadingContainer>
  )
}
