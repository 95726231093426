import { type AppliedAttributeFilter, type AttributeFilter } from '@kijiji/generated/graphql-types'
import { useTranslation } from 'next-i18next'
import { type ReactNode, useEffect } from 'react'

import { type FilterProps } from '@/components/srp/filters/FiltersAccordion/FiltersAccordion'
import { getAttributeFilterDescription } from '@/domain/srp/filters/getAttributeFilterDescription'

type UseParentFilterProps = {
  attributeFilter?: AttributeFilter
  parentFilter?: FilterProps<AttributeFilter, AppliedAttributeFilter>['parentFilter']
  resetSelectedValues: () => void
}

type UseParentFilterReturn = {
  filterDescription: string | ReactNode
}

export const useParentFilter = ({
  attributeFilter,
  parentFilter,
  resetSelectedValues,
}: UseParentFilterProps): UseParentFilterReturn => {
  const { t } = useTranslation('srp')
  const getFilterDescription = () => {
    /**
     * Show parent label as description if there is a parent with no selected value
     */
    if (parentFilter && !parentFilter.isSelected) {
      return t('filters.select_parent_filter', { parentFilter: parentFilter.label })
    }

    /**
     * Should show selected values if there is no parent and values have been selected
     */
    if (attributeFilter?.selectedValues?.length) {
      return getAttributeFilterDescription(attributeFilter)
    }

    return ''
  }

  /**
   * If the current filter is not collapsed and depends on a parent
   * and its parent doesn't have a selected value
   * then clear the current filter values
   */
  useEffect(() => {
    if (parentFilter && !parentFilter.isSelected && attributeFilter?.selectedValues?.length) {
      resetSelectedValues()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentFilter?.isSelected])

  const filterDescription = getFilterDescription()
  return { filterDescription }
}
