import { ThemeProps } from '@kijiji/theme'
import styled, { DefaultTheme } from 'styled-components'

import { Fieldset } from '@/ui/atoms/fieldset'
import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'

import { CheckboxesFieldProps } from './CheckboxesField'

type StyledCheckboxesFieldsetProps = {
  $gap: string
  columns?: number
  $media: CheckboxesFieldProps['media']
}

const getGridTemplateColumnsByMedia = (
  theme: DefaultTheme,
  media: CheckboxesFieldProps['media'],
  breakpoint: keyof ThemeProps['breakpoints']
) => {
  return media?.[breakpoint]
    ? `${MEDIA_QUERIES(theme)[breakpoint]}{ 
    ${
      media?.[breakpoint]?.columns
        ? `grid-template-columns: repeat(${media?.[breakpoint]?.columns}, auto);`
        : ''
    }
   }`
    : ''
}

export const CheckboxesFieldset = styled(
  Fieldset
)<StyledCheckboxesFieldsetProps>(
  ({ theme, $gap, columns, $media }) => `
  display: grid;
  gap: ${$gap};
  ${columns ? `grid-template-columns: repeat(${columns}, auto);` : ''}

  ${getGridTemplateColumnsByMedia(theme, $media, 'small')}
  ${getGridTemplateColumnsByMedia(theme, $media, 'mediumSmall')}
  ${getGridTemplateColumnsByMedia(theme, $media, 'medium')}
  ${getGridTemplateColumnsByMedia(theme, $media, 'large')}
  ${getGridTemplateColumnsByMedia(theme, $media, 'xLarge')}

`
)
