import { theme } from '@kijiji/theme'
import { useTranslation } from 'next-i18next'

import {
  SearchAreaButtonStyles,
  SearchAreaButtonText,
  SearchAreaFlexContainer,
  SearchAreaIcon,
} from '@/features/map/components/search-button/styled'

type MapSearchButtonProps = {
  onClick: () => void
}

export const MapSearchButton = ({ onClick }: MapSearchButtonProps) => {
  const { t } = useTranslation(['srp'])

  return (
    <SearchAreaButtonStyles
      shape="pill"
      size="compact"
      type="button"
      onClick={onClick}
      variant="secondary"
      data-testid="search-button"
    >
      <SearchAreaFlexContainer
        justifyContent="space-around"
        alignItems="center"
        gap={theme.spacing.defaultSmall}
        flexGrow={1}
      >
        <SearchAreaIcon size="xsm" data-testid="search-icon" aria-hidden="true" />
        <SearchAreaButtonText>{t('map.srp.search_button')}</SearchAreaButtonText>
      </SearchAreaFlexContainer>
    </SearchAreaButtonStyles>
  )
}
