import styled from 'styled-components'

import { Button } from '@/ui/atoms/button'
import { Flex } from '@/ui/atoms/flex'

export const SaveSearchWrapper = styled(Flex)(
  () => `
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 10px;

  span {
    margin: 5px 0;
    font-size: 14px;
  }
`
)

export const SaveSearchFiltersContainer = styled.div<{ isPillVariant?: boolean }>(
  ({ theme, isPillVariant }) => `
  ${!isPillVariant ? `border-bottom: 0.1rem solid ${theme.colors.grey.light4};` : ''}
`
)

export const SaveSearchFilterToggle = styled(Button)<{
  isSelected?: boolean
  isPillVariant?: boolean
}>(
  ({ theme, isSelected = false, isPillVariant }) => `
  display: flex;
  gap: ${theme.spacing.mini};
  padding: ${theme.spacing.defaultSmall} ${theme.spacing.default};
  background-color: ${isSelected ? theme.colors.purple.light5 : theme.colors.white};
  border: 0.1rem solid ${theme.colors.grey.light3} !important;
  border-radius: ${isPillVariant ? theme.borderRadius.rounded : 'none'};
  min-height: ${isPillVariant ? '4rem' : '4.8rem'};

  svg {
    height: 1.8rem;
    width: 1.8rem;
  }
`
)

export const SaveSearchSubText = styled.span`
  text-align: center;
`
