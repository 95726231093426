import { type AppliedAttributeFilter, type AttributeFilter } from '@kijiji/generated/graphql-types'
import { useTranslation } from 'next-i18next'
import { type FC, type SyntheticEvent, useState } from 'react'
import { useTheme } from 'styled-components'

import { type FilterProps } from '@/components/srp/filters/FiltersAccordion/FiltersAccordion'
import { useParentFilter } from '@/hooks/filters/useParentFilter'
import { AccordionItem } from '@/ui/atoms/accordion'
import { Button } from '@/ui/atoms/button'
import { Flex } from '@/ui/atoms/flex'
import { RadiosField } from '@/ui/molecules/radios-field'

const MAX_INITIAL_DISPLAY = 5

export const RadioFilter: FC<FilterProps<AttributeFilter, AppliedAttributeFilter>> = ({
  filter,
  parentFilter,
  isMobile,
  refetch,
  onClickAccordionItem,
}) => {
  const { spacing } = useTheme()
  const { t } = useTranslation('srp')

  const { name, label, selectedValues, values } = filter
  const options = values || []

  const [showMore, setShowMore] = useState<boolean>(false)

  const { filterDescription } = useParentFilter({
    attributeFilter: filter,
    parentFilter,
    resetSelectedValues: () => refetch({ filterName: name, values: null }, `${name}=[undefined]`),
  })

  const valuesToDisplay = showMore ? options : options.slice(0, MAX_INITIAL_DISPLAY)

  const radios = valuesToDisplay.map((field) => {
    const canonicalValue = field.value
    const label = field.label
    const id = `${name}-${canonicalValue}${isMobile ? '-mobile' : ''}`

    return {
      id,
      label,
      name,
      onChange: (e: SyntheticEvent<HTMLInputElement>) => {
        const selectedValue = (e.target as HTMLInputElement).value
        const trackingLabel = `${name}=[${selectedValue}]`

        refetch({ filterName: name, values: [selectedValue] }, trackingLabel)
      },
      value: canonicalValue,
      checked: selectedValues?.includes(canonicalValue),
    }
  })

  const toggleShowMore = () => setShowMore(!showMore)

  return (
    <AccordionItem
      id={name}
      key={name}
      title={label || ''}
      description={filterDescription}
      isDisabled={parentFilter && !parentFilter.isSelected}
      onClick={onClickAccordionItem}
    >
      <Flex flexDirection="column" gap={spacing.default} data-testid="radio-filter">
        <RadiosField id={name} name={name} legend={label || ''} radios={radios} />

        {options?.length > MAX_INITIAL_DISPLAY && (
          <Button onClick={toggleShowMore} variant="tertiary">
            {showMore ? t('filters.show.less') : t('filters.show.more')}
          </Button>
        )}
      </Flex>
    </AccordionItem>
  )
}
