import { useDecision } from '@optimizely/react-sdk'
import dynamic from 'next/dynamic'
import { useSession } from 'next-auth/react'
import { useTranslation } from 'next-i18next'
import { useCallback } from 'react'
import { useTheme } from 'styled-components'

import { ShowAtOrLarger } from '@/components/shared/breakpoint'
import { ClientRender } from '@/components/shared/client-render'
import { SrpHeaderContainer, SrpHeaderSearchTitle } from '@/components/srp/header/styled'
import { SrpResultsSort } from '@/components/srp/results-sort'
import { SaveSearchGuestModal, SaveSearchToggle } from '@/components/srp/save-search'
import { LoadingSaveSearchToggle } from '@/components/srp/srp-loading/LoadingSaveSearchToggle'
import { LoadingSrpHeader } from '@/components/srp/srp-loading/LoadingSrpHeader'
import { ALL_CATEGORIES_ID_NUM } from '@/constants/category'
import { useMapSRP } from '@/features/map/hooks/useMapSRP'
import { useGetSearchResultsData } from '@/hooks/srp/useGetSearchResultsData'
import { useSaveSearchActions } from '@/hooks/useSaveSearchActions'
import { FEATURE_FLAG } from '@/lib/optimizely'
import { type IntlResponse, isIntlResponse } from '@/types/translations'
import { Divider } from '@/ui/atoms/divider'
import { Flex } from '@/ui/atoms/flex'
import { Spacing } from '@/ui/atoms/spacing'
import { Toggle } from '@/ui/atoms/toggle'

const SaveSearchFloatingButton = dynamic(
  () =>
    import('../save-search/SaveSearchFloatingButton').then((mod) => mod.SaveSearchFloatingButton),
  { ssr: false }
)

const QuickFilters = dynamic(() =>
  import('@/features/filters/components/QuickFilters/QuickFilters').then((mod) => mod.QuickFilters)
)

/**
 * Top of the search page
 */

export type SrpHeaderProps = {
  h1: string | IntlResponse
  currentPage: number
  loading: boolean
  isMobile?: boolean
  shouldRenderMapSRP: boolean
  setShouldRenderMapSRP: React.Dispatch<React.SetStateAction<boolean>>
}

export const SrpHeader = ({
  h1,
  currentPage,
  loading = true,
  isMobile,
  shouldRenderMapSRP,
  setShouldRenderMapSRP,
}: SrpHeaderProps) => {
  const { t } = useTranslation(['srp', 'filters'])
  const { colors, spacing } = useTheme()
  const { data } = useGetSearchResultsData()
  const { searchQuery } = data ?? {}
  const { data: userData } = useSession()
  const userId = parseInt(`${userData?.user.sub}`)

  const selectedCategoryId = searchQuery?.category?.id ?? ALL_CATEGORIES_ID_NUM

  const [saveSearchWithinFiltersDecision] = useDecision(FEATURE_FLAG.SAVED_SEARCH_WITHIN_FILTERS)
  const isMapSRPEnabled = useMapSRP(selectedCategoryId)

  const handleRenderMapView = useCallback(() => {
    setShouldRenderMapSRP((oldState) => !oldState)
  }, [setShouldRenderMapSRP])

  const [srpQuickFilterPillsDecision] = useDecision(
    FEATURE_FLAG.SRP_QUICK_FILTERS_PILLS,
    undefined,
    {
      overrideAttributes: {
        categoryId: selectedCategoryId,
      },
    }
  )
  const areQuickFilterPillsActive =
    srpQuickFilterPillsDecision?.enabled && srpQuickFilterPillsDecision?.variationKey === 'b'

  const shouldDisplayPageNumber = currentPage > 1

  const h1Text = isIntlResponse(h1) ? t(h1.intl, { ...h1.params }) : h1

  const keywords = searchQuery?.keywords ?? ''
  const searchString = searchQuery?.searchString ?? ''
  const {
    handleActivateSaveSearch,
    isSearchSaved,
    lookupLoading,
    activateError,
    deactivateError,
    handleCloseModal,
    isModalOpen,
    signInRedirectUrl,
  } = useSaveSearchActions({
    searchString,
  })
  // Determine whether to show SaveSearchToggle
  const showSaveSearchToggle = !(userId && saveSearchWithinFiltersDecision.enabled)

  return (
    <>
      {loading ? (
        <LoadingSrpHeader />
      ) : (
        <SrpHeaderContainer>
          <>
            <SrpHeaderSearchTitle
              aria-live="polite"
              color={colors.grey.primary}
              data-testid="srp-header-title"
              size="medium"
            >
              {h1Text}
              {shouldDisplayPageNumber &&
                t('header.results.title.page_number', { pageNumber: currentPage })}
            </SrpHeaderSearchTitle>
            <Flex gap="20px" justifyContent="flex-end"></Flex>
          </>
        </SrpHeaderContainer>
      )}

      {areQuickFilterPillsActive ? (
        <QuickFilters isMobile={isMobile} />
      ) : (
        <ShowAtOrLarger breakpoint="medium">
          <Divider bottom={spacing.default} top={spacing.default} />
        </ShowAtOrLarger>
      )}

      <Spacing mBottom="0" mTop={spacing.default} large={{ mTop: '0', mBottom: spacing.default }}>
        {loading ? (
          <LoadingSaveSearchToggle />
        ) : (
          <>
            <SaveSearchGuestModal
              error={!!activateError || !!deactivateError}
              handleCloseModal={handleCloseModal}
              isModalOpen={isModalOpen}
              keywords={keywords}
              signInRedirectUrl={signInRedirectUrl}
            />
            {!lookupLoading &&
              !isSearchSaved &&
              (!saveSearchWithinFiltersDecision.enabled || !userId) && (
                <ShowAtOrLarger breakpoint="large">
                  <Flex justifyContent="center">
                    <SaveSearchFloatingButton handleActivateSaveSearch={handleActivateSaveSearch} />
                  </Flex>
                </ShowAtOrLarger>
              )}

            <Flex justifyContent="space-between">
              {showSaveSearchToggle && <SaveSearchToggle />}
              <Flex gap="20px">
                {isMapSRPEnabled && (
                  <ClientRender>
                    <Toggle
                      isFullWidth={false}
                      isActive={shouldRenderMapSRP}
                      label={`${shouldRenderMapSRP ? 'Hide' : 'Render'} map`}
                      onClick={handleRenderMapView}
                    />
                  </ClientRender>
                )}
                {shouldRenderMapSRP && <SrpResultsSort />}
              </Flex>
            </Flex>
          </>
        )}
      </Spacing>
    </>
  )
}
