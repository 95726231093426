import { theme } from '@kijiji/theme'
import { type FC } from 'react'
import { useTheme } from 'styled-components'

import { LoadingFiltersContainer } from '@/components/srp/srp-loading/styled'
import { Divider } from '@/ui/atoms/divider'
import { Flex } from '@/ui/atoms/flex'
import { Skeleton } from '@/ui/atoms/skeleton'
import { Spacing } from '@/ui/atoms/spacing'

const LOADING_FILTERS_SIZE = {
  height: theme.spacing.default,
  width: '10.6rem',
}

type LoadingFilterProps = {
  titleSize?: 'default' | 'small'
  hideDivider?: boolean
}

export const LoadingFilter: FC<LoadingFilterProps> = ({ titleSize = 'default', hideDivider }) => {
  const { spacing } = useTheme()

  return (
    <LoadingFiltersContainer>
      <Spacing pBottom={spacing.default}>
        <Skeleton width={titleSize === 'default' ? '14rem' : '6.4rem'} height={spacing.default} />
      </Spacing>

      <Flex gap={spacing.defaultSmall} flexDirection="column">
        <Skeleton width={LOADING_FILTERS_SIZE.width} height={LOADING_FILTERS_SIZE.height} />
        <Skeleton width={LOADING_FILTERS_SIZE.width} height={LOADING_FILTERS_SIZE.height} />
        <Skeleton width={LOADING_FILTERS_SIZE.width} height={LOADING_FILTERS_SIZE.height} />
      </Flex>

      {!hideDivider && <Divider bottom={spacing.large} top={spacing.large} />}
    </LoadingFiltersContainer>
  )
}
