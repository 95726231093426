import { ThemeProps } from '@kijiji/theme'

import { Checkbox, CheckboxProps } from '@/ui/atoms/checkbox'
import { createSequentialId } from '@/ui/helpers/createSequentialId'
import { CommonInputFieldProps } from '@/ui/typings/commonTextInput'

import { FormControl } from '../form-control'
import { CheckboxesFieldset } from './styled'

export type CheckboxesFieldProps = {
  /**
   * List of checkboxes to be rendered
   */
  checkboxes: CheckboxProps[]
  /**
   * Legend that describes the checkbox group
   */
  legend: string
  /**
   * Gap between checkbox fields, it only works if there is more than one checkbox
   * @default 1.8rem
   */
  gap?: string
  /**
   * Number of columns the checkboxes will be displayed in
   */
  columns?: number
  /**
   * Media queries for the CheckboxesField style properties
   */
  media?: { [x in keyof ThemeProps['breakpoints']]?: { columns?: number } }
} & Omit<
  CommonInputFieldProps,
  'label' | 'autocomplete' | 'value' | 'maxLength'
>

/**
 * @description Group of Checkbox + Error fields.
 */
export const CheckboxesField = ({
  bottom,
  checkboxes,
  error,
  gap = '1.8rem',
  legend,
  columns,
  media,
  ...rest
}: CheckboxesFieldProps) => {
  const sequentialErrorId =
    error && createSequentialId('checkbox-group-error-')()

  const isSingleCheckbox = checkboxes?.length === 1

  const renderCheckbox = (checkbox: CheckboxProps) => {
    const { id, ...checkboxProps } = checkbox

    return (
      <Checkbox
        errorId={sequentialErrorId}
        id={id}
        key={`checkbox-${id}`}
        {...checkboxProps}
      />
    )
  }

  return (
    <FormControl
      bottom={bottom}
      data-testid={createSequentialId('checkboxes-field-')()}
      error={error}
      errorId={sequentialErrorId}
      {...rest}
    >
      {isSingleCheckbox ? (
        renderCheckbox(checkboxes[0])
      ) : (
        <CheckboxesFieldset
          legend={legend}
          $gap={gap}
          $media={media}
          columns={columns}
        >
          {checkboxes?.map((checkbox) => renderCheckbox(checkbox))}
        </CheckboxesFieldset>
      )}
    </FormControl>
  )
}
