import {
  FavouriteSearchIdLookupDocument,
  NotificationFrequency,
  NotificationMethod,
  useFavouriteSearchActivateMutation,
  useFavouriteSearchDeactivateMutation,
  useFavouriteSearchIdLookupQuery,
} from '@kijiji/generated/graphql-types'
import { useRouter } from 'next/router'
import { useSession } from 'next-auth/react'
import qs from 'query-string'
import { useCallback, useEffect, useState } from 'react'

import { isUserAuthenticated } from '@/features/auth/constants/user'
import { useGetLocation } from '@/hooks/location/useGetLocation'
import { trackEvent } from '@/lib/ga'
import { GA_EVENT } from '@/lib/ga/constants/gaEvent'
import { sendToLogger } from '@/utils/sendToLogger'

export type GaSaveSearchType = 'fab' | 'toggle' | 'btn' | 'btn_in_filters'
export type SaveSearchUseFeedbackType = 'saved' | 'removed'

export const SAVE_SEARCH_FEEDBACK_TYPE = { saved: 'saved', removed: 'removed' } as const

type SaveSearchActionsProps = {
  searchString: string
}

/**
 * Redirect url for users signing in before saving their search
 * It should take the user back to the srp with the same query parameters + saveSearch = true
 * The save search parameter will trigger a flow to save the proper cookie and trigger the save search mutation
 * */
const getSaveSearchRedirectUrl = (asPath: string, gaLabel: GaSaveSearchType) => {
  const { url, query } = qs.parseUrl(asPath)
  return qs.stringifyUrl({ url, query: { ...query, saveSearch: gaLabel } })
}

export const useSaveSearchActions = ({ searchString }: SaveSearchActionsProps) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [showUserFeedback, setShowUserFeedback] = useState<SaveSearchUseFeedbackType | null>(null)
  const [signInRedirectUrl, setSignInRedirectUrl] = useState<string>('')
  const { location } = useGetLocation()

  const { status: userSessionStatus, data: userData } = useSession()
  const { asPath } = useRouter()

  useEffect(() => {
    if (showUserFeedback === null) return

    const timer = setTimeout(() => {
      setShowUserFeedback(null)
    }, 3000)

    return () => clearTimeout(timer)
  }, [showUserFeedback])

  /** Query to get all user's saved searches */
  const {
    refetch,
    data,
    loading: lookupLoading,
  } = useFavouriteSearchIdLookupQuery({
    fetchPolicy: 'cache-first',
    skip: !isUserAuthenticated(userSessionStatus),
    variables: { input: { searchString } },
    onError: (err) =>
      sendToLogger(err, { fingerprint: ['useSaveSearchActions', 'favouriteSearchIdLookup'] }),
  })

  const searchLookup = data?.favouriteSearchIdLookup
  const isSearchSaved = !!searchLookup?.active

  /** Mutation to save a search */
  const [activateSearch, { error: activateError, loading: activateLoading }] =
    useFavouriteSearchActivateMutation({
      variables: {
        input: {
          searchString,
          notificationDetails: {
            frequency: NotificationFrequency.Day,
            notificationMethod: NotificationMethod.Email,
          },
          ...(location.area?.address && { address: location.area.address }),
        },
      },
      /** Provides user instant feedback and reverts cache if mutation errors out */
      optimisticResponse: { __typename: 'Mutation', favouriteSearchActivate: '' },
      update: (cache, { data }) => {
        /** Update lookup cache on mutation success */
        cache.writeQuery({
          query: FavouriteSearchIdLookupDocument,
          variables: { input: { searchString } },
          data: { favouriteSearchIdLookup: { active: true, id: data?.favouriteSearchActivate } },
        })
      },
    })

  /** Mutation to remove a saved search */
  const [deactivateSearch, { error: deactivateError, loading: deactivateLoading }] =
    useFavouriteSearchDeactivateMutation({
      onCompleted: () => {
        setShowUserFeedback(SAVE_SEARCH_FEEDBACK_TYPE.removed)
        trackEvent({
          action: GA_EVENT.SaveSearchDeleteSuccess,
          label: `btn=Watchlist;AlertID=${data?.favouriteSearchIdLookup?.id}`,
        })
      },
      onError: (err) =>
        sendToLogger(err, { fingerprint: ['useSaveSearchActions', 'favouriteSearchDeactivate'] }),
      /** Provides user instant feedback and reverts cache if mutation errors out */
      optimisticResponse: { __typename: 'Mutation', favouriteSearchDeactivate: null },
      update: (cache) => {
        /** Update lookup cache on mutation success */
        cache.writeQuery({
          query: FavouriteSearchIdLookupDocument,
          variables: { input: { searchString } },
          data: {
            favouriteSearchIdLookup: { active: false, id: data?.favouriteSearchIdLookup?.id },
          },
        })
      },
    })

  /** Function responsible for removing a saved search */
  const handleDeactivateSearch = async (favouriteSearchDeactivateId: string) => {
    /**
     * If the search has been saved, then an ID should be in state
     * If not, we try to fetch it before deselecting it
     * */
    if (!searchLookup?.id) {
      sendToLogger('Save Search - Refetching ID to deactivate', {
        fingerprint: ['useSaveSearchActions', 'refetchingIdToDeactivate'],
      })
      const { data } = await refetch({
        input: { searchString: searchString },
      })

      if (data.favouriteSearchIdLookup?.id) {
        deactivateSearch({
          variables: { favouriteSearchDeactivateId },
        })
        return
      }

      sendToLogger('Save Search - No id to deactivate', {
        tags: { userId: userData?.user.sub, searchString },
        fingerprint: ['useSaveSearchActions', 'noIdToDeactivate'],
      })
      return
    }

    /** If the search ID is already in state, deselect it */
    deactivateSearch({
      variables: { favouriteSearchDeactivateId },
    })
  }

  /** Function responsible for saving a search */
  const handleActivateSaveSearch = useCallback(
    async (type: GaSaveSearchType) => {
      if (isUserAuthenticated(userSessionStatus)) {
        trackEvent({ action: GA_EVENT.SaveSearchBegin, label: `type=${type}` })

        await activateSearch({
          onCompleted: () => {
            setShowUserFeedback(SAVE_SEARCH_FEEDBACK_TYPE.saved)
            trackEvent({ action: GA_EVENT.SaveSearchSuccess, label: `type=${type}` })
          },
          onError: (err) => {
            trackEvent({
              action: GA_EVENT.SaveSearchFail,
              label: `error=${err.message};type${type}`,
            })
            sendToLogger(err, { fingerprint: ['activateSaveSearch'] })
          },
        })
        return
      }

      const redirectUrl = getSaveSearchRedirectUrl(asPath, type)
      setSignInRedirectUrl(redirectUrl)

      handleOpenModal()
      trackEvent({ action: GA_EVENT.SaveSearchModalLoad, label: `type=${type}` })
    },
    [activateSearch, asPath, userSessionStatus]
  )

  const handleOpenModal = () => setIsModalOpen(true)
  const handleCloseModal = () => {
    trackEvent({ action: GA_EVENT.SaveSearchModalCancel })
    setIsModalOpen(false)
  }

  return {
    activateError,
    activateLoading,
    deactivateError,
    deactivateLoading,
    handleActivateSaveSearch,
    handleCloseModal,
    handleDeactivateSearch,
    isModalOpen,
    isSearchSaved,
    lookupLoading: userSessionStatus === 'loading' || lookupLoading,
    searchLookup,
    showUserFeedback,
    signInRedirectUrl,
  }
}
