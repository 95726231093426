import { type TFunction } from 'next-i18next'

import { isAmountPrice } from '@/domain/listings/isAmountPrice'
import { getSingleListingStructuredMarkup } from '@/features/seo/utils/getSingleListingStructuredMarkup'
import { type Listing } from '@/types/search'

/**
 * currently only supports Autos and its subcategories
 * @param listings topAds + organic listings
 * @param t i18n translation function
 * @returns structured markup for each listing on the SRP
 */
export const getListingsStructuredMarkup = (listings: Listing[], t: TFunction) => {
  const filteredListings = listings.filter((listing) => isAmountPrice(listing.price))

  const itemListElement = filteredListings.map((listing, index) => {
    return getSingleListingStructuredMarkup({ listing, t, position: index + 1 })
  })

  return itemListElement
}
