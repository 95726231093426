import { type AppliedAttributeFilter, type AttributeFilter } from '@kijiji/generated/graphql-types'
import { type FC } from 'react'
import { useTheme } from 'styled-components'

import { type FilterProps } from '@/components/srp/filters/FiltersAccordion/FiltersAccordion'
import { useParentFilter } from '@/hooks/filters/useParentFilter'
import { AccordionItem } from '@/ui/atoms/accordion'
import { Flex } from '@/ui/atoms/flex'
import { Toggle } from '@/ui/atoms/toggle'

export const ToggleFilter: FC<FilterProps<AttributeFilter, AppliedAttributeFilter>> = ({
  filter,
  parentFilter,
  refetch,
  onClickAccordionItem,
}) => {
  const { spacing } = useTheme()

  const { name, label, values, selectedValues } = filter

  const fields = values || []

  const { filterDescription } = useParentFilter({
    attributeFilter: filter,
    parentFilter,
    resetSelectedValues: () => refetch({ filterName: name, values: null }),
  })

  return (
    <AccordionItem
      description={filterDescription}
      id={name}
      isDisabled={parentFilter && !parentFilter.isSelected}
      key={name}
      title={label || ''}
      onClick={onClickAccordionItem}
    >
      <Flex flexDirection="column" gap={spacing.default} data-testid="toggle-filter">
        {fields.map((field, index) => {
          const canonicalValue = field.value
          const label = field.label || ''
          const id = `${name}-${canonicalValue}-${index}`
          const isSelected = selectedValues?.includes(canonicalValue)

          const onClick = () => {
            const updatedValues = isSelected
              ? selectedValues?.filter((v) => v !== canonicalValue) || []
              : [...(selectedValues || []), canonicalValue]

            const trackingLabel = `${canonicalValue.toLowerCase()}=[${Boolean(!isSelected)}]`
            refetch(
              { filterName: name, values: updatedValues.length ? updatedValues : null },
              trackingLabel
            )
          }

          return (
            <Toggle
              key={id}
              id={id}
              label={label}
              defaultChecked={isSelected}
              isActive={isSelected}
              onClick={onClick}
            />
          )
        })}
      </Flex>
    </AccordionItem>
  )
}
