import { z } from 'zod'

import { type LanguageKey } from '@/domain/locale'
import { type ContentfulDataContent } from '@/types/contentful'
import { sendToLogger } from '@/utils/sendToLogger'
import { getBaseUrl } from '@/utils/url'

/**
 * TODO: Replace with finalized contentful integration when ready
 */
const contentfulPathnames = new Set([
  '/b-apartments-condos/gta-greater-toronto-area/affordable-apartments/k0c37l1700272',
  '/b-apartments-condos/ontario/burlington/k0c37l9004',
  '/b-apartments-condos/burnaby-new-westminster/c37l1700286',
  '/b-apartments-condos/mississauga-peel-region/c37l1700276',
  '/b-apartments-condos/north-shore/c37l1700289',
  '/b-apartments-condos/gta-greater-toronto-area/north-york/k0c37l1700272',
  '/b-british-columbia/cabin-for-rent/k0l9007',
  '/b-vacation-rentals-saskatchewan/cabins-for-rent/k0c815l0',
  '/b-ontario/cheap-houses-for-sale/k0l9004',
  '/b-apartments-condos/gta-greater-toronto-area/vaughan/k0c37l1700272',
  '/b-apartments-condos/oakville-halton-region/c37l1700277',
  '/b-canada/houses-for-sale-in-laval/k0l0',
  '/b-jobs/ontario/c45l9004',
  '/b-appartement-condo/grand-montreal/c37l80002',
  '/b-maison-a-vendre/quebec/chalet/k0c35l9001',
  '/b-house-for-sale/saskatoon/c35l1700197',
  '/b-other-services/ontario/junk-removal/k0c87l9004',
  '/b-apartments-condos/vancouver/1+bedroom/c37l1700287a27949001',
  '/b-apartments-condos/gta-greater-toronto-area/1+bedroom/c37l1700272a27949001',
  '/b-cars-trucks/canada/audi-a5/c174l0a54a1000054',
  '/b-condo-for-sale/gta-greater-toronto-area/c643l1700272',
  '/b-electrician/gta-greater-toronto-area/c742l1700272',
  '/b-house-for-sale/gta-greater-toronto-area/brampton/k0c35l1700272',
  '/b-house-for-sale/gatineau/c35l1700186',
  '/b-house-for-sale/nova-scotia/c35l9002',
  '/b-house-for-sale/ottawa/c35l1700185',
  '/b-house-for-sale/windsor-area-on/c35l1700220',
  '/b-house-for-sale/london/c35l1700214',
  '/b-other-services/calgary/junk-removal/k0c87l1700199',
  '/b-cats-kittens/gta-greater-toronto-area/c125l1700272',
  '/b-other-services/gta-greater-toronto-area/grass-cutting/k0c87l1700272',
  '/b-patio-garden-furniture/gta-greater-toronto-area/c686l1700272',
  '/b-plumber/calgary/affordable-plumber/k0c752l1700199',
  '/b-plumber/gta-greater-toronto-area/plumbers/k0c752l1700272',
  '/b-plumber/halifax/c752l80010',
  '/b-services/ontario/snow-removal/k0c72l9004',
  '/b-cars-trucks/canada/toyota-yaris/c174l0a54a1000054',
  '/b-home-appliance/gta-greater-toronto-area/used-appliances/k0c107l1700272',
  '/b-cars-trucks/mississauga-peel-region/c174l1700276',
  '/b-maison-a-vendre/sherbrooke-qc/c35l1700156',
  '/b-classic-cars/canada/1968-dodge-charger/k0c122l0',
  '/b-classic-cars/canada/1969-dodge-charger/k0c122l0',
  '/b-classic-cars/canada/1969-mustang/k0c122l0',
  '/b-apartments-condos/calgary/2+bedrooms/c37l1700199a27949001',
  '/b-apartments-condos/gta-greater-toronto-area/3+bedrooms/c37l1700272a27949001',
  '/b-apartments-condos/ontario/3+bedrooms/c37l9004a27949001',
  '/b-apartments-condos/city-of-toronto/3-bedroom-house-rent/house/k0c37l1700273a29276001',
  '/b-apartments-condos/gta-greater-toronto-area/4-bedroom-house-rent/k0c37l1700272',
  '/b-ontario/apartment-for-rent-in-scarborough/k0l9004',
  '/b-apartments-condos/calgary/c37l1700199',
  '/b-cars-trucks/canada/audi-r8/c174l0a54a1000054',
  '/b-condo-for-sale/ottawa/c643l1700185',
  '/b-land-for-sale/ontario/farm-for-sale/k0c641l9004',
  '/b-hvac-service/gta-greater-toronto-area/hvac-technician/k0c747l1700272',
  '/b-jobs/calgary/c45l1700199',
  '/b-pet-services/gta-greater-toronto-area/pet-sitting/k0c143l1700272',
  '/b-rv-motorhome/gta-greater-toronto-area/c333l1700272',
  '/b-rv-motorhome/ontario/c333l9004',
  '/b-services/mississauga-peel-region/alteration/k0c72l1700276',
  '/b-canada/montreal-rent-apartment/k0l0',
  '/b-apartments-condos/fraser-valley/abbotsford/k0c37l1700139',
  '/b-apartments-condos/kingston-on/c37l1700183',
  '/b-apartments-condos/london/c37l1700214',
  '/b-apartments-condos/ontario/c37l9004',
  '/b-apartments-condos/vancouver/c37l1700287',
  '/b-apartments-condos/gta-greater-toronto-area/c37l1700272',
  '/b-gta-greater-toronto-area/basement-renovations/k0l1700272',
  '/b-grand-montreal/boat-for-sale/k0l80002',
  '/b-house-for-sale/ottawa/bungalow/k0c35l1700185',
  '/b-condo-for-sale/calgary/c643l1700199',
  '/b-apartments-condos/canada/house/c37l0a29276001',
  '/b-cleaners-cleaning-service/gta-greater-toronto-area/cleaning-lady/k0c160l1700272',
  '/b-ontario/commercial-property-for-sale/k0l9004',
  '/b-condo-for-sale/saskatoon/c643l1700197',
  '/b-condo-for-sale/canada/c643l0',
  '/b-vacation-rentals-nova-scotia/c811l0',
  '/b-ontario/cottage-for-rent/k0l9004',
  '/b-real-estate/ontario/cottages-for-sale/k0c34l9004',
  '/b-furniture/ontario/couch/k0c235l9004',
  '/b-pet-services/gta-greater-toronto-area/dog-walker/k0c143l1700272',
  '/b-house-for-sale/ontario/duplex/k0c35l9004',
  '/b-refrigerator-fridge/canada/c696l0',
  '/b-hvac-service/gta-greater-toronto-area/furnace-repair/k0c747l1700272',
  '/b-house-for-sale/canada/c35l0',
  '/b-apartments-condos/hamilton/house/c37l80014a29276001',
  '/b-house-for-sale/barrie/c35l1700006',
  '/b-house-for-sale/cambridge/c35l1700210',
  '/b-house-for-sale/fredericton/c35l1700018',
  '/b-house-for-sale/calgary/c35l1700199',
  '/b-house-for-sale/regina/c35l1700196',
  '/b-house-for-sale/kingston-on/c35l1700183',
  '/b-house-for-sale/peterborough/c35l1700218',
  '/b-house-for-sale/winnipeg/c35l1700192',
  '/b-canada/cottages-for-rent-kawartha-lake/k0l0',
  '/b-land-for-sale/ontario/c641l9004',
  '/b-muskoka/cottage-for-rent/k0l1700078',
  '/b-video-games-consoles/canada/ps5/k0c141l0',
  '/b-real-estate-service/ontario/rent-to-own/k0c170l9004',
  '/b-roofing-service-roofer/mississauga-peel-region/roof-repair-brampton/k0c754l1700276',
  '/b-snowblower/gta-greater-toronto-area/snowblower/k0c688l1700272',
  '/b-furniture/gta-greater-toronto-area/sofa/k0c235l1700272',
  '/b-house-for-sale/thunder-bay/c35l1700126',
  '/b-ontario/tiny-house/k0l9004',
  '/b-apartments-condos/calgary/townhouse-rent/k0c37l1700199',
  '/b-video-games-consoles/ontario/buy-used-games/k0c141l9004',
  '/b-apartments-condos/winnipeg/c37l1700192',
  '/b-apartments-condos/gta-greater-toronto-area/2+bedrooms/c37l1700272a27949001',
  '/b-apartments-condos/ottawa/c37l1700185',
  '/b-ottawa/cat-sitter/k0l1700185',
  '/b-condo-for-sale/mississauga-peel-region/c643l1700276',
  '/b-house-for-sale/moncton/c35l1700001',
  '/b-house-for-sale/guelph/c35l1700242',
  '/b-house-for-sale/kelowna/c35l1700228',
  '/b-house-for-sale/ontario/burlington/k0c35l9004',
  '/b-house-for-sale/kitchener-waterloo/c35l1700212',
  '/b-house-for-sale/moose-jaw/c35l1700195',
  '/b-house-for-sale/oshawa-durham-region/c35l1700275',
  '/b-house-for-sale/prince-edward-island/c35l9011',
  '/b-house-for-sale/city-of-toronto/c35l1700273',
  '/b-exercise-equipment/canada/treadmill/k0c655l0',
  '/b-gta-greater-toronto-area/cheap-used-tires/k0l1700272',
  '/b-apartments-condos/edmonton/c37l1700203',
  '/b-apartments-condos/halifax/c37l80010',
  '/b-apartments-condos/hamilton/c37l80014',
  '/b-house-for-sale/brantford/c35l1700206',
  '/b-house-for-sale/lethbridge/c35l1700230',
  '/b-house-for-sale/medicine-hat/c35l1700231',
  '/b-house-for-sale/north-bay/c35l1700243',
  '/b-house-for-sale/hamilton/c35l80014',
  '/b-apartments-condos/gta-greater-toronto-area/house/c37l1700272a29276001',
  '/b-apartments-condos/gta-greater-toronto-area/brampton/k0c37l1700272',
  '/b-apartments-condos/gta-greater-toronto-area/brampton/house/k0c37l1700272a29276001',
  '/b-apartments-condos/calgary/house/c37l1700199a29276001',
  '/b-real-estate/british-columbia/surrey-for-rent/k0c34l9007',
  '/b-apartments-condos/ottawa/house/c37l1700185a29276001',
  '/b-land-for-sale/gta-greater-toronto-area/c641l1700272',
  '/b-house-for-sale/ontario/multi-family/k0c35l9004',
  '/b-commercial-office-space/gta-greater-toronto-area/c40l1700272',
  '/b-ontario/suvs-for-sale-by-owner/k0l9004',
  '/b-real-estate/ontario/warehouse/k0c34l9004',
])

export type GetSrpContentfulDataProps = {
  languageKey: LanguageKey
  url: URL
}

// Zod schema to validate the content
const ContentfulDataContentSchema = z.object({
  textJson: z.array(
    z.object({
      type: z.string(),
      content: z.string(),
    })
  ),
})

/**
 * Fetches content data for a given SRP URL and language from the content.json file.
 *
 * This function checks if the URL is eligible for content.json data, fetches the content
 * from the content.json via an API call, and returns the data in a structured format.
 *
 * @param {Object} params - The parameters for fetching SRP content data.
 * @param {LanguageKey} params.languageKey - The language key for the requested content.
 * @param {URL} params.url - The URL object representing the SRP page.
 * @returns {Promise<ContentfulDataContent | undefined>} The fetched and validated content, or undefined if not available, not eligible, or on error.
 *
 * @example
 * import { getSrpContentfulData } from './getSrpContentfulData';
 *
 * const fetchSrpContent = async () => {
 *   const url = new URL('https://www.kijiji.ca/b-apartments-condos/gta-greater-toronto-area/c37l1700272');
 *   const languageKey = 'en';
 *
 *   try {
 *     const content = await getSrpContentfulData({ languageKey, url });
 *     if (content) {
 *       console.log('SRP Content:', content);
 *     } else {
 *       console.log('No content available for this SRP');
 *     }
 *   } catch (error) {
 *     console.error('Error fetching SRP content:', error);
 *   }
 * };
 *
 * fetchSrpContent();
 */

export const getSrpContentfulData = async ({
  languageKey,
  url,
}: GetSrpContentfulDataProps): Promise<ContentfulDataContent | undefined> => {
  if (!contentfulPathnames.has(url.pathname) || url.searchParams.has('address')) {
    return undefined
  }

  try {
    const response = await fetch(
      `${getBaseUrl()}/api/content?url=${encodeURIComponent(url.pathname)}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
        },
      }
    )

    if (!response.ok) {
      sendToLogger('Failed to fetch content', {
        fingerprint: ['getSrpContentfulData'],
        extra: {
          status: response.status,
          statusText: response.statusText,
          url: url.pathname,
        },
      })
      return undefined
    }

    const data = await response.json()
    const languageData: object = data[languageKey]

    const parseResult = ContentfulDataContentSchema.safeParse(languageData)

    if (!parseResult.success) {
      // Only log if both requested language and English content are missing or invalid, we currently only have English content
      if (languageKey === 'en' || !data['en']) {
        sendToLogger('Invalid or missing content for all available languages', {
          fingerprint: ['getSrpContentfulData'],
          extra: {
            languageKey,
            url: url.pathname,
            content: JSON.stringify(languageData),
            zodErrors: parseResult.error.errors,
          },
        })
      }
      return undefined
    }

    return parseResult.data.textJson
  } catch (error) {
    sendToLogger('Error fetching or parsing Contentful data', {
      fingerprint: ['getSrpContentfulData'],
      extra: {
        error: error instanceof Error ? error.message : String(error),
        url: url.pathname,
      },
    })
    return undefined
  }
}
