import { type ApiLocale, API_LOCALE } from '@/domain/locale'

type SrpStaticMetadata = {
  title: string
  description: string
}

/**
 * Responsible for returning the static translation for title and description of search page
 * When there is no "Keyword", it should always take the static title/description
 * When there is a "Keyword" and reading the excel file fails, then take the static fallback.
 */
export const getSrpMetaStaticFallback = ({
  apiLocale,
  keyword,
  locationName,
  categoryName,
}: {
  apiLocale?: ApiLocale
  keyword?: string | null
  locationName: string
  categoryName: string
}): SrpStaticMetadata => {
  /**
   * TODO: After the translation package change we should import the strings from: srp.meta for title and description
   * Since this is used by SSR function, we can't easily import the file translations here
   */

  if (keyword) {
    if (apiLocale === API_LOCALE.en_CA) {
      return {
        title: `${keyword} in ${categoryName} in ${locationName} | Kijiji Marketplaces`,
        description: `Find ${keyword} in ${categoryName} in ${locationName}. Visit Kijiji Classifieds to buy, sell, or trade almost anything! Find new and used items, cars, real estate, jobs, services, vacation rentals and more virtually in ${locationName}.`,
      }
    }

    return {
      title: `${keyword} dans ${categoryName} dans ${locationName} | Kijiji Marketplaces`,
      description: `Trouvez ${keyword} dans ${categoryName} dans ${locationName}. Visitez Petites Annonces Kijiji pour acheter, vendre ou échanger presque tout! Trouvez des objets neufs et d'occasion, des voitures, des biens immobiliers, des emplois, des services, des locations de vacances et bien plus encore virtuellement dans ${locationName}.`,
    }
  }

  /**
   * Fallback translations for when the categoryId is not on the excel sheet
   * OR when fetching the excel sheet fails
   */
  if (apiLocale === API_LOCALE.en_CA) {
    return {
      title: `${categoryName} in ${locationName} - Buy, Sell & Save with Canada's #1 Local Marketplaces`,
      description: `Browse ${categoryName} in ${locationName}. Visit Kijiji Classifieds to buy, sell, or trade almost anything! Find new and used items, cars, real estate, jobs, services, vacation rentals and more virtually in ${locationName}.`,
    }
  }

  return {
    title: `${categoryName} dans ${locationName} - Achetez, vendez et économisez avec le site de petites annonces locales n°1 au Canada`,
    description: `Parcourez ${categoryName} dans ${locationName}. Visitez Petites Annonces Kijiji pour acheter, vendre ou échanger presque tout! Trouvez des objets neufs et d'occasion, des voitures, des biens immobiliers, des emplois, des services, des locations de vacances et bien plus encore virtuellement dans ${locationName}.`,
  }
}
