import { GPT_KEYS } from '@/features/advertisement/constants/gpt'
import { getSearchPageType } from '@/lib/ga/utils/getSearchPageType'

/**
 * Returns an object with the page type label key-value pair, using the value returned by the
 * `getSearchPageType` function.
 *
 * @param {string} keywords - The search keywords to use for the label.
 * @returns {Object} An object containing the page type label key-value pair.
 * @example
 *
 * const pageTypeLabel = createPageTypeLabel('couch');
 * console.log(pageTypeLabel); // { key: 'pt', value: 'ResultsBrowse' }
 */
export function createPageTypeLabel(keywords?: string) {
  return {
    key: GPT_KEYS.PAGE_TYPE_LABEL,
    value: getSearchPageType(keywords),
  }
}

/**
 * Returns an object with the page type label key-value pair, using the value of the `pageTypeLabelKj`
 * variable.
 *
 * @param {string} pageTypeLabelKj - The page type label to use for the label.
 * @returns {Object} An object containing the page type label key-value pair.
 * @example
 *
 * const pageTypeLabelKjObject = createPageTypeLabelKj('homepage');
 * console.log(pageTypeLabelKjObject); // { key: 'pagetype', value: 'homepage' }
 */
export function createPageTypeLabelKj(pageTypeLabelKj: string) {
  return {
    key: GPT_KEYS.PAGE_TYPE_LABEL_KJ,
    value: pageTypeLabelKj,
  }
}

/**
 * Returns an object with the page target key-value pair, using the string representation of the
 * `currentPage` variable.
 *
 * @param {number} currentPage - The current page to use for the label.
 * @returns {Object} An object containing the page target key-value pair.
 * @example
 *
 * const pageTargetLabel = createPageTargetLabel(3);
 * console.log(pageTargetLabel); // { key: 'g_pn', value: '3' }
 */
export function createPageTargetLabel(currentPage: number) {
  return {
    key: GPT_KEYS.PAGE_TARGET,
    value: String(currentPage),
  }
}
