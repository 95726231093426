import styled from 'styled-components'

import { HeadlineText } from '@/ui/atoms/headline-text'
import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'

type SkyscraperAdSlotContainerProps = {
  areQuickFilterPillsActive: boolean
}

export const FiltersSidebarPanel = styled.div(({ theme }) => {
  return `
    background: ${theme.colors.white};
    border: .1rem solid ${theme.colors.grey.light4};
    display: none;
    padding: 0;

    ${MEDIA_QUERIES(theme).large}{
      display: block;
    }
  `
})

export const FiltersSidebarTitle = styled(HeadlineText)(
  ({ theme }) => `
  padding: 1.4rem ${theme.spacing.default};
`
)

export const SkyscraperAdSlotContainer = styled.div<SkyscraperAdSlotContainerProps>(
  ({ theme, areQuickFilterPillsActive }) => `
    display: none;
    justify-content: center;
    margin-top:  ${theme.spacing.large};
    overflow: hidden;
    position: sticky;
    top: ${areQuickFilterPillsActive ? '9rem' : '0'};

    ${MEDIA_QUERIES(theme).large}{
      display: flex;
    }
  `
)
