import { theme } from '@kijiji/theme'
import { useTranslation } from 'next-i18next'

import { ImageCustom } from '@/components/shared/image'
import { LinkCustom } from '@/components/shared/link-custom/LinkCustom'
import {
  TicketListingCardDescription,
  TicketListingFooter,
  TicketListingImageBase,
  TicketListingPolicyButton,
} from '@/components/srp/listing-card/ticket-listing-card/styled'
import { BodyText } from '@/ui/atoms/body-text'
import { Card } from '@/ui/atoms/card'
import { Flex } from '@/ui/atoms/flex'

export const TicketListingCard = () => {
  const { t } = useTranslation('srp')

  return (
    <Card data-testid="ticket-listing-card">
      <Flex columnGap={theme.spacing.default}>
        <TicketListingImageBase
          imageComponent={
            <ImageCustom
              alt={t('listing.tickets.logo_alt')}
              fill
              src="/next-assets/images/kijiji-logo.svg"
            />
          }
        />
        <Flex alignItems="center">
          <Flex flexDirection="column" gap={theme.spacing.mini}>
            {/* TODO: Figure out why "as" prop isn't working correctly. Ex. as="span" or as="h3" */}
            <BodyText color={theme.colors.grey.dark3} size="large" weight="bold">
              {t('listing.tickets.header')}
            </BodyText>
            <TicketListingCardDescription>{t('listing.tickets.body')}</TicketListingCardDescription>
            <TicketListingFooter>
              <LinkCustom
                href={t('listing.tickets.contact_button.href')}
                button={true}
                variant="primary"
              >
                {t('listing.tickets.contact_button.text')}
              </LinkCustom>
              <TicketListingPolicyButton
                href={t('listing.tickets.ticket_policy_button.href')}
                button={true}
              >
                {t('listing.tickets.ticket_policy_button.text')}
              </TicketListingPolicyButton>
            </TicketListingFooter>
          </Flex>
        </Flex>
      </Flex>
    </Card>
  )
}
