import styled from 'styled-components'

import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'

type adsenseContainerProps = {
  position?: string
}

/**
 * position prop is used to make sure we set min-height for top leader board (maintaing old logic)
 * Purpose - so that we don't cause layout shift for the top position of adsense.
 *
 * position => top ( for top leader board - adsense)
 * position => bottom ( for mini leader board - adsense)
 */
export const AdSenseContainer = styled.div<adsenseContainerProps>(
  ({ theme, position }) => `
  background-color: ${theme.colors.white};
  border-radius: ${theme.borderRadius.medium};
  border: .1rem solid ${theme.colors.grey.light4};
  min-height: ${position === 'top' ? '30rem' : 'auto'}; 
  position: relative;

  ${MEDIA_QUERIES(theme).medium} {
    min-height: 21rem;
  }
`
)
