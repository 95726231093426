import { spacing } from '@kijiji/theme'
import { useSelect } from 'downshift'
import { HTMLAttributes } from 'react'

import { Flex } from '@/ui/atoms/flex'
import { FloatingLabel } from '@/ui/atoms/floating-label'
import { RotatingChevron } from '@/ui/atoms/rotating-chevron'
import { createSequentialId } from '@/ui/helpers/createSequentialId'
import { CommonInputFieldProps } from '@/ui/typings/commonTextInput'

import { FormControl } from '../form-control'
import {
  LabelContainer,
  SelectButton,
  SelectButtonContent,
  SelectList,
  SelectListItem,
  SelectListWrapper,
} from './styled'

export type SelectItem = {
  icon?: React.ReactNode
  label: string
  value: string | number
  disabled?: boolean
  badge?: React.ReactNode
}

export enum SelectVariant {
  DEFAULT = 'default',
  ROUNDED = 'rounded',
}

export type SelectProps = {
  /**
   * List of items that should appear in the select list
   */
  options: SelectItem[]
  /**
   * Maximum height for all select options.
   */
  maxHeight?: string
  /**
   * Defines if select is used in a group.
   * It will change styling to allow grouping with other fields.
   */
  isGrouped?: boolean
  /**
   * Function triggered when an item is selected
   */
  onSelectChange: (value?: SelectItem) => void
  /**
   * Initial value of the select
   * The initial value should be passed if this is a controlled component
   */
  selectedValue?: SelectItem['value']
  /**
   * Variant of the select
   */
  variant?: SelectVariant
  /**
   * Icon to be rendered on the left of the select
   */
  leftIcon?: React.ReactNode
} & Omit<CommonInputFieldProps, 'maxLength' | 'value'> &
  HTMLAttributes<HTMLButtonElement>

/**
 * Select component
 */
export const Select = ({
  bottom = '2rem',
  error,
  helperText,
  id,
  isGrouped,
  label,
  maxHeight = '20rem',
  onSelectChange,
  options = [],
  required,
  selectedValue,
  variant = SelectVariant.DEFAULT,
  leftIcon = null,
  ...rest
}: SelectProps) => {
  /** Find option based on its value */
  let selectedItem = options.find(({ value }) => value === selectedValue)
  /**
   * The select should go from uncontrolled to controlled
   * If we are controlling the selected item, it should always have a value
   * It will default to the first item of the select if can't find the new value in the options
   */
  if (!!selectedValue && !selectedItem) {
    selectedItem = options[0]
  }

  /** Setup downshift */
  const {
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
    closeMenu,
  } = useSelect({
    id,
    items: options,
    selectedItem,
    itemToString: (item) => item?.label || '',
    onStateChange: ({ type, selectedItem: newSelectedItem }) => {
      if (
        newSelectedItem &&
        !newSelectedItem.disabled &&
        type === useSelect.stateChangeTypes.ToggleButtonKeyDownEnter
      ) {
        if (newSelectedItem.value !== selectedItem?.value) {
          onSelectChange(newSelectedItem)
        }
        closeMenu()
      }
    },
  })

  const sequentialErrorId = error && createSequentialId('select-error-')()

  return (
    <FormControl
      bottom={bottom}
      error={error}
      helperText={helperText}
      id={id}
      isFullWidth
    >
      <LabelContainer variant={variant} hasSelectedIcon={!!selectedItem?.icon}>
        <div>
          {variant === SelectVariant.DEFAULT && (
            <FloatingLabel
              hasError={error}
              htmlFor={id}
              isFocused={selectedItem}
              {...getLabelProps()}
            >
              {label}
            </FloatingLabel>
          )}

          <SelectButton
            isGrouped={isGrouped}
            hasError={!!error}
            hasSelectedIcon={!!selectedItem?.icon}
            variant={variant}
            {...rest}
            {...getToggleButtonProps({
              ...(variant === SelectVariant.ROUNDED
                ? { 'aria-label': label }
                : {}),
              'aria-describedby': sequentialErrorId,
              id: id,
              type: 'button',
            })}
          >
            <SelectButtonContent variant={variant}>
              {leftIcon || selectedItem?.icon}
              {selectedItem?.label}
              {selectedItem?.badge}
            </SelectButtonContent>

            <RotatingChevron isOpen={isOpen} />
          </SelectButton>
        </div>
      </LabelContainer>

      <SelectListWrapper isOpen={isOpen} variant={variant}>
        <SelectList
          aria-required={required}
          maxHeight={maxHeight}
          {...getMenuProps()}
        >
          {isOpen &&
            options.map((item, index) => (
              <SelectListItem
                as="li"
                isHighlighted={highlightedIndex === index}
                key={`select-list-item-${id}-${item.value}`}
                tabIndex={0}
                {...getItemProps({
                  item,
                  index,
                  onClick: () => {
                    if (item.disabled) return
                    onSelectChange(item)
                    closeMenu()
                  },
                })}
                isDisabled={item.disabled}
                aria-disabled={item.disabled}
                variant={variant}
              >
                <Flex alignItems="center" gap={spacing.default}>
                  {item.icon}
                  {item.label}
                  {item.badge}
                </Flex>
              </SelectListItem>
            ))}
        </SelectList>
      </SelectListWrapper>
    </FormControl>
  )
}

Select.whyDidYouRender = false
