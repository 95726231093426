import { type RefObject, useEffect, useState } from 'react'

export const useIframeObserverDisplayAds = (ref: RefObject<HTMLDivElement>) => {
  const [iframe, setIframe] = useState<HTMLIFrameElement | null>(null)

  useEffect(() => {
    const adSlot = ref.current

    if (!adSlot) {
      return
    }

    // the target will not always be the iframe, so we need to find it among the descendants
    const findFirstIframe = (node: Node | null): HTMLIFrameElement | null => {
      if (!node) {
        return null
      }

      if (node instanceof HTMLIFrameElement) {
        return node
      }

      const children = node.childNodes
      for (const child of children) {
        const childIframe = findFirstIframe(child)
        if (childIframe) {
          return childIframe
        }
      }

      return null
    }

    const observer = new MutationObserver((mutations) => {
      const mutationsLastElement = mutations[mutations.length - 1]
      const target = mutationsLastElement.target

      setIframe(findFirstIframe(target))
    })

    observer.observe(adSlot, {
      childList: true,
      subtree: true,
    })

    return () => {
      observer.disconnect()
    }
  }, [ref])

  return iframe
}
