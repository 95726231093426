import { type AppliedAttributeFilter } from '@kijiji/generated/graphql-types'
import { capitalizeWords } from '@kijiji/string'

/**
 * Extracts the filter values from the search query data and returns them as a comma-separated string.
 * Each word in the filter values is capitalized. Only values from allowed filter names are included.
 *
 * @param filters - The search query data containing filters.
 * @param allowedFilterNames - The list of filter names to include in the output.
 * @returns A string with allowed filter values separated by commas and each word capitalized.
 *
 * @example
 * const filters = {
 *   filters: [
 *     {
 *       filterName: "jewellerytype",
 *       values: ["necklaces"]
 *     },
 *     {
 *       filterName: "location",
 *       values: ["Brantford"]
 *     }
 *   ]
 * };
 *
 * const filterString = getFilterValuesString(filters.filters, ["jewellerytype"]);
 * console.log(filterString); // "Necklaces"
 */
export function getFilterValuesString(
  filters: AppliedAttributeFilter[],
  allowedFilterNames: string[]
): string {
  const filterValues = filters
    .filter((filter) => allowedFilterNames.includes(filter.filterName))
    .map((filter) => filter.values)
    .flat()
    .map(capitalizeWords)
    .join(', ')

  return filterValues
}
