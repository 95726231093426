import { type ListingFragment } from '@kijiji/generated/graphql-types'
import {
  type MapCameraChangedEvent,
  type MapEvent,
  APIProvider as GoogleAPIProvider,
  Map as GoogleMap,
  useMap,
} from '@vis.gl/react-google-maps'
import { type ReactNode, useCallback, useEffect, useState } from 'react'

import { type LanguageKey } from '@/domain/locale'
import { defaultLocation, MAP_ID } from '@/features/map/components/constants'
import { MapSearchButton } from '@/features/map/components/search-button/MapSearchButton'
import { getGoogleMapsKey } from '@/features/map/utils/getGoogleMapsKey'
import { useGetLocation } from '@/hooks/location/useGetLocation'
import { useSearchActions } from '@/hooks/srp'
import { useFetchLocationFromCoordinates } from '@/hooks/useFetchLocationFromCoordinates'
import { useLocale } from '@/hooks/useLocale'

type MapProviderProps = {
  children: ReactNode
  provider?: string
}

/**
 * Props for the Map component.
 *
 * @see https://visgl.github.io/react-google-maps/docs - Documentation for **vis.gl** (if integrating or using vis.gl for other mapping libraries).
 * @see https://developers.google.com/maps/documentation/javascript/reference/map - Documentation for **Google Maps** API.
 */
type MapProps = {
  provider?: string
  listings?: ListingFragment[]
} & React.ComponentProps<typeof GoogleMap>

/**
 * MapProvider component that manages the integration with different map providers.
 * Currently supports the 'google' provider, fetching the API key dynamically based on the language key.
 *
 * @param {Object} props - The component's props.
 * @param {React.ReactNode} props.children - The child components that will be rendered within the MapProvider.
 * @param {string} [props.provider='google'] - The map provider to use. Defaults to 'google'.
 *
 * @returns {React.ReactNode | null} - The component returns either the GoogleAPIProvider with the children or null if an unsupported provider is specified.
 *
 * @note This component fetches the Google Maps API key based on the current language and provider settings.
 */
const MapProvider: React.FC<MapProviderProps> = ({ children, provider = 'google' }) => {
  const { languageKey } = useLocale()
  const [googleApiKey, setGoogleApiKey] = useState<string>('')
  const [error, setError] = useState<string | null>(null)
  const [loading, setLoading] = useState<boolean>(true)

  const getGoogleMapsAPIKeyByInstance = async (languageKey: LanguageKey) => {
    try {
      const { apiKey } = await getGoogleMapsKey(languageKey)
      if (!apiKey) throw new Error('Google Maps API key is missing')
      setGoogleApiKey(apiKey)
    } catch (err: unknown) {
      setError('Failed to load Google Maps API. Please try again later.')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    const fetchGoogleApiKey = async () => {
      setLoading(true)
      setError(null)
      await getGoogleMapsAPIKeyByInstance(languageKey)
    }
    if (provider === 'google') {
      fetchGoogleApiKey()
    } else {
      setLoading(false)
    }
    if (provider === 'google') fetchGoogleApiKey()
  }, [languageKey, provider])

  if (loading) {
    return <div>Loading map...</div>
  }

  if (error) {
    return (
      <div style={{ textAlign: 'center', color: 'red' }}>
        <p>{error}</p>
        <button onClick={() => window.location.reload()}>Retry</button>
      </div>
    )
  }

  if (provider === 'google')
    return <GoogleAPIProvider apiKey={googleApiKey}>{children}</GoogleAPIProvider>

  return null
}

/**
 * MapListing component that renders a map from the specified provider.
 * Currently supports the 'google' provider and renders the GoogleMap component.
 *
 * @param {Object} props - The component's props.
 * @param {string} [props.provider='google'] - The map provider to use. Defaults to 'google'.
 * @param {Object} [props.rest] - Any other additional props passed to the GoogleMap component.
 *
 * @returns {React.ReactNode | null} - The component returns the GoogleMap component with the provided props or null if an unsupported provider is specified.
 *
 * @note This component is currently limited to rendering Google Maps based on the provider prop.
 */
const MapListing: React.FC<MapProps> = ({ provider = 'google', listings, ...rest }) => {
  // This state is necessary for the map first render so
  // the "Search this area" button doesn't appear since the useEffect potentially updates this state
  const [isFirstRender, setIsFirstRender] = useState(true)
  const [lastEvent, setLastEvent] = useState('')
  const [renderSearchButton, setRenderSearchButton] = useState(false)
  const { location: userLocation, updateUserLocation } = useGetLocation()
  const map = useMap()
  const { fetchLocationFromCoordinates } = useFetchLocationFromCoordinates()
  const { refetchResults } = useSearchActions()

  const handleMapInteraction = useCallback(
    (event: MapEvent<unknown> | MapCameraChangedEvent) => {
      if (isFirstRender && event.type === 'zoom_changed') {
        setIsFirstRender(false)
        return
      }
      setLastEvent(event.type)
      setRenderSearchButton(true)
    },
    [isFirstRender]
  )

  /**
   * There was a previous POC branch (`https://github.mpi-internal.com/ecg-kijiji-ca/kijiji-frontend/pull/2963`) but the code below
   * simplifies the logic to fit the map bounds to the user search radius.
   * As per this page https://confluence.ets.mpi-internal.com/display/FE/Bringing+the+Map+SRP+from+the+APP+to+WEB
   * We need to fit the map to the bounds of the circle of the radius selected by the user, so:
   * We create a google.maps.Circle,
   * We make it the radius of the user selected radius, and set the center to the user selected location,
   * We get the bounds of the circle,
   * We fit the map to the bounds of the circle.
   * NOTE: Any google.maps.* references are exposed via the GoogleMaps singleton already in our codebase.
   */
  useEffect(() => {
    /**
     * This assertion checks if the user has a radius selected and if the maps API already finished your initialization,
     * in order to instantiate a Circle on the map according to the user's radius
     */
    if (userLocation.area?.radius && google.maps.Circle) {
      const circ = new google.maps.Circle()
      circ.setRadius(userLocation.area.radius * 1000)
      circ.setCenter({
        lat: userLocation.area.latitude,
        lng: userLocation.area.longitude,
      })
      const bounds = circ.getBounds()

      // Then, fit the map according to the circle's bounds
      bounds && map?.fitBounds(bounds)

      const mapBounds = map?.getBounds()

      if (mapBounds) {
        const { north, east, south, west } = mapBounds.toJSON()

        refetchResults({
          location: {
            id: userLocation.id,
            boundingBox: {
              ne: { latitude: north, longitude: east },
              sw: { latitude: south, longitude: west },
            },
            area: null,
          },
        })
      }
    }
    // eslint-disable-next-line
  }, [map, userLocation])

  /**
   * this call fetches the approximate radius that fit just right on the map bounds
   * this is only for when the user changes the zoom of the map, since we already do the calculation
   * when the user selects a location from the map search modal
   * NOTE: Any google.maps.* references are exposed via the GoogleMaps singleton already in our codebase.
   */
  const getRadius = useCallback(() => {
    const bounds = map?.getBounds()
    if (!bounds || !google.maps.geometry) return null
    // get the distance between the north and south point
    const nsRadius = google.maps.geometry.spherical.computeDistanceBetween(
      bounds.getCenter(),
      new google.maps.LatLng(bounds.getNorthEast().lat(), bounds.getCenter().lng())
    )
    // get the distance between the east and west point
    const ewRadius = google.maps.geometry.spherical.computeDistanceBetween(
      bounds.getCenter(),
      new google.maps.LatLng(bounds.getCenter().lat(), bounds.getNorthEast().lng())
    )

    // return the smallest of the two distances so the radius is always within the bounds
    const radius = nsRadius <= ewRadius ? nsRadius : ewRadius

    return Number(radius.toFixed(2)) / 1000
  }, [map])

  // When the map is manipulated (pan, zoom) the "Search this area" button appears
  // When clicked it needs to re-search, sync the new location/radius to the site header, and hide itself
  const handleSearchButtonClick = useCallback(async () => {
    setRenderSearchButton(false)

    const mapCenter = map?.getCenter()?.toJSON()
    if (!mapCenter) return

    const newUserLocation = await fetchLocationFromCoordinates({
      latitude: mapCenter.lat,
      longitude: mapCenter.lng,
    })

    if (!newUserLocation) return

    const mapRadius =
      lastEvent === 'zoom_changed'
        ? Math.round(getRadius() ?? 50)
        : (userLocation.area?.radius ?? 50)

    updateUserLocation({
      id: newUserLocation.id,
      isRegion: false,
      name: newUserLocation.name,
      area: {
        latitude: mapCenter.lat,
        longitude: mapCenter.lng,
        radius: mapRadius,
        address: newUserLocation?.area?.address ?? '',
      },
    })
    const bounds = map?.getBounds()
    if (bounds) {
      const { north, south, east, west } = bounds.toJSON()
      await refetchResults({
        location: {
          id: newUserLocation.id,
          boundingBox: {
            ne: { latitude: north, longitude: east },
            sw: { latitude: south, longitude: west },
          },
          area: newUserLocation.area,
        },
      })
    }
  }, [
    map,
    fetchLocationFromCoordinates,
    lastEvent,
    getRadius,
    userLocation.area?.radius,
    updateUserLocation,
    refetchResults,
  ])

  // const listing = {
  //   __typename: 'RealEstateListing',
  //   id: '1001269354',
  //   title: 'Real Estate feature purchase 731674',
  //   description: '731674 Real Estate feature purchase in Long Tern rentals',
  //   imageUrls: [],
  //   categoryId: 37,
  //   url: 'https://fes.dev.kjdev.ca:3100/v-apartments-condos/granby/real-estate-feature-purchase-731674/1001269354',
  //   activationDate: '2023-11-09T04:32:36.000Z',
  //   sortingDate: '2024-12-07T19:45:30.000Z',
  //   adSource: 'ORGANIC',
  //   location: {
  //     __typename: 'ListingLocation',
  //     id: 1700253,
  //     name: 'Granby',
  //     address: '111 Rue de Trois-Riviéres, Granby, QC',
  //     distance: 56316,
  //     nearestIntersection: null,
  //   },
  //   price: {
  //     __typename: 'StandardAmountPrice',
  //     type: 'FIXED',
  //     amount: 20100,
  //     originalAmount: null,
  //   },
  //   attributes: {
  //     __typename: 'RealEstateListingAttributes',
  //     all: [
  //       {
  //         __typename: 'ListingAttributeV2',
  //         canonicalName: 'heat',
  //         canonicalValues: ['1'],
  //       },
  //       {
  //         __typename: 'ListingAttributeV2',
  //         canonicalName: 'dateavailable',
  //         canonicalValues: ['2023-11-09T00:00:00Z'],
  //       },
  //       {
  //         __typename: 'ListingAttributeV2',
  //         canonicalName: 'hydro',
  //         canonicalValues: ['1'],
  //       },
  //       {
  //         __typename: 'ListingAttributeV2',
  //         canonicalName: 'yard',
  //         canonicalValues: ['1'],
  //       },
  //       {
  //         __typename: 'ListingAttributeV2',
  //         canonicalName: 'visualaids',
  //         canonicalValues: ['0'],
  //       },
  //       {
  //         __typename: 'ListingAttributeV2',
  //         canonicalName: 'barrierfreeentrancesandramps',
  //         canonicalValues: ['1'],
  //       },
  //       {
  //         __typename: 'ListingAttributeV2',
  //         canonicalName: 'numberparkingspots',
  //         canonicalValues: ['3'],
  //       },
  //       {
  //         __typename: 'ListingAttributeV2',
  //         canonicalName: 'balcony',
  //         canonicalValues: ['0'],
  //       },
  //       {
  //         __typename: 'ListingAttributeV2',
  //         canonicalName: 'laundryinbuilding',
  //         canonicalValues: ['0'],
  //       },
  //       {
  //         __typename: 'ListingAttributeV2',
  //         canonicalName: 'elevator',
  //         canonicalValues: ['1'],
  //       },
  //       {
  //         __typename: 'ListingAttributeV2',
  //         canonicalName: 'numberbathrooms',
  //         canonicalValues: ['25'],
  //       },
  //       {
  //         __typename: 'ListingAttributeV2',
  //         canonicalName: 'furnished',
  //         canonicalValues: ['1'],
  //       },
  //       {
  //         __typename: 'ListingAttributeV2',
  //         canonicalName: 'wheelchairaccessible',
  //         canonicalValues: ['1'],
  //       },
  //       {
  //         __typename: 'ListingAttributeV2',
  //         canonicalName: 'cabletv',
  //         canonicalValues: ['0'],
  //       },
  //       {
  //         __typename: 'ListingAttributeV2',
  //         canonicalName: 'numberbedrooms',
  //         canonicalValues: ['3'],
  //       },
  //       {
  //         __typename: 'ListingAttributeV2',
  //         canonicalName: 'accessiblewashroomsinsuite',
  //         canonicalValues: ['1'],
  //       },
  //       {
  //         __typename: 'ListingAttributeV2',
  //         canonicalName: 'bicycleparking',
  //         canonicalValues: ['0'],
  //       },
  //       {
  //         __typename: 'ListingAttributeV2',
  //         canonicalName: 'forrentbyhousing',
  //         canonicalValues: ['ownr'],
  //       },
  //       {
  //         __typename: 'ListingAttributeV2',
  //         canonicalName: 'concierge',
  //         canonicalValues: ['0'],
  //       },
  //       {
  //         __typename: 'ListingAttributeV2',
  //         canonicalName: 'braillelabels',
  //         canonicalValues: ['0'],
  //       },
  //       {
  //         __typename: 'ListingAttributeV2',
  //         canonicalName: 'unittype',
  //         canonicalValues: ['apartment'],
  //       },
  //       {
  //         __typename: 'ListingAttributeV2',
  //         canonicalName: 'fridgefreezer',
  //         canonicalValues: ['1'],
  //       },
  //       {
  //         __typename: 'ListingAttributeV2',
  //         canonicalName: 'dishwasher',
  //         canonicalValues: ['1'],
  //       },
  //       {
  //         __typename: 'ListingAttributeV2',
  //         canonicalName: 'pool',
  //         canonicalValues: ['0'],
  //       },
  //       {
  //         __typename: 'ListingAttributeV2',
  //         canonicalName: 'laundryinunit',
  //         canonicalValues: ['1'],
  //       },
  //       {
  //         __typename: 'ListingAttributeV2',
  //         canonicalName: 'petsallowed',
  //         canonicalValues: ['1'],
  //       },
  //       {
  //         __typename: 'ListingAttributeV2',
  //         canonicalName: 'areainfeet',
  //         canonicalValues: ['3930'],
  //       },
  //       {
  //         __typename: 'ListingAttributeV2',
  //         canonicalName: 'water',
  //         canonicalValues: ['1'],
  //       },
  //       {
  //         __typename: 'ListingAttributeV2',
  //         canonicalName: 'audioprompts',
  //         canonicalValues: ['1'],
  //       },
  //       {
  //         __typename: 'ListingAttributeV2',
  //         canonicalName: 'airconditioning',
  //         canonicalValues: ['1'],
  //       },
  //       {
  //         __typename: 'ListingAttributeV2',
  //         canonicalName: 'twentyfourhoursecurity',
  //         canonicalValues: ['0'],
  //       },
  //       {
  //         __typename: 'ListingAttributeV2',
  //         canonicalName: 'smokingpermitted',
  //         canonicalValues: ['0'],
  //       },
  //       {
  //         __typename: 'ListingAttributeV2',
  //         canonicalName: 'agreementtype',
  //         canonicalValues: ['one-year'],
  //       },
  //       {
  //         __typename: 'ListingAttributeV2',
  //         canonicalName: 'storagelocker',
  //         canonicalValues: ['1'],
  //       },
  //       {
  //         __typename: 'ListingAttributeV2',
  //         canonicalName: 'gym',
  //         canonicalValues: ['1'],
  //       },
  //       {
  //         __typename: 'ListingAttributeV2',
  //         canonicalName: 'rentalsvirtualoptions',
  //         canonicalValues: ['videochat', 'onlineapplication', 'virtualtour'],
  //       },
  //       {
  //         __typename: 'ListingAttributeV2',
  //         canonicalName: 'internet',
  //         canonicalValues: ['1'],
  //       },
  //     ],
  //   },
  //   flags: {
  //     __typename: 'RealEstateListingFlags',
  //     categorySpecificBadge: true,
  //     priceDrop: false,
  //     showcase: false,
  //     topAd: false,
  //     highlight: true,
  //     shippedBySeller: null,
  //     isPromotionProvTopAd: null,
  //     isPromotionTopAd: null,
  //     virtualTour: false,
  //   },
  //   posterInfo: {
  //     __typename: 'PosterInfo',
  //     posterId: '1022972068',
  //   },
  // }

  const renderMapProvider = useCallback(() => {
    if (provider === 'google') {
      return (
        <GoogleMap
          // The map requires a unique MAP_ID for features like Advanced Markers.
          mapId={MAP_ID}
          defaultCenter={defaultLocation}
          defaultZoom={defaultLocation.defaultZoom}
          onDrag={handleMapInteraction}
          onZoomChanged={handleMapInteraction}
          {...rest}
        >
          {/* <InfoWindow
            key={listing.id}
            position={{
              lat: userLocation.area?.latitude ?? 0,
              lng: userLocation.area?.longitude ?? 0,
            }}
          >
            <ListingCard item={listing} index={0} isMapCard />
          </InfoWindow> */}
        </GoogleMap>
      )
    }
    return null
  }, [handleMapInteraction, provider, rest])

  return (
    <>
      {renderMapProvider()}
      {renderSearchButton && (
        <MapSearchButton data-testid="search-button" onClick={handleSearchButtonClick} />
      )}
    </>
  )
}

export { MapListing, MapProvider }
