import { ThemeProps } from '@kijiji/theme'
import { HTMLAttributes } from 'react'

import { StyledAs } from '@/ui/typings/helpers'

import { CardBase } from './styled'

export type CardProps = HTMLAttributes<HTMLDivElement> & {
  /**
   * The type of element you want text to be rendered in
   * @default 'div'
   */
  as?: StyledAs
  /**
   * The padding for the card
   * @default 'large'
   */
  padding?: keyof ThemeProps['spacing']
  /**
   * The borderRadius for the card
   * @default 'small'
   */
  borderRadius?: keyof ThemeProps['borderRadius']
  /**
   * The style for the border of the card
   * @default 'box-shadow'
   */
  borderType?: 'box-shadow' | 'border'
}

export const Card = ({
  children,
  padding = 'large',
  borderRadius = 'small',
  borderType = 'box-shadow',
  ...props
}: CardProps) => (
  <CardBase
    padding={padding}
    borderType={borderType}
    borderRadius={borderRadius}
    {...props}
  >
    {children}
  </CardBase>
)
