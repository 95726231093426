/**
 * Determines if a given number is a multiple of 20.
 *
 * @param {number} value - The number to check.
 * @returns {boolean} True if the number is a multiple of 20, false otherwise.
 * @throws {TypeError} If the input is not a number.
 *
 * @example
 * isMultipleOfTwenty(40);  // returns true
 * isMultipleOfTwenty(30);  // returns false
 * isMultipleOfTwenty(0);   // returns true
 * isMultipleOfTwenty(-60); // returns true
 */
export const isMultipleOfTwenty = (value: number): boolean => {
  if (typeof value !== 'number') {
    throw new TypeError('Input must be a number');
  }

  return value % 20 === 0
}
