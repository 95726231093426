import styled from 'styled-components'

import { zIndexRegistry } from '@/constants/zIndexRegistry'
import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'
import { styleFromTypography } from '@/ui/helpers/styleFromTypography'

export const FiltersDivider = styled.hr(
  ({ theme }) => `
  border-bottom: 0.1rem solid ${theme.colors.grey.light4};
`
)

export const FilterFloatingButtonContainer = styled.div(
  ({ theme }) => `
  bottom: ${theme.spacing.default};
  display: flex;
  z-index: ${zIndexRegistry.FLOATING_CTA};

  ${MEDIA_QUERIES(theme).large}{
    display: none;
  }
`
)

export const FiltersAppliedCount = styled.span(
  ({ theme }) => `
  ${styleFromTypography(theme.typography.body.xSmall)}
  align-items: center;
  background-color: ${theme.colors.purple.light5};
  border-radius: ${theme.borderRadius.rounded};
  color: ${theme.colors.purple.primary};
  display: flex;
  height: 2rem;
  justify-content: center;
  width: 2rem;
`
)
