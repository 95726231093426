import { useDecision } from '@optimizely/react-sdk'
import dynamic from 'next/dynamic'
import { useTranslation } from 'next-i18next'
import { type FC, useEffect } from 'react'
import { useTheme } from 'styled-components'

import {
  FiltersAccordion,
  FiltersAccordionOrigin,
} from '@/components/srp/filters/FiltersAccordion/FiltersAccordion'
import {
  FiltersModalContent,
  FiltersModalFooter,
  FiltersModalHeader,
  FiltersModalPanel,
  FiltersModalTitle,
} from '@/components/srp/filters/FiltersModal/styled'
import { SrpLoading } from '@/components/srp/srp-loading'
import { useGetSearchResultsData } from '@/hooks/srp'
import { RESET_FILTERS_INPUT, useSearchActions } from '@/hooks/srp/useSearchActions'
import { trackEvent } from '@/lib/ga'
import { GA_EVENT } from '@/lib/ga/constants/gaEvent'
import { FEATURE_FLAG } from '@/lib/optimizely'
import { Button } from '@/ui/atoms/button'
import { Spacing } from '@/ui/atoms/spacing'
import { Modal } from '@/ui/molecules/modal/Modal'

const SaveSearchChipButton = dynamic(
  () => import('@/components/srp/save-search-chip').then((mod) => mod.SaveSearchChipButton),
  { ssr: false }
)
export type FiltersModalProps = {
  /**
   * Defines if modal is opened
   */

  isOpen: boolean
  /**
   * Triggered to close modal
   */
  onClose: () => void
}

export const FiltersModal: FC<FiltersModalProps> = ({ isOpen, onClose }) => {
  const { data, loadingFilters, loadingResults } = useGetSearchResultsData()
  const { refetchResults } = useSearchActions()
  const { colors, spacing } = useTheme()
  const { t } = useTranslation('srp')

  const totalListings = data?.pagination.totalCount || 0

  const [saveSearchWithinFiltersDecision] = useDecision(FEATURE_FLAG.SAVED_SEARCH_WITHIN_FILTERS)

  const handleResetFilters = () => {
    refetchResults(RESET_FILTERS_INPUT)
    trackEvent({ action: GA_EVENT.FilterSelectReset })
    onClose()
  }

  const handleClose = () => {
    trackEvent({ action: GA_EVENT.FilterModalClose })
    onClose()
  }

  useEffect(() => {
    if (isOpen) {
      trackEvent({ action: GA_EVENT.FilterModalOpen })
    }
  }, [isOpen])

  return (
    <Modal
      data-testid="srp-mobile-filter"
      id="filters"
      isOpen={isOpen}
      label={t('filters.title')}
      onCancel={handleClose}
      hasCloseButton
      closeButtonLabel={t('filters.close_modal_label')}
      padding="0"
    >
      <FiltersModalPanel>
        <FiltersModalHeader>
          <FiltersModalTitle color={colors.grey.primary} size="large" textAlign="center">
            {t('filters.title')}
          </FiltersModalTitle>
        </FiltersModalHeader>

        {saveSearchWithinFiltersDecision.enabled && <SaveSearchChipButton />}
        {loadingFilters ? (
          <SrpLoading variant="modal-filter" />
        ) : (
          <FiltersModalContent>
            <FiltersAccordion isMobile={true} origin={FiltersAccordionOrigin.MOBILE_MODAL} />
          </FiltersModalContent>
        )}

        <FiltersModalFooter>
          <Button
            aria-busy="false"
            aria-live="assertive"
            isFullWidth
            isLoading={loadingResults}
            loadingLabel={`${t('filters.show.results_loading')}`}
            onClick={handleClose}
          >
            {t('filters.show.n_listings', { totalListings })}
          </Button>
          <Spacing mTop={spacing.default}>
            <Button isFullWidth variant="tertiary" onClick={handleResetFilters}>
              {t('filters.actions.clear_filters')}
            </Button>
          </Spacing>
        </FiltersModalFooter>
      </FiltersModalPanel>
    </Modal>
  )
}
