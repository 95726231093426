export const removeTypenameFromObject = <T extends object>(
  obj?: T | null
): Omit<T, '__typename'> | undefined => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const object = obj as any

  if (object?.['__typename']) {
    const { __typename, ...objectWithoutTypename } = object
    return objectWithoutTypename
  }

  return object
}
