let debugModeMessageShown = false

/**
 * A helper function to log debug messages to the console.
 *
 * @param log A function that returns a string to be logged to the console.
 * @example
 *  debug(() => 'This is a debug message')
 *
 *  This will log the message to the console if the debug query param is present.
 *  e.g. https://fes.dev.kjdev.ca:3100/?debug=true
 */
export const debug = (log: () => void) => {
  const searchParams = new URLSearchParams(
    typeof window !== 'undefined' ? window.location.search : ''
  )
  const isDebugQueryParamPresent = searchParams.get('debug') === 'true'

  if (isDebugQueryParamPresent && !debugModeMessageShown) {
    // eslint-disable-next-line no-console
    console.info(
      `
******************************
*                            *
*  DEBUG MODE IS ENABLED!    *
*                            *
******************************
      `
    )
    debugModeMessageShown = true
  }

  if (isDebugQueryParamPresent) {
    log()
  }
}
