import { useTranslation } from 'next-i18next'
import { type FC } from 'react'
import { useTheme } from 'styled-components'

import { getListingCounts } from '@/components/srp/results-sort/results/getListingCounts'
import { SrpResultsText } from '@/components/srp/results-sort/results/styled'
import { SRP_LISTING_LIMIT } from '@/constants/pageSettings'
import { getCurrentPageFromPagination } from '@/domain/srp/getCurrentPageFromPaginationData'
import { useGetSearchResultsData } from '@/hooks/srp/useGetSearchResultsData'

type SrpResultsProps = {
  /**
   * There are two placements for this results to be shown.
   * This flag is indicating if this component should be shown at large breakpoints or only smaller
   */
  showOnLargeBreakpoint?: boolean
}

export const SrpResults: FC<SrpResultsProps> = ({ showOnLargeBreakpoint }) => {
  const { data } = useGetSearchResultsData()

  const { t } = useTranslation('srp')
  const { colors } = useTheme()

  const {
    totalCount: totalListings = 0,
    offset = 0,
    limit = SRP_LISTING_LIMIT,
  } = data?.pagination || {}
  const currentPage = getCurrentPageFromPagination({ offset, limit })

  const listingCounts = getListingCounts(totalListings, currentPage, limit)

  return (
    <SrpResultsText
      $showOnLargeBreakpoint={showOnLargeBreakpoint}
      aria-live="polite"
      color={colors.grey.primary}
      data-testid="srp-results"
      size="small"
    >
      {t(listingCounts.intl, { ...listingCounts.params })}
    </SrpResultsText>
  )
}
