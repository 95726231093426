import { type AppliedAttributeFilter } from '@kijiji/generated/graphql-types'
import { formatWholeNumber } from '@kijiji/number/formatWholeNumber'
import { z } from 'zod'

import { type ApiLocale, API_LOCALE } from '@/domain/locale'
import { getFilterValuesString } from '@/features/seo/utils/getFilterValuesString'

export type GenerateSrpSeoTitleProps = {
  filters: AppliedAttributeFilter[] | []
  filterTypesInTitle: string[]
  locale: ApiLocale
  searchKeywords?: string | null
  title?: string
  totalListings: number
  translation: (
    key: string,
    options: { totalCount?: string; title?: string; filters?: string }
  ) => string
}

const LISTING_COUNT_DISPLAY_MIN = 25

const AppliedAttributeFilterSchema = z.object({
  key: z.string().optional(),
  values: z.array(z.string()),
  filterName: z.string(),
})

const GenerateSrpSeoTitlePropsSchema = z.object({
  filters: z.array(AppliedAttributeFilterSchema),
  filterTypesInTitle: z.array(z.string()),
  locale: z.nativeEnum(API_LOCALE),
  searchKeywords: z.string().nullish(),
  title: z.string().optional(),
  totalListings: z.number().nonnegative(),
  translation: z
    .function()
    .args(
      z.string(),
      z.object({
        totalCount: z.string().optional(),
        title: z.string().optional(),
        filters: z.string().optional(),
      })
    )
    .returns(z.string()),
})

/**
 * Formats the SEO title based on listings count and search parameters.
 *
 * @param locale - The locale chosen by the user.
 * @param totalListings - The total number of listings.
 * @param title - The base title derived from search metadata.
 * @param translation - The translation function from next-i18next.
 *
 * @returns A formatted SEO title string.
 *
 * @example
 * const title = formatSeoTitle({
 *   locale: API_LOCALE.en_CA,
 *   totalListings: 1000,
 *   title: 'Jobs in Canada',
 *   translation: t,
 * });
 */
function formatSeoTitle({
  translation,
  totalListings,
  title,
  locale,
}: Pick<GenerateSrpSeoTitleProps, 'translation' | 'totalListings' | 'title' | 'locale'>): string {
  const isEnglish = locale === API_LOCALE.en_CA
  const adsNumber = formatWholeNumber(totalListings, isEnglish, false)

  if (totalListings < LISTING_COUNT_DISPLAY_MIN) {
    return title ?? ''
  }

  return translation('meta.srp.title.seo', { totalCount: adsNumber, title })
}

/**
 * Formats the SEO title with filters applied.
 *
 * @param filters - The filters applied.
 * @param filterTypesInTitle - The types of filters to include in the title.
 * @param translation - The translation function from next-i18next.
 * @param seoTitle - The base SEO title to append the filters to.
 *
 * @returns A formatted SEO title string with filters applied, or the original title if no filters are present.
 *
 * @example
 * const titleWithFilters = applyFiltersToSeoTitle({
 *   filters: appliedFilters,
 *   filterTypesInTitle: ['type1', 'type2'],
 *   translation: t,
 *   seoTitle: 'Jobs in Canada',
 * });
 */
function applyFiltersToSeoTitle({
  filters,
  filterTypesInTitle,
  translation,
  seoTitle,
}: Pick<GenerateSrpSeoTitleProps, 'filters' | 'filterTypesInTitle' | 'translation'> & {
  seoTitle: string
}): string {
  // Use of Set here to avoid duplicate filter types
  const filterTypesSet = new Set(filterTypesInTitle)

  const filterValuesString = getFilterValuesString(
    filters.filter((filter) => filterTypesSet.has(filter.filterName)),
    filterTypesInTitle
  )

  if (filterValuesString) {
    return translation('meta.srp.title.filters', {
      filters: filterValuesString,
      title: seoTitle,
    })
  }

  return seoTitle
}

/**
 * Generates a SEO-friendly title based on the listings count and search parameters.
 *
 * @param filters - The filters applied.
 * @param filterTypesInTitle - The types of filters to include in the title.
 * @param locale - The locale chosen by the user.
 * @param searchKeywords - The search keywords used in the search.
 * @param title - The base title derived from search metadata.
 * @param totalListings - The total number of listings.
 * @param translation - The translation function from next-i18next.
 *
 * @returns A SEO-optimized title string - Example: "1,000 ads for technician in Jobs in Canada".
 *
 * @example
 * const seoTitle = generateSrpSeoTitle({
 *    locale: API_LOCALE.en_CA,
 *    title: 'Jobs in Canada',
 *    totalListings: 1000,
 *    searchKeywords: 'technician',
 *    filters: filtersData,
 *    filterTypesInTitle: ['type1', 'type2'],
 *    translation: t,
 * });
 */
export function generateSrpSeoTitle(props: GenerateSrpSeoTitleProps): string {
  const { filters, filterTypesInTitle, locale, searchKeywords, title, totalListings, translation } =
    GenerateSrpSeoTitlePropsSchema.parse(props)

  const seoTitle = title ?? ''

  // If search keyword (SRP)
  if (searchKeywords && title) {
    return formatSeoTitle({ translation, totalListings, title, locale })
  }

  // If filters applied (BRP)
  return applyFiltersToSeoTitle({ filters, filterTypesInTitle, translation, seoTitle })
}
