import {
  type AppliedAttributeFilter,
  type AppliedDateFilter,
  type AppliedDateRangeFilter,
  type AppliedFilter,
  type AppliedRangeFilter,
  type AppliedToggleFilter,
  type AttributeFilter,
  type FilterGroup as FilterGroupType,
  FilterControlType,
} from '@kijiji/generated/graphql-types'
import { useTranslation } from 'next-i18next'
import { type ComponentProps, type FC, Fragment, useCallback } from 'react'

import { ToggleFilter as DeprecatedToggleFilter } from '@/components/shared/deprecated/toggle-filter/ToggleFilter'
import { DateFilter } from '@/components/srp/filters/filter-types/DateFilter'
import { DateRangeFilter } from '@/components/srp/filters/filter-types/DateRangeFilter'
import { IntegerRangeFilter } from '@/components/srp/filters/filter-types/IntegerRangeFilter'
import { MultiSelectFilter } from '@/components/srp/filters/filter-types/MultiSelectFilter'
import { RadioFilter } from '@/components/srp/filters/filter-types/RadioFilter'
import { ToggleFilter } from '@/components/srp/filters/filter-types/ToggleFilter'
import {
  type FilterRefetchResults,
  type FilterValuesUnion,
} from '@/components/srp/filters/FiltersAccordion/FiltersAccordion'
import { FiltersDivider } from '@/components/srp/filters/styled'
import { getLocaleLabelKeyOverride } from '@/domain/filters'
import { useGetSearchResultsData } from '@/hooks/srp/useGetSearchResultsData'
import { FilterKeysEnum, useSearchActions } from '@/hooks/srp/useSearchActions'
import {
  isAttributeFilter,
  isDateFilter,
  isDateRangeFilter,
  isHasFilter,
  isRangeFilterWithMinMax,
  isToggleFilter,
} from '@/types/search'
import { type NullableFields } from '@/utils/types'

export type FilterGroupProps = {
  filterGroup: FilterGroupType
  isMobile?: boolean
  refetch: FilterRefetchResults<FilterValuesUnion>
  onClick?: (filterId: string) => void
}

export const FilterGroup: FC<FilterGroupProps> = ({ filterGroup, isMobile, refetch, onClick }) => {
  const { t } = useTranslation(['srp'])
  const { data, loadingResults } = useGetSearchResultsData()
  const { getParentFilter, getFilterWithSelectedValues } = useSearchActions()
  const { searchQuery } = data || {}
  const selectedCategoryId = searchQuery?.category?.id || 0

  const getAttributeFilterComponent = useCallback(
    ({
      filter,
      attributeProps,
    }: {
      filter: AttributeFilter
      attributeProps: ComponentProps<
        typeof MultiSelectFilter | typeof RadioFilter | typeof DeprecatedToggleFilter
      >
    }) => {
      switch (filter.type) {
        case FilterControlType.Checkboxes:
        case FilterControlType.MultiSelect:
          return <MultiSelectFilter {...attributeProps} />
        case FilterControlType.Radio:
          return <RadioFilter {...attributeProps} />
        case FilterControlType.Toggles:
          return <DeprecatedToggleFilter {...attributeProps} />
        default:
          return null
      }
    },
    []
  )

  return filterGroup.filters.map((rawFilter, index) => {
    const key = `${rawFilter.name}-${index}`

    const localeKeyOverride = getLocaleLabelKeyOverride(rawFilter.name, selectedCategoryId)
    const label = localeKeyOverride
      ? t(`filters.${localeKeyOverride}_range.${localeKeyOverride}`)
      : rawFilter.label

    const filter = { ...rawFilter, label }
    const parentFilter = getParentFilter(filter)

    const filterBaseProps = {
      parentFilter,
      isMobile,
      isSrpLoading: loadingResults,
      onClickAccordionItem: onClick,
    }

    if (isHasFilter(filter)) {
      const attributeProps = {
        ...filterBaseProps,
        filter: getFilterWithSelectedValues(filter),
        refetch: (
          { filterName, ...value }: AppliedFilter & NullableFields<AppliedAttributeFilter>,
          trackingLabel?: string
        ) => {
          /* 
            On spamming clicking the inputs, the filter values that are coming get mixed with other has filters that are applied,
            causing a duplicity of the values applied. 
            This piece of code filters out the values that are not part of the filters.
          */
          const filterGroupValues = value.values?.filter((val) =>
            filter.values?.some((item) => item.value === val)
          )
          value.values = filterGroupValues ?? null
          refetch(FilterKeysEnum.ADDITIONAL_FLAG_FILTERS, filterName, value, trackingLabel)
        },
      }

      return (
        <Fragment key={key}>
          <FiltersDivider />
          <DeprecatedToggleFilter {...attributeProps} />
        </Fragment>
      )
    } else if (isAttributeFilter(filter)) {
      const attributeProps = {
        ...filterBaseProps,
        filter: getFilterWithSelectedValues(filter),
        refetch: (
          { filterName, ...value }: AppliedFilter & NullableFields<AppliedAttributeFilter>,
          trackingLabel?: string
        ) => refetch(FilterKeysEnum.ATTRIBUTE_FILTERS, filterName, value, trackingLabel),
      }

      return (
        <Fragment key={key}>
          <FiltersDivider />
          {getAttributeFilterComponent({ filter, attributeProps })}
        </Fragment>
      )
    } else if (isRangeFilterWithMinMax(filter)) {
      const rangeProps = {
        ...filterBaseProps,
        filter: getFilterWithSelectedValues(filter),
        refetch: (
          { filterName, ...value }: AppliedFilter & NullableFields<AppliedRangeFilter>,
          trackingLabel?: string
        ) => refetch(FilterKeysEnum.RANGE_FILTERS, filterName, value, trackingLabel),
      }

      return (
        <Fragment key={key}>
          <FiltersDivider />
          <IntegerRangeFilter {...rangeProps} />
        </Fragment>
      )
    } else if (isDateFilter(filter)) {
      const dateProps = {
        ...filterBaseProps,
        filter: getFilterWithSelectedValues(filter),
        refetch: (
          { filterName, ...value }: AppliedFilter & NullableFields<AppliedDateFilter>,
          trackingLabel?: string
        ) => refetch(FilterKeysEnum.DATE_FILTERS, filterName, value, trackingLabel),
      }

      return (
        <Fragment key={key}>
          <FiltersDivider />
          <DateFilter {...dateProps} />
        </Fragment>
      )
    } else if (isDateRangeFilter(filter)) {
      const dateRangeProps = {
        ...filterBaseProps,
        filter: getFilterWithSelectedValues(filter),
        refetch: (
          { filterName, ...value }: AppliedFilter & NullableFields<AppliedDateRangeFilter>,
          trackingLabel?: string
        ) => refetch(FilterKeysEnum.DATE_RANGE_FILTERS, filterName, value, trackingLabel),
      }

      return (
        <Fragment key={key}>
          <FiltersDivider />
          <DateRangeFilter {...dateRangeProps} />
        </Fragment>
      )
    } else if (isToggleFilter(filter)) {
      const toggleProps = {
        ...filterBaseProps,
        filter: getFilterWithSelectedValues(filter),
        refetch: (
          { filterName, ...value }: AppliedFilter & NullableFields<AppliedToggleFilter>,
          trackingLabel?: string
        ) => refetch(FilterKeysEnum.TOGGLE_FILTERS, filterName, value, trackingLabel),
      }

      return <ToggleFilter {...toggleProps} key={key} />
    }
  })
}
