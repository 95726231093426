import styled from 'styled-components'

import { TextLink } from '@/ui/atoms/text-link'
import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'

export const PaginationContainer = styled.ul(
  ({ theme }) => `
  align-items: center;
  display: inline-flex;
  flex-wrap: wrap;
  gap: ${theme.spacing.defaultSmall};
  justify-content: center;
  list-style: none;
  width: 100%;
`
)

export const PageNumber = styled(TextLink)(
  ({ theme }) => `
  background: ${theme.colors.white};
  align-items: center;
  background: ${theme.colors.white};
  border-radius: ${theme.borderRadius.small};
  border: .1rem solid ${theme.colors.grey.light3};
  color: ${theme.colors.grey.light1};
  display: flex;
  justify-content: center;
  min-height: ${theme.spacing.xLarge};
  min-width: ${theme.spacing.xLarge};
  text-decoration: none;

  &:hover {
    background: ${theme.colors.purple.light5};
  }

  &[aria-current] {
    background: ${theme.colors.purple.light5};
    border-color: ${theme.colors.purple.light3};
    color: ${theme.colors.purple.light1};
  }
`
)

export const Previous = styled(TextLink)(
  ({ theme }) => `
  padding: 1.2rem ${theme.spacing.large};

  &:before {
    content: '< ';
  }
`
)

export const Next = styled(TextLink)(
  ({ theme }) => `
  padding: 1.2rem ${theme.spacing.large};

  &:after {
    content: ' >';
  }
`
)

export const DirectionButtons = styled.li(
  ({ theme }) => `
  display: inline-flex;
  justify-content: center;
  text-align: center;
  width: 100%;

  ${MEDIA_QUERIES(theme).medium} {
    text-align: initial;
    width: initial;
  }
`
)
