import { isL1Category, isL2Category } from '@kijiji/category'

/**
 * Get breadcrumb levels and format into a dictionary
 * @param {number | string} categoryId
 * @param {number | string} parentId
 *
 * @returns returns the level of the category
 */
export const getCategoryLevel = ({
  categoryId,
  parentId,
}: {
  categoryId?: number | string
  parentId?: string | number | null
}): number => {
  if (
    (typeof categoryId === 'undefined' && typeof parentId === 'undefined') ||
    categoryId === 0 ||
    parentId === null
  )
    return 0

  if (typeof categoryId === 'string') categoryId = parseInt(categoryId)
  if (typeof parentId === 'string') parentId = parseInt(parentId)

  if (parentId === 0 || (categoryId && isL1Category(categoryId))) return 1

  return isL2Category({ categoryId, parentId }) ? 2 : 3
}
