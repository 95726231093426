import { EXPAND_FILTERS } from '@/constants/searchAccordionConfig'

/**
 * Get filter attributes to be expanded on render depending on the selected category
 *
 * @param categoryId
 * @param filters
 * @returns
 */
export const getExpandedFilterIdList = (categoryId: number) => {
  const categoryFiltersToExpand = EXPAND_FILTERS.category[categoryId] || []
  return [...EXPAND_FILTERS.general, ...categoryFiltersToExpand]
}
