import FiltersIcon from '@kijiji/icons/src/icons/Filters'
import { useTranslation } from 'next-i18next'

import { AssistChip } from '@/components/shared/assist-chip'
import { FilterFloatingButtonContainer, FiltersAppliedCount } from '@/components/srp/filters/styled'

export type FilterMobileChipProps = {
  appliedFiltersCount: number
  onClick: () => void
}

export const FilterMobileChip = ({ appliedFiltersCount, onClick }: FilterMobileChipProps) => {
  const { t } = useTranslation('srp')

  return (
    <FilterFloatingButtonContainer>
      <AssistChip
        data-testid="srp-floating-filter-button"
        label={t('filters.title')}
        onClick={onClick}
      >
        {appliedFiltersCount > 0 ? (
          <FiltersAppliedCount>{` +${appliedFiltersCount}`}</FiltersAppliedCount>
        ) : null}

        <FiltersIcon aria-hidden="true" />
      </AssistChip>
    </FilterFloatingButtonContainer>
  )
}
