import { type AppliedAttributeFilter, type AttributeFilter } from '@kijiji/generated/graphql-types'

import { type FilterValuesUnion } from '@/components/srp/filters/FiltersAccordion/FiltersAccordion'
import { type NullableFields } from '@/utils/types'

export const getLabelFromSelectedValue = (
  value: string,
  filterOptions: AttributeFilter['values']
) => {
  const selectedOption = filterOptions?.find((option) => value === option.value)
  return selectedOption && selectedOption.label
}

export function hasValuesProperty(
  value: FilterValuesUnion
): value is NullableFields<Omit<AppliedAttributeFilter, 'filterName'>> {
  return (value as AppliedAttributeFilter).values !== undefined
}
