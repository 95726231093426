import { isAnyRealEstateCategory } from '@kijiji/category'
import { useDecision } from '@optimizely/react-sdk'

import { CookieRegistry } from '@/constants/cookieRegistry'
import { FEATURE_FLAG } from '@/lib/optimizely'
import { getCookieByKey } from '@/utils/cookies/getCookieByKey'

export const useMapSRP = (categoryId: number): boolean => {
  const [decision] = useDecision(FEATURE_FLAG.MAP_SRP)

  const mapSRPOptInCookie: string =
    typeof document !== 'undefined'
      ? getCookieByKey(document.cookie, CookieRegistry.MAP_SRP_OPT_IN)
      : ''

  const isMapSRPEnabled: boolean =
    decision.enabled && mapSRPOptInCookie === 'enabled' && isAnyRealEstateCategory(categoryId)

  return isMapSRPEnabled
}
