import { type KeyboardEvent } from 'react'

import { REGEX_PATTERN as RGX } from '@/constants/regex'

export const isNumber = (evt: KeyboardEvent<HTMLInputElement>) => {
  /**
   * Allowing: Integers | Backspace | Tab | Delete | Left & Right arrow keys
   **/
  const regex = new RegExp(RGX.KEYBOARD_ONLY_NUMBERS)

  return !evt.key.match(regex) && evt.preventDefault()
}
