import styled, { type DefaultTheme } from 'styled-components'

import { Button } from '@/ui/atoms/button'

export const ShowMoreButton = styled(Button)`
  padding-left: 0;
`

export const ChipsFilterContainer = styled.div(
  ({ theme }) => `
  display: flex;
  gap: ${theme.spacing.defaultSmall};
  flex-wrap: wrap;
`
)

export const FlexItemContainer = styled.div`
  flex-basis: 50%;
`

type CategoryTreeButtonProps = {
  $selected?: boolean
  level: number
}

type attributeLevel = 'three' | 'lessThanThree'

const getAttributeTreeButtonColor = (
  selected: boolean = false,
  level: number,
  theme: DefaultTheme
) => {
  const colorSchemesConfig = {
    selected: {
      three: theme.colors.purple.primary,
      lessThanThree: theme.colors.purple.primary,
    },
    unselected: {
      three: theme.colors.grey.light1,
      lessThanThree: theme.colors.grey.primary,
    },
  }
  const selectedStr = selected ? 'selected' : 'unselected'
  const levelStr: attributeLevel = level < 3 ? 'lessThanThree' : 'three'
  return `
  color: ${colorSchemesConfig[selectedStr][levelStr]};
  `
}

// eslint-disable-next-line @kijiji/prefer-button-component
export const AttributeTreeButton = styled.button<CategoryTreeButtonProps>(
  ({ $selected, theme, level }) => `
  background: ${$selected ? theme.colors.purple.light5 : theme.colors.white};
  border-radius: ${theme.borderRadius.small};
  border: .1rem solid ${$selected ? theme.colors.purple.light2 : theme.colors.white};

  ${getAttributeTreeButtonColor($selected, level, theme)}

  display: block;
  font-size: ${theme.spacing.default};
  padding: ${theme.spacing.defaultSmall} ${theme.spacing.default};
  text-align: left;
  text-decoration: none;
  width: 100%;

  &:hover {
    cursor: pointer;
    background: ${$selected ? theme.colors.purple.light5 : theme.colors.grey.light4};
  }
`
)

export const AttributeTreeList = styled.ul`
  & > li > ul,
  & > li > ul > button {
    margin-left: 1.8rem;
  }
`

export const AddressWrapper = styled.span`
  display: inline-block;
  max-width: 16.9rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const ScrollableCheckboxesContainer = styled.div`
  max-height: 30rem;
  overflow-y: auto;
`
