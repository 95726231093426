/**
 * Determines the Geo ID based on the location ID.
 *
 * @param {number} locationId - The location ID.
 * @returns {string} The corresponding Geo ID.
 *   - If a mapping is found for the given location ID, the corresponding Geo ID is returned.
 *   - If no mapping is found, the default value 'o' is returned.
 *
 * @example
 *  determineGeoId(9001); // Returns '91'
 *  determineGeoId(9002); // Returns '05'
 *  determineGeoId(0);    // Returns '01'
 *  determineGeoId(123);  // Returns 'o'
 */
export function determineGeoId(locationId: number): string {
  const locationMapping: Record<number, string> = {
    0: '01',
    9001: '91',
    9002: '05',
    9003: '93',
    9004: '94',
    9005: '05',
    9006: '96',
    9007: '97',
    9008: '05',
    9009: '99',
    9010: '01',
    9011: '05',
  }

  return locationMapping[locationId] || 'o'
}
