import { useTranslation } from 'next-i18next'
import { type FC } from 'react'
import { useTheme } from 'styled-components'

import { Button } from '@/ui/atoms/button'
import { Spacing } from '@/ui/atoms/spacing'

export type ApplyFilterProps = {
  /**
   * Label of filter used for voice assistant technologies to let user know which filter are they applying
   */
  filterLabel: string
  /**
   * Trigger action to apply values on filter
   */
  handleApplyFilter: () => void
  /**
   * Trigger action to reset filter to original state
   */
  handleResetFilter: () => void
  /**
   * Defines if the apply button should be disabled
   */
  isDisabled?: boolean
  /**
   * Defines if reset filters button should appear
   */
  shouldShowResetButton: boolean
  /**
   * Defines if result is still loading
   */
  isLoading?: boolean
}

export const ApplyFilter: FC<ApplyFilterProps> = ({
  filterLabel,
  handleApplyFilter,
  handleResetFilter,
  isDisabled,
  isLoading,
  shouldShowResetButton,
}) => {
  const { spacing } = useTheme()
  const { t } = useTranslation(['srp', 'common'])

  return (
    <>
      <Spacing mBottom={spacing.default}>
        <Button
          isFullWidth
          variant="secondary"
          size="small"
          onClick={handleApplyFilter}
          aria-label={`${t('srp:filters.actions.apply')} ${filterLabel}`}
          disabled={isDisabled}
          isLoading={isLoading}
          loadingLabel={`${t('common:forms.buttons.loading')}`}
        >
          {t('srp:filters.actions.apply')}
        </Button>
      </Spacing>

      {shouldShowResetButton && (
        <Button isFullWidth variant="tertiary" size="small" onClick={handleResetFilter}>
          {t('srp:filters.actions.clear')}
        </Button>
      )}
    </>
  )
}
